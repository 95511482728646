import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import moment from "moment";
import { useState } from "react";
import Datetime from "react-datetime";
import { FaTimes } from "react-icons/fa";
import { Navigate } from "react-router";
import Select, { SingleValue } from "react-select";
import { useEffectOnce } from "react-use";
import { Checkbox } from "../Checkbox";
import { Dialog } from "../Dialog";
import {
  Business,
  addImage,
  updateBusiness,
  createBusiness,
  getAdmins,
  createAdmins,
  listUsersForBusiness,
  excludeModules,
  listExcluded,
  listAdmins,
} from "../api/BusinessApi";
import {
  getBusinessSettings,
  SettingType,
  upsertBusinessSettings,
} from "../api/BusinessSettingApi";
import { getModules, Module } from "../api/ModulesApi";
import { deleteConnectedBusiness, User } from "../api/UserApi";
import { uniqueId } from "../uniqueId";

export function BusinessForm({ business }: { business: Business | null }) {
  const [infoNL, setInfoNL] = useState(business ? business.info_nl : "");
  const [infoEN, setInfoEN] = useState(business ? business.info_en : "");
  const [infoFR, setInfoFR] = useState(business ? business.info_fr : "");
  const [name, setName] = useState(business ? business.name : "");
  const [code, setCode] = useState(business ? business.code : "");
  const [width, setWidth] = useState(business ? business.width : "auto");
  const [height, setHeight] = useState(business ? business.height : "250px");
  const [email, setEmail] = useState(business ? business.email : "");
  const [vat, setVat] = useState(business ? business.vat : "");
  const infoAuthorName = business ? business.infoAuthorName : "";
  const infoAuthorTitleEn = business ? business.infoAuthorTitleEn : "";
  const infoAuthorTitleFr = business ? business.infoAuthorTitleFr : "";
  const infoAuthorTitleNl = business ? business.infoAuthorTitleNl : "";
  const [invoiceAddress, setInvoiceAddress] = useState(
    business ? business.invoiceAddress : "",
  );

  const logo = business ? business.logo : "";
  const [contactEmail, setContactEmail] = useState(
    business ? business.contactEmail : "",
  );

  const [type, setType] = useState(
    business && business.type ? business.type.toString() : "0",
  );

  const [usersInBusiness, setUsersInBusiness] = useState<User[]>([]);
  const [admins, setAdmins] = useState<
    { businessId: string; id: string; userId: string }[]
  >([]);

  const [sendAdmins, setSendAdmins] = useState<{ email: string; id: string }[]>(
    [],
  );

  const [employees, setEmployees] = useState(business ? business.email : "");
  const [preventionService, setPreventionService] = useState(
    business ? business.preventionService : "",
  );

  const [startDate, setStartDate] = useState(
    business
      ? DateTime.fromISO(business.startDate)
      : DateTime.utc().set({ hour: 0, millisecond: 0, minute: 0, second: 0 }),
  );

  const [selectedModules, setSelectedModules] = useState<
    { label: string; value: string }[]
  >([]);

  const [modules, setModules] = useState<Module[]>([]);
  const [businessSettings, setBusinessSettings] = useState<SettingType>({
    disableTests: false,
    enforce2FA: false,
    inactiveSiteLink: process.env.NX_PUBLIC_FRONTEND_URL || "",
  });

  const acceptedTypes: string[] = ["image/png", "image/jpg", "image/jpeg"];
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [deletingUser, setDeletingUser] = useState<User | null>(null);

  const [saved, setSaved] = useState(false);

  useEffectOnce(() => {
    if (business) {
      listUsersForBusiness(business.id).then((result) => {
        setUsersInBusiness(result);
      });

      getBusinessSettings(business.id).then((result) => {
        setBusinessSettings(result.settings);
      });

      getModules().then((r) => {
        setModules(r);

        listExcluded(business.id).then((e) => {
          setSelectedModules(
            e.map((item) => {
              const foundModule = r.find((m) => m.id === item);

              return {
                label: foundModule?.titleNL || "",
                value: foundModule?.id || "",
              };
            }),
          );
        });
      });

      getAdmins(business.id).then((result) => {
        setAdmins(
          result.map((item) => {
            return {
              ...item,
              id: uniqueId(),
            };
          }),
        );

        listAdmins(business.id).then((sa) => {
          sa &&
            setSendAdmins(
              sa.map((i) => {
                return { email: i, id: uniqueId() };
              }),
            );
        });
      });
    }
  });

  if (saved) {
    return <Navigate to={"/dashboard/businesses"} />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          // TODO checks on fields
          e.preventDefault();

          const isoStartDate = startDate.toUTC().toISO();

          if (isoStartDate === null) {
            return;
          }

          let updatedBusiness = {
            code: code.toLowerCase(),
            contactEmail: contactEmail,
            email: email,
            employees: 0,
            height: height,
            info_en: infoEN,
            info_fr: infoFR,
            info_nl: infoNL,
            infoAuthorName: infoAuthorName,
            infoAuthorTitleEn: infoAuthorTitleEn,
            infoAuthorTitleFr: infoAuthorTitleFr,
            infoAuthorTitleNl: infoAuthorTitleNl,
            invoiceAddress: invoiceAddress,
            logo: logo,
            name: name,
            preventionService: preventionService,
            startDate: isoStartDate,
            type: parseInt(type),
            vat: vat,
            width: width,
          };

          let image;

          if (selectedFile) {
            image = await addImage(selectedFile);
          }

          if (image && typeof image !== "string" && image.path) {
            updatedBusiness = {
              ...updatedBusiness,
              logo: image.path,
            };
          }

          if (business) {
            updateBusiness({
              ...updatedBusiness,
              id: business.id,
            }).then(async (r) => {
              await upsertBusinessSettings({
                businessId: business.id,
                settings: businessSettings,
              });

              if (r) {
                await createAdmins(
                  admins
                    .filter((a) => a.userId !== "")
                    .map((a) => {
                      return {
                        ...a,
                        businessId: r.id,
                      };
                    }),
                  r.id,
                );
                await excludeModules(
                  selectedModules
                    .filter((m) => m.value !== "")
                    .map((m) => m.value),
                  r.id,
                );
                setSaved(true);
              }
            });
          } else {
            createBusiness({
              id: "",
              ...updatedBusiness,
            }).then(async (r) => {
              await upsertBusinessSettings({
                businessId: r.id,
                settings: businessSettings,
              });

              if (r) {
                await createAdmins(
                  admins
                    .filter((a) => a.userId !== "")
                    .map((a) => {
                      return {
                        ...a,
                        businessId: r.id,
                      };
                    }),
                  r.id,
                );

                await excludeModules(
                  selectedModules
                    .filter((m) => m.value !== "")
                    .map((m) => m.value),
                  r.id,
                );
                setSaved(true);
              }
            });
          }
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-25">
            <div>
              <h4>Algemene info</h4>
              <label>Naam</label>
              <input
                className="input"
                onChange={(input) => {
                  setName(input.target.value);
                }}
                placeholder="Naam"
                required={true}
                type="text"
                value={name}
              />
              <label>Code</label>
              <input
                className="input"
                onChange={(input) => {
                  setCode(input.target.value);
                }}
                placeholder="Code"
                required={true}
                type="text"
                value={code}
              />
              <label>Beschrijving in het Nederlands</label>
              <textarea
                className="input"
                onChange={(input) => {
                  setInfoNL(input.target.value);
                }}
                placeholder="Beschrijving"
                required={true}
                rows={6}
                value={infoNL}
              />
              <label>Beschrijving in het Engels</label>
              <textarea
                className="input"
                onChange={(input) => {
                  setInfoEN(input.target.value);
                }}
                placeholder="Beschrijving"
                rows={6}
                value={infoEN}
              />
              <label>Beschrijving in het Frans</label>
              <textarea
                className="input"
                onChange={(input) => {
                  setInfoFR(input.target.value);
                }}
                placeholder="Beschrijving"
                rows={6}
                value={infoFR}
              />
              <label>Btw nummer</label>
              <input
                className="input"
                onChange={(input) => {
                  setVat(input.target.value);
                }}
                placeholder="Btw nummer"
                type="text"
                value={vat}
              />
              <label>Facturatieadres</label>
              <input
                className="input"
                onChange={(input) => {
                  setInvoiceAddress(input.target.value);
                }}
                placeholder="Facturatieadres"
                type="text"
                value={invoiceAddress}
              />
              <Checkbox
                onClick={(value) => {
                  setBusinessSettings({
                    ...businessSettings,
                    disableTests: value,
                  });
                }}
                title={"Testen uitschakelen"}
                value={businessSettings.disableTests}
              />
              <Checkbox
                onClick={(value) => {
                  setBusinessSettings({
                    ...businessSettings,
                    enforce2FA: value,
                  });
                }}
                title={"Enforce 2FA"}
                value={businessSettings.enforce2FA}
              />
              <label>Inactive mails site url</label>
              <input
                className="input"
                onChange={(input) => {
                  setBusinessSettings({
                    ...businessSettings,
                    inactiveSiteLink: input.target.value,
                  });
                }}
                placeholder="Inactive mails site url"
                type="text"
                value={businessSettings.inactiveSiteLink}
              />
              <button className="button" type="submit">
                {business ? "Bedrijfscode bijwerken" : "Bedrijfscode toevoegen"}
              </button>
            </div>
          </div>
          <div className="w-25">
            <h4>&nbsp;</h4>
            <label>Logo</label>
            {selectedFile ? (
              <img
                alt={name}
                className="business-image"
                src={URL.createObjectURL(selectedFile)}
              />
            ) : null}
            {logo && !selectedFile ? (
              <img alt={name} className="business-image" src={logo} />
            ) : null}
            <input
              accept={acceptedTypes.toString()}
              className="input"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
              placeholder="Upload afbeelding"
              type="file"
            />
            <label>Email</label>
            <input
              className="input"
              onChange={(input) => {
                setEmail(input.target.value);
              }}
              placeholder="Email"
              type="email"
              value={email}
            />
            <label>Breedte</label>
            <input
              className="input"
              onChange={(input) => {
                setWidth(input.target.value);
              }}
              placeholder="Breedte"
              type="text"
              value={width}
            />
            <label>Hoogte</label>
            <input
              className="input"
              onChange={(input) => {
                setHeight(input.target.value);
              }}
              placeholder="Hoogte"
              type="text"
              value={height}
            />
            <label>Preventiedienst</label>
            <Select
              className="input"
              onChange={(e: SingleValue<{ label: string; value: string }>) => {
                if (e != null) {
                  setPreventionService(e.value);
                }
              }}
              options={[
                {
                  label: "Mensura",
                  value: "Mensura",
                },
                {
                  label: "Liantis",
                  value: "Liantis",
                },
                {
                  label: "Andere",
                  value: "Andere",
                },
              ]}
              placeholder={"Preventiedienst"}
              required={true}
              value={
                preventionService
                  ? {
                      label: preventionService,
                      value: preventionService,
                    }
                  : null
              }
            />
            <label>Startdatum</label>
            <Datetime
              locale="nl-be"
              onChange={(item) => {
                setStartDate(DateTime.fromISO(moment(item).toISOString()));
              }}
              timeFormat={false}
              value={moment(startDate.toISO())}
            />
            <label>Bedrijfstype</label>
            <Select
              className="input"
              onChange={(e: SingleValue<{ label: string; value: string }>) => {
                if (e != null) {
                  setType(e.value);
                }
              }}
              options={[
                {
                  label: "Standaard",
                  value: "0",
                },
                {
                  label: "Premium",
                  value: "1",
                },
                {
                  label: "Genius",
                  value: "2",
                },
              ]}
              placeholder={"Bedrijfstype"}
              required={true}
              value={
                type
                  ? {
                      label:
                        type === "0"
                          ? "Standaard"
                          : type === "1"
                            ? "Premium"
                            : "Genius",
                      value: type,
                    }
                  : null
              }
            />
            <label>Medewerkers</label>
            <input
              className="input"
              min={0}
              onChange={(input) => {
                setEmployees(input.target.value);
              }}
              placeholder="Medewerkers"
              type="number"
              value={employees}
            />
            <label>Contact email</label>
            <input
              className="input"
              onChange={(input) => {
                setContactEmail(input.target.value);
              }}
              placeholder="Email"
              type="email"
              value={contactEmail}
            />
            <b>Bedrijfsadmins</b>
            {usersInBusiness &&
              admins.map((hrAdmin) => {
                const user = usersInBusiness.find(
                  (u) => u.id === hrAdmin.userId,
                );

                return (
                  <>
                    <div className="flex">
                      <label>Gebruiker</label>
                      <div
                        className="ml-auto"
                        onClick={() => {
                          setAdmins(admins.filter((a) => a.id !== hrAdmin.id));
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </div>

                    <Select
                      className="input"
                      onChange={(
                        e: SingleValue<{ label: string; value: string }>,
                      ) => {
                        if (e != null) {
                          setAdmins(
                            admins.map((a) => {
                              if (a.id === hrAdmin.id) {
                                return {
                                  ...a,
                                  userId: e.value,
                                };
                              }

                              return a;
                            }),
                          );
                        }
                      }}
                      options={usersInBusiness.map((u) => {
                        return {
                          label: `${u.firstName} ${u.lastName} (${u.email})`,
                          value: u.id,
                        };
                      })}
                      placeholder={"Gebruiker"}
                      value={
                        user
                          ? {
                              label: `${user.firstName} ${user.lastName} (${user.email})`,
                              value: user.id,
                            }
                          : { label: "", value: "" }
                      }
                    />
                    <hr />
                  </>
                );
              })}
            <div
              onClick={() => {
                setAdmins(
                  admins.concat({ businessId: "", id: uniqueId(), userId: "" }),
                );
              }}
            >
              + admin toevoegen
            </div>
            <b>Admins zonder account</b>
            {sendAdmins.map((sa) => {
              const user = usersInBusiness.find((u) => u.email === sa.email);

              if (!user) {
                return <div>{sa.email}</div>;
              } else {
                return null;
              }
            })}
            <b>Geweigerde modules</b>
            {selectedModules.map((item, i) => {
              return (
                <>
                  <div className="flex">
                    <label>Module</label>
                    <div
                      className="ml-auto"
                      onClick={() => {
                        setSelectedModules(
                          selectedModules.filter((_, index) => i !== index),
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>

                  <Select
                    className="input"
                    onChange={(
                      e: SingleValue<{ label: string; value: string }>,
                    ) => {
                      if (e != null) {
                        setSelectedModules(
                          selectedModules.map((a, index) => {
                            if (i === index) {
                              return e;
                            }

                            return a;
                          }),
                        );
                      }
                    }}
                    options={modules.map((u) => {
                      return {
                        label: u.titleNL,
                        value: u.id,
                      };
                    })}
                    placeholder={"Module"}
                    value={item}
                  />
                  <hr />
                </>
              );
            })}
            <div
              onClick={() => {
                setSelectedModules(
                  selectedModules.concat({ label: "", value: "" }),
                );
              }}
            >
              + module toevoegen
            </div>
          </div>
        </div>
      </form>
      <div className="container">
        <div className="flex">
          <h2>Gebruikers ({usersInBusiness ? usersInBusiness.length : 0})</h2>
        </div>

        {!usersInBusiness || usersInBusiness.length === 0 ? (
          "Momenteel nog geen gebruikers beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Email</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {usersInBusiness.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>
                      {user.firstName} {user.lastName}
                    </td>
                    <td>{user.email}</td>
                    <td>
                      <div
                        className="button small w-fit ml-5 pink"
                        onClick={async () => {
                          setDeletingUser(user);
                        }}
                      >
                        <FaTimes />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <Dialog
        isOpen={deletingUser !== null}
        onRequestClose={() => {
          setDeletingUser(null);
        }}
        padding={true}
        size={"small"}
        title={"Gebruiker verwijderen"}
      >
        <div>
          <div>Bent u zeker dat u deze gebruiker wilt verwijderen?</div>
          <div
            className="button w-fit pink"
            onClick={async () => {
              if (!deletingUser) {
                return;
              }

              if (!business) {
                return;
              }

              await deleteConnectedBusiness(business.id, deletingUser.id);
              setUsersInBusiness(
                usersInBusiness.filter((u) => u.id !== deletingUser.id),
              );
              setDeletingUser(null);
            }}
          >
            Verwijderen
          </div>
        </div>
      </Dialog>
    </>
  );
}
