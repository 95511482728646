import { useState } from "react";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import { Navigate } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { List, getLists } from "../api/ListApi";

export function Lists() {
  const [lists, setLists] = useState<List[]>([]);
  const [addingList, setAddingList] = useState(false);
  const [editingList, setEditingList] = useState<List | null>(null);

  useEffectOnce(() => {
    getLists().then((r) => {
      setLists(r);
    });
  });

  if (addingList) {
    return <Navigate to="/dashboard/lists/new" />;
  }

  if (editingList) {
    return <Navigate to={`/dashboard/lists/${editingList.id}`} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Lijsten</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingList(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        {lists.length === 0 ? (
          "Momenteel nog geen lijsten beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {lists.map((list) => {
                return (
                  <tr key={list.id}>
                    <td>{list.name}</td>
                    <td>
                      <div className="flex">
                        <div
                          className="button small w-fit ml-5 yellow"
                          onClick={() => {
                            setEditingList(list);
                          }}
                        >
                          <FaPen />
                        </div>
                        <div
                          className="button small w-fit ml-5 pink"
                          onClick={async () => {
                            // TODO
                            /*await deleteBusiness(business);
                            setBusinesses(
                              businesses.filter(b => b.id !== business.id)
                            );*/
                          }}
                        >
                          <FaTimes />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
