import { useState } from "react";
import { useEffectOnce } from "react-use";
import { Sidebar } from "./Sidebar";
import { getCmsItem, upsertCmsItem } from "./api/CMSApi";

export function LandingPageCms() {
  const [data, setData] = useState<any>({});
  const [message, setMessage] = useState("");

  useEffectOnce(() => {
    getCmsItem("landingpage").then((result) => {
      const parsed = JSON.parse(result.data);

      setData(parsed);
    });
  });

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Landing page</h2>
        </div>

        <label>Title 1 NL</label>
        <input
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              title1NL: input.target.value,
            });
          }}
          placeholder={"Title 1 NL"}
          type="text"
          value={data.title1NL}
        />
        <label>Text 1 NL</label>
        <textarea
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              text1NL: input.target.value,
            });
          }}
          placeholder={"Text 1 NL"}
          rows={6}
          value={data.text1NL}
        />
        <label>Title 2 NL</label>
        <input
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              title2NL: input.target.value,
            });
          }}
          placeholder={"Title 2 NL"}
          type="text"
          value={data.title2NL}
        />
        <label>Text 2 NL</label>
        <textarea
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              text2NL: input.target.value,
            });
          }}
          placeholder={"Text 2 NL"}
          rows={6}
          value={data.text2NL}
        />

        <label>Title 1 EN</label>
        <input
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              title1EN: input.target.value,
            });
          }}
          placeholder={"Title 1 EN"}
          type="text"
          value={data.title1EN}
        />
        <label>Text 1 EN</label>
        <textarea
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              text1EN: input.target.value,
            });
          }}
          placeholder={"Text 1 EN"}
          rows={6}
          value={data.text1EN}
        />
        <label>Title 2 EN</label>
        <input
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              title2EN: input.target.value,
            });
          }}
          placeholder={"Title 2 EN"}
          type="text"
          value={data.title2EN}
        />
        <label>Text 2 EN</label>
        <textarea
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              text2EN: input.target.value,
            });
          }}
          placeholder={"Text 2 EN"}
          rows={6}
          value={data.text2EN}
        />

        <label>Title 1 FR</label>
        <input
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              title1FR: input.target.value,
            });
          }}
          placeholder={"Title 1 FR"}
          type="text"
          value={data.title1FR}
        />
        <label>Text 1 FR</label>
        <textarea
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              text1FR: input.target.value,
            });
          }}
          placeholder={"Text 1 FR"}
          rows={6}
          value={data.text1FR}
        />
        <label>Title 2 FR</label>
        <input
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              title2FR: input.target.value,
            });
          }}
          placeholder={"Title 2 FR"}
          type="text"
          value={data.title2FR}
        />
        <label>Text 2 FR</label>
        <textarea
          className="input"
          onChange={(input) => {
            setData({
              ...data,
              text2FR: input.target.value,
            });
          }}
          placeholder={"Text 2 FR"}
          rows={6}
          value={data.text2FR}
        />
      </div>
      <div className="mt-4 mb-4">{message}</div>
      <div
        className="button w-10 ml-auto mr-auto"
        onClick={() => {
          upsertCmsItem("landingpage", JSON.stringify(data));
          setMessage("Opgeslagen!");
        }}
      >
        Opslaan
      </div>
    </Sidebar>
  );
}
