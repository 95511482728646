import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { createEvaluation } from "../api/EvaluationsApi";

export function Evaluation() {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [tevreden, setTevreden] = useState("10");
  const [interessant, setInteressant] = useState("");
  const [minder, setMinder] = useState("");
  const [onduidelijk, setOnduidelijk] = useState("");
  const [relevant, setRelevant] = useState("");
  const [aanbevelen, setAanbevelen] = useState("10");
  const [opmerkingen, setOpmerkingen] = useState("");
  const [ervaring, setErvaring] = useState("");
  const [voornaam, setVoornaam] = useState("");
  const [woonplaats, setWoonplaats] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation("general");

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await createEvaluation(
          {
            experience: ervaring,
            interesting: interessant,
            less: minder,
            name: voornaam !== "" ? voornaam : undefined,
            place: woonplaats !== "" ? woonplaats : undefined,
            recommendation: parseInt(aanbevelen),
            relevant: relevant,
            remarks: opmerkingen,
            score: parseInt(tevreden),
            unclear: onduidelijk,
          },
          moduleId,
        );

        setSubmitted(true);
      }}
    >
      <label>{t("evaluation.score")}</label>
      <div className="flex">
        {Array.from(Array(11).keys()).map((item) => {
          return (
            <div className="flex flex-column items-center" key={item}>
              <input
                checked={item === parseInt(tevreden)}
                name="tevreden"
                onChange={(e) => {
                  setTevreden(e.target.value);
                }}
                required
                type="radio"
                value={item}
              />{" "}
              {item}
            </div>
          );
        })}
      </div>
      <div className="mt-4" />
      <label>{t("evaluation.interesting")}</label>
      <input
        onChange={(e) => {
          setInteressant(e.target.value);
        }}
        type="text"
        value={interessant}
      />
      <div className="mt-4" />
      <label>{t("evaluation.less")}</label>
      <input
        onChange={(e) => {
          setMinder(e.target.value);
        }}
        type="text"
        value={minder}
      />
      <div className="mt-4" />
      <label>{t("evaluation.unclear")}</label>
      <input
        onChange={(e) => {
          setOnduidelijk(e.target.value);
        }}
        type="text"
        value={onduidelijk}
      />
      <div className="mt-4" />
      <label>{t("evaluation.relevant")}</label>
      <input
        onChange={(e) => {
          setRelevant(e.target.value);
        }}
        type="text"
        value={relevant}
      />
      <div className="mt-4" />
      <label>{t("evaluation.recommendation")}</label>
      <div className="flex">
        {Array.from(Array(11).keys()).map((item) => {
          return (
            <div className="flex flex-column items-center" key={item}>
              <input
                checked={item === parseInt(aanbevelen)}
                name="aanbevelen"
                onChange={(e) => {
                  setAanbevelen(e.target.value);
                }}
                required
                type="radio"
                value={item}
              />{" "}
              {item}
            </div>
          );
        })}
      </div>
      <div className="mt-4" />
      <label>{t("evaluation.remarks")}</label>
      <textarea
        onChange={(e) => {
          setOpmerkingen(e.target.value);
        }}
        value={opmerkingen}
      />
      <div className="mt-4" />
      <label>{t("evaluation.experience")}</label>
      <textarea
        onChange={(e) => {
          setErvaring(e.target.value);
        }}
        value={ervaring}
      />
      <div className="mt-4" />
      <label>{t("evaluation.name")}</label>
      <input
        onChange={(e) => {
          setVoornaam(e.target.value);
        }}
        type="text"
        value={voornaam}
      />
      <div className="mt-4" />
      <label>{t("evaluation.place")}</label>
      <input
        onChange={(e) => {
          setWoonplaats(e.target.value);
        }}
        type="text"
        value={woonplaats}
      />
      {submitted ? (
        <div className="green mt-4 mb-4">Succesvol ingediend!</div>
      ) : null}
      <button className="button w-fit" type="submit">
        {t("evaluation.send")}
      </button>
    </form>
  );
}
