import { useEffect, useState } from "react";
import "./BusinessMail.css";
import { useTranslation } from "react-i18next";
import { BsFillPersonFill } from "react-icons/bs";
import { useEffectOnce } from "react-use";
import { ConnectedBusiness, getConnectedBusinesses } from "./api/BusinessApi";
import Camera from "./img/email.png";

export function BusinessMail({ authenticated }: { authenticated: boolean }) {
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);

  const [hover, setHover] = useState<boolean>(false);

  useEffectOnce(() => {
    getConnectedBusinesses().then((result) => {
      setConnectedBusinesses(result);
    });
  });

  useEffect(() => {
    getConnectedBusinesses().then((result) => {
      setConnectedBusinesses(result);
    });
  }, [authenticated]);

  const { t } = useTranslation("general");

  const business =
    connectedBusinesses && connectedBusinesses.length > 0
      ? connectedBusinesses[0]
      : null;

  if (!business) {
    return null;
  }

  if (!business.business.contactEmail) {
    return null;
  }

  return hover ? (
    <div className="business-mail-wrapper" onMouseLeave={() => setHover(false)}>
      <BsFillPersonFill className={"person-icon"} />
      <a
        className="business-mail"
        href={`mailto:${business.business.contactEmail}`}
        title="Contacteer jouw business"
      >
        <div className={"button"}>{t("businessMail")}</div>
      </a>
    </div>
  ) : (
    <div
      className="business-mail-icon pointer"
      onClick={() => setHover(true)} //mobile
      onMouseEnter={() => setHover(true)}
    >
      <img alt="mail info" src={Camera} />
    </div>
  );
}
