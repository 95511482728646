import { Sidebar } from "../Sidebar";
import { TestimonialForm } from "./TestimonialForm";

export function CreateTestimonial() {
  return (
    <Sidebar>
      <TestimonialForm />
    </Sidebar>
  );
}
