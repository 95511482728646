import { useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { NavLink } from "react-router";

export function UserDashboardSidebarMobile({
  handleClick,
  routes,
}: {
  handleClick: () => void;
  routes: { icon: string; name: string; url: string }[];
}) {
  function unMountHamburger() {
    document.body.style.overflow = "auto";
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => unMountHamburger();
  }, []);

  return (
    <div className="hamburger-menu">
      <RxCross2
        className="user-sidebar-cross mr-auto"
        onClick={handleClick}
        size={30}
      />
      {routes.map((item) => {
        return (
          <NavLink
            className="sidebar-item"
            key={item.url}
            onClick={handleClick}
            to={item.url}
          >
            <img alt="icon" className="icon" src={item.icon} />
            <div>{item.name}</div>
          </NavLink>
        );
      })}
    </div>
  );
}
