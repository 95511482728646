import { useState } from "react";
import { Navigate } from "react-router";
import Select from "react-select";
import { createQuiz, QuizType, updateQuiz } from "../api/QuizApi";

const types = [
  { label: "Multiple choice", value: "multiple" },
  { label: "Single", value: "single" },
];

export function QuizForm({ quiz }: { quiz: QuizType | null }) {
  const [data, setData] = useState(quiz ? quiz.quiz : []);

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Navigate to={"/dashboard/quizzes"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const updatedBusiness = {
          quiz: data
            .filter((q) => q.questionNL.trim() !== "")
            .map((d) => {
              return {
                ...d,
                optionsEN: d.optionsEN.filter(
                  (q) => q.label.trim() !== "" && q.value.trim() !== "",
                ),
                optionsFR: d.optionsFR.filter(
                  (q) => q.label.trim() !== "" && q.value.trim() !== "",
                ),
                optionsNL: d.optionsNL.filter(
                  (q) => q.label.trim() !== "" && q.value.trim() !== "",
                ),
              };
            }),
        };

        if (quiz) {
          updateQuiz({
            ...updatedBusiness,
            id: quiz.id,
          }).then(async () => {
            setSaved(true);
          });
        } else {
          createQuiz({
            id: "",
            ...updatedBusiness,
          }).then(async () => {
            setSaved(true);
          });
        }
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            {data.map((item, index) => {
              const foundType = types.find((t) => t.value === item.type);

              return (
                <div>
                  <label>Vraag NL</label>
                  <input
                    className="input"
                    onChange={(input) => {
                      setData(
                        data.map((d, i) => {
                          if (i === index) {
                            return {
                              ...d,
                              questionNL: input.target.value,
                            };
                          }

                          return d;
                        }),
                      );
                    }}
                    placeholder="Naam"
                    required={true}
                    type="text"
                    value={item.questionNL}
                  />
                  <label>Vraag EN</label>
                  <input
                    className="input"
                    onChange={(input) => {
                      setData(
                        data.map((d, i) => {
                          if (i === index) {
                            return {
                              ...d,
                              questionEN: input.target.value,
                            };
                          }

                          return d;
                        }),
                      );
                    }}
                    placeholder="Naam"
                    type="text"
                    value={item.questionEN}
                  />
                  <label>Vraag FR</label>
                  <input
                    className="input"
                    onChange={(input) => {
                      setData(
                        data.map((d, i) => {
                          if (i === index) {
                            return {
                              ...d,
                              questionFR: input.target.value,
                            };
                          }

                          return d;
                        }),
                      );
                    }}
                    placeholder="Naam"
                    type="text"
                    value={item.questionFR}
                  />

                  <label>Type vraag</label>
                  <Select
                    onChange={(e: any) => {
                      setData(
                        data.map((d, i) => {
                          if (i === index) {
                            return {
                              ...d,
                              type: e.value,
                            };
                          }

                          return d;
                        }),
                      );
                    }}
                    options={types}
                    value={foundType ? foundType : { label: "", value: "" }}
                  />

                  <label>Opties NL</label>
                  {item.optionsNL.map((option, optionIndex) => {
                    return (
                      <div>
                        <div>
                          <label>Naam</label>
                          <input
                            className="input"
                            onChange={(e) => {
                              setData(
                                data.map((t, i) => {
                                  if (i === index) {
                                    return {
                                      ...t,
                                      optionsNL: item.optionsNL.map((o, oi) => {
                                        if (oi === optionIndex) {
                                          return {
                                            ...o,
                                            label: e.target.value,
                                          };
                                        }

                                        return o;
                                      }),
                                    };
                                  }

                                  return t;
                                }),
                              );
                            }}
                            type="text"
                            value={option.label}
                          />
                        </div>
                        <div>
                          <label>Waarde</label>
                          <input
                            className="input"
                            onChange={(e) => {
                              setData(
                                data.map((t, i) => {
                                  if (i === index) {
                                    return {
                                      ...t,
                                      optionsNL: item.optionsNL.map((o, oi) => {
                                        if (oi === optionIndex) {
                                          return {
                                            ...o,
                                            value: e.target.value,
                                          };
                                        }

                                        return o;
                                      }),
                                    };
                                  }

                                  return t;
                                }),
                              );
                            }}
                            type="text"
                            value={option.value}
                          />
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                  <div
                    className="mb-4 pointer"
                    onClick={() => {
                      setData(
                        data.map((t, i) => {
                          if (i === index) {
                            return {
                              ...t,
                              optionsNL: item.optionsNL.concat({
                                label: "",
                                value: "",
                              }),
                            };
                          }

                          return t;
                        }),
                      );
                    }}
                  >
                    + Optie NL toevoegen
                  </div>

                  <label>Opties EN</label>
                  {item.optionsEN.map((option, optionIndex) => {
                    return (
                      <div>
                        <div>
                          <label>Naam</label>
                          <input
                            className="input"
                            onChange={(e) => {
                              setData(
                                data.map((t, i) => {
                                  if (i === index) {
                                    return {
                                      ...t,
                                      optionsEN: item.optionsEN.map((o, oi) => {
                                        if (oi === optionIndex) {
                                          return {
                                            ...o,
                                            label: e.target.value,
                                          };
                                        }

                                        return o;
                                      }),
                                    };
                                  }

                                  return t;
                                }),
                              );
                            }}
                            type="text"
                            value={option.label}
                          />
                        </div>
                        <div>
                          <label>Waarde</label>
                          <input
                            className="input"
                            onChange={(e) => {
                              setData(
                                data.map((t, i) => {
                                  if (i === index) {
                                    return {
                                      ...t,
                                      optionsEN: item.optionsEN.map((o, oi) => {
                                        if (oi === optionIndex) {
                                          return {
                                            ...o,
                                            value: e.target.value,
                                          };
                                        }

                                        return o;
                                      }),
                                    };
                                  }

                                  return t;
                                }),
                              );
                            }}
                            type="text"
                            value={option.value}
                          />
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                  <div
                    className="mb-4 pointer"
                    onClick={() => {
                      setData(
                        data.map((t, i) => {
                          if (i === index) {
                            return {
                              ...t,
                              optionsEN: item.optionsEN.concat({
                                label: "",
                                value: "",
                              }),
                            };
                          }

                          return t;
                        }),
                      );
                    }}
                  >
                    + Optie EN toevoegen
                  </div>

                  <label>Opties FR</label>
                  {item.optionsFR.map((option, optionIndex) => {
                    return (
                      <div>
                        <div>
                          <label>Naam</label>
                          <input
                            className="input"
                            onChange={(e) => {
                              setData(
                                data.map((t, i) => {
                                  if (i === index) {
                                    return {
                                      ...t,
                                      optionsFR: item.optionsFR.map((o, oi) => {
                                        if (oi === optionIndex) {
                                          return {
                                            ...o,
                                            label: e.target.value,
                                          };
                                        }

                                        return o;
                                      }),
                                    };
                                  }

                                  return t;
                                }),
                              );
                            }}
                            type="text"
                            value={option.label}
                          />
                        </div>
                        <div>
                          <label>Waarde</label>
                          <input
                            className="input"
                            onChange={(e) => {
                              setData(
                                data.map((t, i) => {
                                  if (i === index) {
                                    return {
                                      ...t,
                                      optionsFR: item.optionsFR.map((o, oi) => {
                                        if (oi === optionIndex) {
                                          return {
                                            ...o,
                                            value: e.target.value,
                                          };
                                        }

                                        return o;
                                      }),
                                    };
                                  }

                                  return t;
                                }),
                              );
                            }}
                            type="text"
                            value={option.value}
                          />
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                  <div
                    className="mb-4 pointer"
                    onClick={() => {
                      setData(
                        data.map((t, i) => {
                          if (i === index) {
                            return {
                              ...t,
                              optionsFR: item.optionsFR.concat({
                                label: "",
                                value: "",
                              }),
                            };
                          }

                          return t;
                        }),
                      );
                    }}
                  >
                    + Optie FR toevoegen
                  </div>

                  <label>Antwoord(en)</label>
                  <input
                    className="input"
                    onChange={(input) => {
                      setData(
                        data.map((d, i) => {
                          if (i === index) {
                            return {
                              ...d,
                              answer: input.target.value,
                            };
                          }

                          return d;
                        }),
                      );
                    }}
                    placeholder="Naam"
                    type="text"
                    value={item.answer}
                  />
                </div>
              );
            })}

            <button className="button" type="submit">
              {quiz ? "Quiz bijwerken" : "Quiz toevoegen"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
