import { useState } from "react";
import { FaEye, FaPen, FaTimes, FaPlus } from "react-icons/fa";
import { Navigate } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../../Sidebar";
import { deleteModule, getModules, Module } from "../../api/ModulesApi";

export function AdminModules() {
  const [modules, setModules] = useState<Module[]>([]);
  const [viewingModule, setViewingModule] = useState<false | Module>(false);
  const [editingModule, setEditingModule] = useState<false | Module>(false);
  const [editingModuleSubjects, setEditingModuleSubjects] = useState<
    false | Module
  >(false);

  const [addingModule, setAddingModule] = useState(false);

  useEffectOnce(() => {
    getModules().then((result) => {
      setModules(result);
    });
  });

  if (viewingModule) {
    return <Navigate to={`/modules/${viewingModule.id}`} />;
  }

  if (editingModule) {
    return <Navigate to={`/dashboard/modules/${editingModule.id}`} />;
  }

  if (editingModuleSubjects) {
    return (
      <Navigate
        to={`/dashboard/modules/${editingModuleSubjects.id}/subjects`}
      />
    );
  }

  if (addingModule) {
    return <Navigate to={"/dashboard/modules/new"} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Modules</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingModule(true);
            }}
          >
            <FaPlus />
          </div>
        </div>
        <table className="table ">
          <thead>
            <tr>
              <th>Naam</th>
              <th>Onderwerpen</th>
              <th>Algemene info</th>
            </tr>
          </thead>
          <tbody>
            {modules.map((module) => {
              return (
                <tr>
                  <td>{module.titleNL}</td>
                  <td>
                    <div className="flex">
                      <div
                        className="button small w-fit ml-auto mr-auto yellow"
                        onClick={() => {
                          setEditingModuleSubjects(module);
                        }}
                      >
                        <FaPen />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex">
                      <div
                        className="button small w-fit ml-auto"
                        onClick={() => {
                          setViewingModule(module);
                        }}
                      >
                        <FaEye />
                      </div>
                      <div
                        className="button small w-fit ml-5 yellow"
                        onClick={() => {
                          setEditingModule(module);
                        }}
                      >
                        <FaPen />
                      </div>
                      <div
                        className="button small w-fit ml-5 pink"
                        onClick={async () => {
                          await deleteModule(module.id);
                          setModules(modules.filter((m) => m.id !== module.id));
                        }}
                      >
                        <FaTimes />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Sidebar>
  );
}
