import { useEffectOnce, useSearchParam } from "react-use";
import { removeInactive } from "./api/AuthApi";

function Inactive() {
  const email = useSearchParam("email");

  useEffectOnce(() => {
    if (email) {
      removeInactive(email);
    }
  });

  return (
    <div className="auth">
      <h2>Successvol uitgeschreven</h2>
    </div>
  );
}

export default Inactive;
