import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { useEffectOnce } from "react-use";
import { getCorrectUrl } from "./Utils";
import { getLessonsByModuleAndSubject, Lesson } from "./api/LessonsApi";
import { Module } from "./api/ModulesApi";
import { getSubjects } from "./api/SubjectsApi";
import { getModuleProgress, ModuleProgress as Progress } from "./api/UserApi";

export function HomeModule({ module }: { module: Module }) {
  const [moduleProgress, setModuleProgress] = useState<Progress[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);

  const { i18n } = useTranslation("general");

  useEffectOnce(() => {
    getSubjects(module.id).then(async (result) => {
      const tempLessons: Lesson[] = [];

      for (const subject of result) {
        const subjectLessons = await getLessonsByModuleAndSubject(
          module.id,
          subject.id,
        );

        tempLessons.push(...subjectLessons);
      }

      setLessons(tempLessons);
    });

    getModuleProgress(module.id).then((result) => {
      setModuleProgress(result);
    });
  });

  const visited = moduleProgress.map((i) => i.lessonId);
  const percentage =
    lessons.length !== 0
      ? Math.min(
          Math.floor((new Set(visited).size / lessons.length) * 100),
          100,
        )
      : 100;

  const latestVisit = moduleProgress
    ? moduleProgress[moduleProgress.length - 1]
    : null;

  return (
    <Link
      className="flex module-wrapper"
      to={
        latestVisit
          ? `/modules/${module.id}/subjects/${latestVisit.subjectId}/lessons/${latestVisit.lessonId}`
          : `/modules/${module.id}`
      }
    >
      <div
        className="coloured-border"
        style={{ backgroundColor: "var(--yellow)" }}
      />
      <div className="flex module-block">
        <div className="module-img">
          <img
            alt={module.titleNL}
            className="img"
            src={getCorrectUrl(module.image)}
          />
        </div>
        <div className="module-content">
          <div className="module-title">
            {i18n.language === "nl"
              ? module.titleNL
              : i18n.language === "en"
                ? module.titleEN
                : module.titleFR}
          </div>
          <div className="module-bar-wrapper">
            <div
              className="module-bar"
              style={{
                width: `${percentage}%`,
              }}
            />
          </div>
        </div>
        <div className="arrow-blue" />
      </div>
    </Link>
  );
}
