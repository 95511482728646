import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiFacebookFill,
  RiInstagramLine,
  RiLinkedinFill,
} from "react-icons/ri";
import { Link } from "react-router";
import { MindlabVersion } from "./MindlabVersion";
import { getConnectedBusinessesForUser } from "./api/BusinessApi";
import { getCurrentUser, getHasSkills } from "./api/UserApi";
import Logo from "./img/Bloomup Learn.svg";
import "./Footer.css";

export function Footer({ authenticated }: { authenticated: boolean }) {
  const { i18n, t } = useTranslation("footer");

  const [hasSkills, setHasSkills] = useState(false);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    getHasSkills().then((result) => setHasSkills(result));

    getCurrentUser().then((user) => {
      if (!user) {
        return;
      }

      getConnectedBusinessesForUser(user.id).then((result) => {
        if (result.length === 0) {
          return;
        }
      });
    });
  }, [authenticated]);

  return (
    <div className="footer">
      <div className="flex mb-4 flex-wrapper">
        <div className="flex flex-column">
          <img alt="Mindlab by BloomUp" className="footer-logo" src={Logo} />
          <div className="flex socials">
            <a
              className="social"
              href={"https://www.linkedin.com/company/bloomup-org/"}
              rel="noreferrer"
              target="_blank"
            >
              <RiLinkedinFill />
            </a>
            <a
              className="social"
              href={"https://www.instagram.com/bloomup.talk/"}
              rel="noreferrer"
              target="_blank"
            >
              <RiInstagramLine />
            </a>
            <a
              className="social"
              href={"https://www.facebook.com/bloomup.talk/"}
              rel="noreferrer"
              target="_blank"
            >
              <RiFacebookFill />
            </a>
          </div>
        </div>
        <div className="column">
          <div className="title">{t("home")}</div>
          <Link to={"/skills"}>{t("skills")}</Link>
          <Link to={"/modules"}>{t("modules")}</Link>
          {hasSkills ? <Link to={"/skills"}>{t("skills")}</Link> : null}
          <Link to={"/blog"}>{t("blog")}</Link>
        </div>
        <div className="column">
          <div className="title">{t("company")}</div>
          <a
            href={
              i18n.language === "nl"
                ? "/Externe_Privacyverklaring_Mindlab.pdf"
                : i18n.language === "en"
                  ? "/Externe_Privacyverklaring_Mindlab_en.pdf"
                  : "/Externe_Privacyverklaring_Mindlab_fr.pdf"
            }
            rel="noreferrer"
            target="_blank"
          >
            {t("privacyPolicy")}
          </a>
          <Link to={"/general-terms"}>{t("generalTerms")}</Link>
          <Link to={"/faq"}>{t("faq")}</Link>
        </div>
        <div className="column">
          <div className="title">{t("address")}</div>
          <div>Brain Embassy</div>
          <div>Molenbergstraat 10 bus 25</div>
          <div>2000 Antwerpen</div>
          <div>KBO 0760.433.775</div>
          <a href="mailto:hello@mindlab.be">hello@mindlab.be</a>
        </div>
      </div>
      <div className="mt-4 text-center">
        Copyright 2025 BloomUp all rights reserved.
      </div>
      <MindlabVersion />
    </div>
  );
}
