import { Fragment } from "react";
import "./Modules.css";
import { Footer } from "../../Footer";
import { ModulesList } from "./ModulesList";

export function Skills({ authenticated }: { authenticated: boolean }) {
  return (
    <Fragment>
      <ModulesList skills={true} />
      <Footer authenticated={authenticated} />
    </Fragment>
  );
}
