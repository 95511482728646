import { Sidebar } from "../../Sidebar";
import { LessonForm } from "./LessonForm";

export function CreateLesson() {
  return (
    <Sidebar>
      <LessonForm lesson={null} />
    </Sidebar>
  );
}
