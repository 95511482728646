import { Sidebar } from "../Sidebar";
import { BlogForm } from "./BlogForm";

export function CreateBlog() {
  return (
    <Sidebar>
      <BlogForm blog={null} />
    </Sidebar>
  );
}
