import { Fragment, useState } from "react";
import "./Faq.css";
import { Trans, useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { FaqItem } from "./FaqItem";
import { Footer } from "./Footer";
import { getCmsItem } from "./api/CMSApi";

export function Faq({ authenticated }: { authenticated: boolean }) {
  const { i18n, t } = useTranslation("general");
  const [data, setData] = useState<any>([]);

  useEffectOnce(() => {
    getCmsItem("faq").then((result) => {
      try {
        const parsed = JSON.parse(result.data);

        setData(parsed || []);
      } catch (e) {
        console.log(e);
      }
    });
  });

  const faqItems = [
    { text: t("faq1-text"), title: t("faq1-title") },
    { text: t("faq2-text"), title: t("faq2-title") },
    { text: t("faq3-text"), title: t("faq3-title") },
    { text: t("faq4-text"), title: t("faq4-title") },
    { text: t("faq5-text"), title: t("faq5-title") },
    {
      text: (
        <Trans i18nKey={"faq6-text"} t={t}>
          <a href={"https://www.bloomup.org"} />
        </Trans>
      ),
      title: t("faq6-title"),
    },
    { text: t("faq7-text"), title: t("faq7-title") },
    { text: t("faq8-text"), title: t("faq8-title") },
    { text: t("faq9-text"), title: t("faq9-title") },
    { text: t("faq10-text"), title: t("faq10-title") },
    { text: t("faq11-text"), title: t("faq11-title") },
  ];

  return (
    <Fragment>
      <div className="container faq">
        <h1>FAQ</h1>
        <div>
          {data.map((item: any) => {
            return (
              <FaqItem
                text={
                  i18n.language === "nl"
                    ? item.answerNL
                    : i18n.language === "en"
                      ? item.answerEN
                      : item.answerFR
                }
                title={
                  i18n.language === "nl"
                    ? item.questionNL
                    : i18n.language === "en"
                      ? item.questionEN
                      : item.questionFR
                }
              />
            );
          })}
          {data.length === 0
            ? faqItems
                .filter((i) => i.title.trim() !== "")
                .map((item) => {
                  return <FaqItem text={item.text as any} title={item.title} />;
                })
            : null}
        </div>
      </div>
      <Footer authenticated={authenticated} />
    </Fragment>
  );
}
