import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { useEffectOnce } from "react-use";
import { ToolboxHeader } from "./ToolboxHeader";
import {
  getLessonById,
  getResults,
  Lesson,
  ResultType,
} from "./api/LessonsApi";
import { getModules, Module } from "./api/ModulesApi";
import Oefeningen from "./img/oefeningen.png";

export function ExercisesResults() {
  const [results, setResults] = useState<ResultType[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [modules, setModules] = useState<Module[]>([]);

  const { i18n, t } = useTranslation("general");

  useEffectOnce(() => {
    getResults().then(async (result) => {
      setResults(result);

      const tmpLessons = [];

      for (const favorite of result) {
        const lesson = await getLessonById(favorite.lessonId);

        if (!tmpLessons.map((t) => t.id).includes(lesson.id)) {
          tmpLessons.push(lesson);
        }
      }

      setLessons(tmpLessons);
    });

    getModules().then((result) => {
      setModules(result);
    });
  });

  const mappedResults = results.map((r) => r.lessonId);

  return (
    <ToolboxHeader>
      <div className="container crisishulp w-100">
        <div className="tools">
          <div className="bread">
            <img src={Oefeningen} />
            <div className="title">{t("toolbox.exercises_name")}</div>
          </div>
        </div>
        <div className="favorites">
          {modules.map((module) => {
            const lessonsInModule = lessons.filter(
              (l) =>
                l.moduleId === module.id &&
                l.type === "exercise" &&
                mappedResults.includes(l.id),
            );

            return (
              <div className="all-item">
                <div className="all-title">
                  {i18n.language === "nl" && module.titleNL}
                  {i18n.language === "en" && module.titleEN}
                  {i18n.language === "fr" && module.titleFR}
                </div>
                {lessonsInModule.map((item) => {
                  return (
                    <div>
                      <div className="favorite-item">
                        <Link
                          to={`/modules/${item.moduleId}/subjects/${item.subjectId}/lessons/${item.id}`}
                        >
                          {i18n.language === "nl" && item.nameNL}
                          {i18n.language === "en" && item.nameEN}
                          {i18n.language === "fr" && item.nameFR}
                        </Link>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </ToolboxHeader>
  );
}
