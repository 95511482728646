import { useState } from "react";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { Discount, getDiscounts } from "../api/DiscountsApi";
import { DiscountForm } from "./DiscountForm";

export function EditDiscount() {
  const { name } = useParams<{ name: string }>();
  const [discount, setDiscount] = useState<Discount | null>(null);

  useEffectOnce(() => {
    getDiscounts().then((r) => {
      setDiscount(r.find((c) => c.name === name) || null);
    });
  });

  if (!discount) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <DiscountForm discount={discount} />
    </Sidebar>
  );
}
