import { useEffect, useState } from "react";
import { Navigate } from "react-router";
import {
  createTestimonial,
  Testimonial,
  updateTestimonial,
} from "../api/TestimonialsApi";

export function TestimonialForm({
  testimonial,
}: {
  testimonial?: Testimonial;
}) {
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [score, setScore] = useState(5);

  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (!testimonial) {
      return;
    }

    setText(testimonial.text);
    setName(testimonial.name);
    setScore(testimonial.score);
  }, [testimonial]);

  if (saved) {
    return <Navigate to={"/dashboard/testimonials"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        if (testimonial) {
          updateTestimonial(testimonial.id, text, name, score).then(
            async () => {
              setSaved(true);
            },
          );
        } else {
          createTestimonial(text, name, score).then(async () => {
            setSaved(true);
          });
        }
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Text</label>
            <textarea
              className="input"
              maxLength={300}
              onChange={(e) => {
                setText(e.target.value);
              }}
              placeholder="Text"
              rows={6}
              value={text}
            />
            <label>Name</label>
            <input
              className="input"
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Text"
              type="text"
              value={name}
            />
            <label>Score</label>
            <input
              className="input"
              max={5}
              min={0}
              onChange={(e) => {
                setScore(parseInt(e.target.value));
              }}
              placeholder="Text"
              type="number"
              value={score}
            />

            <button className="button" type="submit">
              Testimonial {testimonial ? "updaten" : "toevoegen"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
