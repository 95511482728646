import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import Select from "react-select";
import { useEffectOnce } from "react-use";
import { BusinessSidebar } from "../BusinessSidebar";
import { Dialog } from "../Dialog";
import { HrAdminsForm } from "../HrAdminsForm";
import {
  ConnectedBusiness,
  getConnectedBusinesses,
  listAdmins,
  updateBusinessPageUser,
  updateBusinessUser,
} from "../api/BusinessApi";
import { uniqueId } from "../uniqueId";
import "./BusinessInfo.css";

const QuillModules = {
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ list: "ordered" }, { list: "bullet" }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    ["link"],
  ],
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const QuillFormats = [
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "link",
];

export function BusinessInfo() {
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);

  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [name, setName] = useState("");
  const [employees, setEmployees] = useState("0");
  const [admins, setAdmins] = useState<{ email: string; id: string }[]>([]);
  const [introductionNL, setIntroductionNL] = useState("");
  const [introductionEN, setIntroductionEN] = useState("");
  const [introductionFR, setIntroductionFR] = useState("");
  const [vat, setVat] = useState("");
  const [invoiceAddress, setInvoiceAddress] = useState("");
  const [email, setEmail] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [authorTitleNl, setAuthorTitleNl] = useState("");
  const [authorTitleEn, setAuthorTitleEn] = useState("");
  const [authorTitleFr, setAuthorTitleFr] = useState("");
  const [saved, setSaved] = useState(false);
  const [saved2, setSaved2] = useState(false);
  const { t } = useTranslation("hr-dashboard");
  const [openAdminsPopup, setOpenAdminsPopup] = useState(false);

  const foundBusiness = connectedBusinesses.find(
    (b) => b.business.id === selectedBusiness,
  );

  useEffectOnce(() => {
    getConnectedBusinesses().then((result) => {
      setConnectedBusinesses(result);

      if (result.length > 0) {
        setSelectedBusiness(result[0].business.id);

        listAdmins(result[0].business.id).then((r) => {
          setAdmins(
            r
              .reverse()
              .map((i) => {
                return { email: i, id: uniqueId() };
              })
              .concat({ email: "", id: uniqueId() }),
          );
        });
      }
    });
  });

  useEffect(() => {
    if (foundBusiness) {
      setName(foundBusiness.business.name);
      setEmployees(
        foundBusiness.business.employees
          ? foundBusiness.business.employees.toString()
          : "0",
      );
      setIntroductionNL(foundBusiness.business.info_nl);
      setIntroductionEN(foundBusiness.business.info_en);
      setIntroductionFR(foundBusiness.business.info_fr);
      setEmail(foundBusiness.business.contactEmail);
      setVat(foundBusiness.business.vat);
      setInvoiceAddress(foundBusiness.business.invoiceAddress);
      setAuthorName(foundBusiness.business.infoAuthorName);
      setAuthorTitleNl(foundBusiness.business.infoAuthorTitleNl);
      setAuthorTitleEn(foundBusiness.business.infoAuthorTitleEn);
      setAuthorTitleFr(foundBusiness.business.infoAuthorTitleFr);

      listAdmins(selectedBusiness).then((r) => {
        setAdmins(
          r
            .map((i) => {
              return { email: i, id: uniqueId() };
            })
            .concat({ email: "", id: uniqueId() }),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  return (
    <BusinessSidebar>
      {connectedBusinesses.length > 1 ? (
        <div className="flex w-100">
          <div
            className={`ml-auto business-select ${
              isMobile ? "w-60 mt-4" : "w-15"
            }`}
          >
            <Select
              onChange={(e: any) => {
                setSelectedBusiness(e.value);
              }}
              options={connectedBusinesses.map((b) => {
                return {
                  label: b.business.name,
                  value: b.business.id,
                };
              })}
              value={
                foundBusiness
                  ? {
                      label: foundBusiness.business.name,
                      value: foundBusiness.business.id,
                    }
                  : { label: "", value: "" }
              }
            />
          </div>
        </div>
      ) : null}
      <div className="business-information-container">
        <div className="business-title">{t("businessInfo.title")}</div>
        <form
          className="business-info-items"
          onSubmit={async (e) => {
            e.preventDefault();
            await updateBusinessUser(
              name,
              email,
              parseInt(employees),
              selectedBusiness,
              vat,
              invoiceAddress,
            );
            setSaved(true);

            setTimeout(() => {
              setSaved(false);
            }, 3000);
          }}
        >
          <div className="business-info-item">
            <label>
              {t("businessInfo.businessName")} <span className="red">*</span>
            </label>
            <input
              className="input"
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder={t("businessInfo.title")}
              required={true}
              type="text"
              value={name}
            />
          </div>
          <div className="business-info-item">
            <label>{t("businessInfo.vatNumber")}</label>
            <input
              className="input"
              onChange={(e) => {
                setVat(e.target.value);
              }}
              placeholder={t("businessInfo.vatNumber")}
              type="text"
              value={vat}
            />
          </div>
          <div className="business-info-item">
            <label>{t("businessInfo.invoiceAddress")}</label>
            <input
              className="input"
              onChange={(e) => {
                setInvoiceAddress(e.target.value);
              }}
              placeholder={t("businessInfo.invoiceAddress")}
              type="text"
              value={invoiceAddress}
            />
          </div>
          <div className="business-info-item">
            <label>{t("businessInfo.businessCode")}</label>
            <input
              className="input"
              disabled={true}
              placeholder={t("businessInfo.businessCode")}
              type="text"
              value={foundBusiness?.business.code}
            />
          </div>
          <div className="business-info-item">
            <label>{t("businessInfo.license")}</label>
            <input
              className="input"
              disabled={true}
              placeholder={t("businessInfo.license")}
              type="text"
              value={
                foundBusiness?.business.type === 0
                  ? "Standaard"
                  : foundBusiness?.business.type === 1
                    ? "Premium"
                    : "Genius"
              }
            />
          </div>
          <div className="business-info-item">
            <label>{t("businessInfo.numberOfEmployees")} </label>
            <input
              className="input"
              onChange={(e) => {
                setEmployees(e.target.value);
              }}
              placeholder={t("businessInfo.numberOfEmployees")}
              type="text"
              value={employees}
            />
          </div>
          <div className="business-info-item">
            <label>
              {t("businessInfo.email")} <span className="red">*</span>
            </label>
            <input
              className="input pointer"
              onChange={(e) => {
                setEmployees(e.target.value);
              }}
              onClick={(e) => {
                e.preventDefault();
                setOpenAdminsPopup(true);
              }}
              placeholder={t("businessInfo.email")}
              type="text"
              value={
                admins.filter((admin) => admin.email !== "admin@mindlab.be")[0]
                  ?.email || ""
              }
            />
            <div
              className="add"
              onClick={(e) => {
                e.preventDefault();
                setOpenAdminsPopup(true);
              }}
            >
              +Add
            </div>
          </div>
          <div className="business-info-item">
            <label>
              {t("businessInfo.contactPerson")} <span className="red">*</span>
            </label>
            <input
              className="input"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={t("businessInfo.contactPerson")}
              required={true}
              type="text"
              value={email}
            />
          </div>
          <div className="flex ml-auto mt-auto mb-auto items-center">
            {saved ? (
              <div className="mr-5">{t("businessInfo.saved")}</div>
            ) : null}
            <button className="button w-fit" type="submit">
              {t("businessInfo.save")}
            </button>
          </div>
        </form>
      </div>
      <div className="business-information-container">
        <div className="business-title">
          {t("businessInfo.personalisedHome")}
        </div>
        <form
          className="business-info-items"
          onSubmit={async (e) => {
            e.preventDefault();

            await updateBusinessPageUser(
              introductionNL,
              introductionEN,
              introductionFR,
              selectedBusiness,
              authorName,
              authorTitleNl,
              authorTitleEn,
              authorTitleFr,
            );
            setSaved2(true);

            setTimeout(() => {
              setSaved2(false);
            }, 3000);
          }}
        >
          <div className="business-info-item">
            <label>
              {t("businessInfo.hrName")} <span className="red">*</span>
            </label>
            <input
              className="input"
              onChange={(e) => {
                setAuthorName(e.target.value);
              }}
              placeholder={t("businessInfo.hrName")}
              required={true}
              type="text"
              value={authorName}
            />
          </div>
          <div className="business-info-item">
            <label>
              {t("businessInfo.hrFunctionNl")} <span className="red">*</span>
            </label>
            <input
              className="input"
              onChange={(e) => {
                setAuthorTitleNl(e.target.value);
              }}
              placeholder={t("businessInfo.hrFunctionNl")}
              required={true}
              type="text"
              value={authorTitleNl}
            />
          </div>
          <div className="business-info-item">
            <label>
              {t("businessInfo.hrFunctionEn")} <span className="red">*</span>
            </label>
            <input
              className="input"
              onChange={(e) => {
                setAuthorTitleEn(e.target.value);
              }}
              placeholder={t("businessInfo.hrFunctionEn")}
              required={true}
              type="text"
              value={authorTitleEn}
            />
          </div>
          <div className="business-info-item">
            <label>
              {t("businessInfo.hrFunctionFr")} <span className="red">*</span>
            </label>
            <input
              className="input"
              onChange={(e) => {
                setAuthorTitleFr(e.target.value);
              }}
              placeholder={t("businessInfo.hrFunctionFr")}
              required={true}
              type="text"
              value={authorTitleFr}
            />
          </div>
          <div className="business-info-item">
            <label>
              {t("businessInfo.welcomeTextNL")} <span className="red">*</span>
            </label>
            <div style={{ backgroundColor: "white" }}>
              <ReactQuill
                formats={QuillFormats}
                modules={QuillModules}
                onChange={(value) => {
                  setIntroductionNL(value);
                }}
                theme="snow"
                value={introductionNL}
              />
            </div>
          </div>
          <div className="business-info-item">
            <label>
              {t("businessInfo.welcomeTextEN")} <span className="red">*</span>
            </label>
            <div style={{ backgroundColor: "white" }}>
              <ReactQuill
                formats={QuillFormats}
                modules={QuillModules}
                onChange={(value) => {
                  setIntroductionEN(value);
                }}
                theme="snow"
                value={introductionEN}
              />
            </div>
          </div>
          <div className="business-info-item">
            <label>
              {t("businessInfo.welcomeTextFR")} <span className="red">*</span>
            </label>
            <div style={{ backgroundColor: "white" }}>
              <ReactQuill
                formats={QuillFormats}
                modules={QuillModules}
                onChange={(value) => {
                  setIntroductionFR(value);
                }}
                theme="snow"
                value={introductionFR}
              />
            </div>
          </div>
          <div className="flex ml-auto mt-auto items-center">
            {saved2 ? (
              <div className="mr-5">{t("businessInfo.saved")}</div>
            ) : null}
            <button className="button w-fit" type="submit">
              {t("businessInfo.save")}
            </button>
          </div>
        </form>
      </div>
      <Dialog
        isOpen={openAdminsPopup}
        onRequestClose={() => {
          setOpenAdminsPopup(false);
        }}
        padding={true}
        size={"small"}
        title={t("businessInfo.adminAccess")}
      >
        <HrAdminsForm
          admins={admins}
          close={() => {
            setOpenAdminsPopup(false);
          }}
          selectedBusiness={selectedBusiness}
          setAdmins={setAdmins}
        />
      </Dialog>
    </BusinessSidebar>
  );
}
