import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { StrictMode, Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import App from "./App";
import client from "./apolloClient";
import i18n from "./i18n";
import "./index.css";
import "react-tooltip/dist/react-tooltip.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

Sentry.init({
  dsn: "https://6054c0a556364373b5687af797edeea6@o4504673774141440.ingest.sentry.io/4505515548147712",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType,
    }),
    Sentry.replayIntegration(),
  ],

  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,

  // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://mindlab.be"],
  // Performance Monitoring
  tracesSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div></div>}>
          <App />
        </Suspense>
      </I18nextProvider>
    </ApolloProvider>
  </StrictMode>,
);
