import { Sidebar } from "../../Sidebar";
import { ModuleForm } from "./ModuleForm";

export function CreateModule() {
  return (
    <Sidebar>
      <ModuleForm module={null} />
    </Sidebar>
  );
}
