import { useState } from "react";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { Challenge, getChallenges } from "../api/GameApi";
import { ChallengeForm } from "./ChallengeForm";

export function EditChallenge() {
  const { challengeId } = useParams<{ challengeId: string }>();
  const [challenge, setChallenge] = useState<Challenge | null>(null);

  useEffectOnce(() => {
    getChallenges().then((r) => {
      setChallenge(r.find((c) => c.id === challengeId) || null);
    });
  });

  if (!challenge) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <ChallengeForm challenge={challenge} />
    </Sidebar>
  );
}
