import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Sidebar } from "../Sidebar";
import { getTestimonial, Testimonial } from "../api/TestimonialsApi";
import { TestimonialForm } from "./TestimonialForm";

export function UpdateTestimonial() {
  const [testimonial, setTestimonial] = useState<Testimonial>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    getTestimonial(id).then((result) => {
      setTestimonial(result);
    });
  }, [id]);

  return (
    <Sidebar>
      <TestimonialForm testimonial={testimonial} />
    </Sidebar>
  );
}
