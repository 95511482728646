import { useTranslation } from "react-i18next";

export function PerfectionistTest() {
  const { i18n, t } = useTranslation("general");

  const nl = [
    "   1. Je hebt het goed gedaan met een project, maar het is nog niet perfect. jij:",
    " Werkt verder om iets goed op te leveren, hoewel niet perfect maar wel op tijd.",
    "   Werkt door om de klus af te werken naar je hoge normen, maar dient het te laat in.",
    " Levert goed, maar geen geweldig werk in en maakt het zo snel mogelijk af.",
    " 2. Stel je jezelf wel eens uit? Zo ja, waarom?",
    "   Ik stel uit omdat ik wil dat de dingen zo perfect zijn dat ik mezelf niet kan laten beginnen.",
    "   Ik stel uit omdat ik zoveel dingen vind die leuker zijn om te doen.",
    "   Nee, ik heb niet echt een probleem met uitstelgedrag; Ik sta te popelen om te beginnen.",
    " 3. Je hebt iets nieuws geprobeerd, maar ontdekt dat je er niet erg goed in bent. jij:",
    " Haakt af want je houdt er niet van om dingen te doen waar je slecht in bent, en je bent al goed in veel dingen.",
    " zou het waarschijnlijk volhouden. Je wordt er misschien nooit geweldig in, maar mogelijks wel goed.",
    " geeft op, omdat het veel moeite lijkt. Je houdt eerder van makkelijke dingen.",
    " 4. Als je naar je werk kijkt, wat valt je dan het meeste op?",
    "   Ik besteed niet veel aandacht aan mijn werk eenmaal het afgewerkt is.",
    "   Ik kijk naar het grote geheel en concentreer me dan op wat echt geweldig is aan wat ik heb gedaan.",
    "   Ik zie vooral de fouten die ik heb gemaakt en de kleine onvolkomenheden.",
    " 5. Als je naar het werk van anderen kijkt, wat valt je dan het meeste op?",
    "   Ik merk de fouten die ze hebben gemaakt, en de kleine onvolkomenheden.",
    "   Ik kijk naar het grote geheel en heb de neiging om me te concentreren op de hoogtepunten.",
    "   Ik besteed niet veel aandacht aan het werk van iemand anders.",
    " 6. Als je naar je uiterlijk kijkt, wat valt je dan het meeste op?",
    "   Ik kijk vooral naar wat ik zou willen veranderen.",
    "   Ik let niet echt op mijn uiterlijk.",
    "   Ik kijk naar wat ik leuk vind aan mezelf.",
    " 7. Zeggen mensen dat je moeilijk te behagen bent?",
    "   Nee, ik verwacht niet veel.",
    "   Soms, maar ik wil het beste in mensen naar boven halen en zorgen dat ze zich goed voelen.",
    "   Ja, mensen hebben de neiging om te denken dat mijn verwachtingen van hen te hoog of te rigide zijn.",
    " 8. Wanneer je naar een doel streeft, waar zal je je op concentreren of van genieten?",
    "   Eerlijk gezegd zet ik mijn doelen niet te hoog; Ik wil vooral snel klaar zijn.",
    "   Ik geniet zowel van het proces als van de resultaten; Ik geniet van de reis.",
    "   Ik ben gefocust op de resultaten; Ik heb mijn oog laten vallen op de prijs.",
    " 9. Hoe denk je over falen?",
    "   Ik haat falen, dus ik zal het ten aller tijde proberen vermijen.",
    "   Ik haat falen, maar ik kan waarde ervan inzien.",
    "   Ik denk er niet veel over na.",
    " 10. Hoe voelt opbouwende kritiek voor jou?",
    "   Ik waardeer feedback, zelfs als het niet positief is; het helpt me te verbeteren.",
    "   Normaal gesproken voelt kritiek als een aanval voor mij.",
    "   Ik besteed er niet te veel aandacht aan.",
  ];

  const en = [
    "  1. You have done well with a project, but it is not yet perfect. You:",
    "Work hard to deliver something well, although not perfectly but on time.",
    "  Work hard to finish the job to your high standards, but submit it too late.",
    "  Deliver good, but not great work, and finish it as quickly as possible.",
    "2. Do you ever procrastinate? If so, why?",
    "  I procrastinate because I want things to be so perfect that I can't make myself start.",
    "I procrastinate because I find so many things that are more fun to do.",
    "  No, I do not really have a problem with procrastination; I am eager to get started.",
    "3. You have tried something new, but discover that you are not very good at it. You:",
    "Quit because you don't like doing things you are bad at, and you are already good at many things.",
    "... would probably stick with it. You may never become great at it, but you may be good at it.",
    "... give up, because it seems like a lot of effort. You prefer things to be easy.",
    "4. When you look at your work, what do you notice most?",
    "  I don't pay much attention to my work once it is finished.",
    "I look at the big picture and then concentrate on what is really great about what I have done.",
    "  I see above all the mistakes I have made and the small imperfections.",
    "5. When you look at the work of others, what strikes you most?",
    "  I notice the mistakes they have made, and the small imperfections.",
    "  I look at the big picture and tend to focus on the highlights.",
    "  I don't pay much attention to anyone else's work.",
    "6. When you look at your appearance, what do you notice most?",
    "  I look mainly at what I would like to change.",
    "  I don't really pay attention to my appearance.",
    "I look at what I like about myself.",
    "7. Do people say you are hard to please?",
    "  No, I am not expecting much.",
    "  Sometimes, but I want to bring out the best in people and make them feel good.",
    "  Yes, people tend to think that my expectations of them are too high or too rigid.",
    "8. When you are striving for a goal, what will you concentrate on or enjoy?",
    "  To be honest, I don't set my goals too high; I want to finish quickly.",
    "I enjoy both the process and the results; I enjoy the journey.",
    "  I am focused on the results; I have my eye on the prize.",
    "9. How do you think about failure?",
    "  I hate failure, so I will try to avoid it at all costs.",
    "  I hate failure, but I can see value in it.",
    "  I don't think about it much.",
    "10. How does constructive criticism feel to you?",
    "  I appreciate feedback, even if it is not positive; it helps me to improve.",
    "  Normally criticism feels like an attack on me.",
    "  I don't pay too much attention to it.",
  ];

  const fr = [
    "  1. Vous avez bien travaillé dans le cadre d’un projet mais ce n’est pas encore parfait. Vous :",
    "Persévérez pour livrer quelque chose de bien, et même si ce n’est pas parfait, vous respectez l’échéance.",
    "  Persévérez pour terminer le travail conformément aux normes élevées que vous avez fixées, mais vous dépassez le délai.",
    "  Faites un travail correct mais pas formidable et le terminez au plus vite.",
    "2. Vous arrive-t-il de procrastiner ? Si oui, pourquoi ?",
    "  Je procrastine parce que je veux que les choses soient tellement parfaites que je ne parviens pas à m’y mettre.",
    "  Je procrastine parce que je trouve tellement de choses plus agréables à faire.",
    "  Non, je n’ai pas vraiment de problème de procrastination ; je suis impatient de commencer.",
    "3. Vous avez tenté quelque chose de nouveau, mais vous découvrez que vous n’êtes pas très doué. Vous :",
    "Abandonnez parce que vous n’aimez pas faire des choses pour lesquelles vous n’êtes pas doué, et vous êtes déjà bon dans beaucoup de choses.",
    "  Allez probablement persévérer. Vous ne serez peut-être jamais excellent dans ce domaine, mais vous pourriez être potentiellement bon.",
    "  Abandonnez parce que cela semble demander beaucoup d’efforts. Vous préférez les choses faciles.",
    "4. Lorsque vous examinez votre travail, qu’est-ce qui vous frappe le plus ?",
    "  Je ne prête pas beaucoup d’attention à mon travail une fois qu’il est terminé.",
    "  J’examine la situation dans son ensemble, puis je me concentre sur ce que j’ai vraiment très bien réalisé.",
    "  Je remarque surtout les erreurs que j’ai commises et les petites imperfections.",
    "5. Lorsque vous examinez le travail des autres, qu’est-ce qui vous frappe le plus ?",
    "  Je remarque surtout les erreurs qu’ils ont commises et les petites imperfections.",
    "  J’examine la situation dans son ensemble et j’ai tendance à me concentrer sur les points forts.",
    "  Je ne prête pas beaucoup d’attention au travail des autres.",
    "6. Lorsque vous examinez votre apparence, qu’est-ce qui vous frappe le plus ?",
    "  Je remarque surtout ce que je voudrais changer.",
    "  Je ne fais pas vraiment attention à mon apparence.",
    "  Je regarde ce que j’aime chez moi.",
    "7. Les gens disent-ils que vous êtes difficile à satisfaire ?",
    "  Non, je ne suis pas très exigeant.",
    "  Parfois, mais je veux faire ressortir le meilleur des gens et faire en sorte qu’ils se sentent bien.",
    "  Oui, les autres ont tendance à penser que mes attentes à leur égard sont trop élevées ou trop rigides.",
    "8. Lorsque vous visez un objectif, sur quoi allez-vous vous concentrer ou de quoi allez-vous profiter ?",
    "  Honnêtement, je ne me fixe pas d’objectifs trop élevés ; je veux surtout finir rapidement.",
    "  J’apprécie à la fois le processus et les résultats ; je profite du parcours.",
    "  Je me concentre sur les résultats ; je vise la récompense.",
    "9. Que pensez-vous de l’échec ?",
    "  Je déteste l’échec, j’essaye donc de l’éviter à tout moment.",
    "  Je déteste l’échec, mais je peux y trouver de l’intérêt.",
    "  Je n’y pense pas beaucoup.",
    "10. Que pensez-vous de la critique constructive ?",
    "  J’apprécie le feed-back, même s’il n’est pas positif ; il me permet de m’améliorer.",
    "  Généralement, je prends la critique comme une attaque personnelle.",
    "  Je n’y prête pas trop attention.",
  ];

  function calculateScore() {
    try {
      let a = 0;
      let b = 0;
      let p = 0;

      for (let i = 1; i <= 10; i++) {
        const value =
          // @ts-expect-error no idea why, check OG ML repo?
          document.querySelector(`input[name="v${i}"]:checked`).value;

        if (value === "a") {
          a++;
        }

        if (value === "b") {
          b++;
        }

        if (value === "p") {
          p++;
        }
      }

      document.getElementById("resultaat").style.display = "block";
      document.getElementById("vragen").style.display = "none";

      if (a > b && a > p) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Je bent iemand die de lat hoog maar haalbaar legt. Je probeert vooral het beste uit jezelf te halen en weet jezelf te motiveren. Je bent een goede presteerder zonder jezelf onder druk te zetten."
            : i18n.language === "en"
              ? "You are someone who sets the bar high but achievable. You try to get the best out of yourself and you know how to motivate yourself. You are a good achiever without putting pressure on yourself."
              : "Vous êtes quelqu'un qui met la barre haute mais réalisable. Vous essayez de tirer le meilleur de vous-même et vous savez vous motiver. Vous êtes un bon réalisateur sans vous mettre la pression.";
      } else if (b > a && b > p) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Je hebt geen last van perfectionisme, integendeel. Je legt de lat dikwijls lager dan haalbaar is voor jou. Dit ondermijnt wel eens je motivatie om er echt voor te gaan."
            : i18n.language === "en"
              ? "You do not suffer from perfectionism, on the contrary. You often set the bar lower than is feasible for you. This sometimes undermines your motivation to really go for it."
              : "Je hebt geen last van perfectionisme, integendeel. Je legt de lat dikwijls lager dan haalbaar is voor jou. Dit ondermijnt wel eens je motivatie om er echt voor te gaan.";
      } else if (p > 5) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Je bent een echte perfectionist. Dit kan ervoor zorgen dat je last hebt van uitstelgedrag en/of faalangst. \tJe legt de lat niet alleen voor jezelf maar ook voor anderen zeer hoog. 'Goed is goed genoeg' is voor jou moeilijk te aanvaarden.\tMisschien is het wel eens tijd om de lat toch wat lager te leggen."
            : i18n.language === "en"
              ? "You are a true perfectionist. This can cause you to suffer from procrastination and/or fear of failure.\tYou set the bar very high not only for yourself but also for others. 'Good is good enough' is difficult for you to accept.\tMaybe it's time to lower the bar a bit."
              : "Vous êtes un vrai perfectionniste. Cela peut vous amener à souffrir de procrastination et/ou de peur de l'échec.\t« Bien est assez bien » est difficile à accepter pour vous.\tIl est peut-être temps de baisser un peu la barre.";
      } else if (p < 6 && p > a && p > b) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Er is geen duidelijk resultaat uit de test. Je scoort evenredig op alle deelaspecten."
            : i18n.language === "en"
              ? "There is no clear result from the test. You score equally on all sub-aspects."
              : "Vous êtes un vrai perfectionniste. Cela peut vous amener à souffrir de procrastination et/ou de peur de l'échec.\t« Bien est assez bien » est difficile à accepter pour vous.\tIl est peut-être temps de baisser un peu la barre.";
      } else if (a === b && b === p) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Er is geen duidelijk resultaat uit de test. Je scoort evenredig op alle deelaspecten."
            : i18n.language === "en"
              ? "There is no clear result from the test. You score equally on all sub-aspects."
              : "Il n'y a pas de résultat clair du test. Vous obtenez un score égal sur tous les sous-aspects.";
      } else if (p < 6 && a === p) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Je bent een echte perfectionist. Dit kan ervoor zorgen dat je last hebt van uitstelgedrag en/of faalangst. \tJe legt de lat niet alleen voor jezelf maar ook voor anderen zeer hoog. 'Goed is goed genoeg' is voor jou moeilijk te aanvaarden.\tMisschien is het wel eens tijd om de lat toch wat lager te leggen."
            : i18n.language === "en"
              ? "You are a true perfectionist. This can cause you to suffer from procrastination and/or fear of failure.\tYou set the bar very high not only for yourself but also for others. 'Good is good enough' is difficult for you to accept.\tMaybe it's time to lower the bar a bit."
              : "Vous êtes un vrai perfectionniste. Cela peut vous amener à souffrir de procrastination et/ou de peur de l'échec.\t« Bien est assez bien » est difficile à accepter pour vous.\tIl est peut-être temps de baisser un peu la barre.";
      } else if (p < 6 && p === b) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Er is geen duidelijk resultaat uit de test. Je scoort evenredig op alle deelaspecten."
            : i18n.language === "en"
              ? "There is no clear result from the test. You score equally on all sub-aspects"
              : "Il n'y a pas de résultat clair du test. Vous obtenez un score égal sur tous les sous-aspects.";
      }
    } catch {
      document.getElementById("error").style.display = "block";
    }
  }

  const values = ["", "a", "p", "b"];

  return (
    <div
      style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <div id="vragen" style={{ overflow: "auto", width: "100%" }}>
        {nl.map((_, i) => {
          const item =
            i18n.language === "nl"
              ? nl[i]
              : i18n.language === "en"
                ? en[i]
                : fr[i];

          if (i % 4 === 0) {
            return <b>{item}</b>;
          } else {
            return (
              <div>
                <input
                  name={`v${Math.floor(i / 4) + 1}`}
                  type="radio"
                  value={values[i % 4]}
                />
                {item}
              </div>
            );
          }
        })}
        <span id="error" style={{ color: "red", display: "none" }}>
          Gelieve alle vragen te beantwoorden
        </span>
        <div
          className="button w-fit mt-4"
          onClick={() => {
            calculateScore();
          }}
        >
          {t("calculate_score")}
        </div>
      </div>
      <div id="resultaat" style={{ width: "80%" }}>
        <div id="result"></div>
      </div>
    </div>
  );
}
