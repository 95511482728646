import { Sidebar } from "../Sidebar";
import { LogoForm } from "./LogoForm";

export function CreateLogo() {
  return (
    <Sidebar>
      <LogoForm />
    </Sidebar>
  );
}
