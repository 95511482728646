import { useTranslation } from "react-i18next";

export function ZelfbeeldTest() {
  const { i18n, t } = useTranslation("general");

  const nl = [
    "Ik blijf lang stilstaan bij negatieve ervaringen uit het verleden",
    "Ik geloof dat ik gelijkwaardig ben, niet beter en niet slechter dan anderen",
    "Veel van mijn tijd gaat naar het nastreven van perfectie",
    "Ik vind de behoeften van anderen belangrijker dan deze van mij",
    "Ik kan gemakkelijk aangeven wat ik nodig heb",
    "Ik wil altijd gelijk hebben",
    "Ik ervaar vaak angst, twijfel en zorgen",
    "Ik heb vertrouwen in mijn eigen kunnen",
    "Ik geloof dat ik niet kan falen",
    "Ik leg de nadruk vaak op mijn zwakke punten",
    "Ik heb een positieve kijk op het leven",
    "Ik geloof dat ik slimmer of beter ben dan anderen",
    "Ik heb moeite om neen te zeggen tegen anderen",
    "Ik kan gemakkelijk neen zeggen als ik dat wil",
    "Er wordt wel eens gezegd dat ik grandioze ideeën weet uit te drukken",
    "Ik heb last van intense faalangst",
    "Ik heb een goed overzicht van mijn sterktes en zwaktes en deze accepteer ik",
    "Er wordt wel eens gezegd dat ik mijzelf overschat",
  ];

  const en = [
    "I dwell for a long time on negative experiences from the past",
    "I believe I am equal, no better and no worse than others",
    "Much of my time goes into the pursuit of perfection",
    "I find the needs of others more important than my own",
    "I can express my needs well",
    "I am quick to focus on being right",
    "I often experience anxiety, self-doubt and worry",
    "I have confidence in my own abilities",
    "I believe I cannot fail",
    "I often focus on my weaknesses",
    "I have a positive outlook on life",
    "I believe I am smarter or better than others",
    "I have difficulty saying no to others",
    "I can easily say no if I want to",
    "It has been said that I know how to express grandiose ideas",
    "I suffer from intense fear of failure",
    "I have a clear view of my strengths and weaknesses and accept them",
    "It is sometimes said that I overestimate my skills",
  ];

  const fr = [
    "Je ressasse longtemps les expériences négatives du passé",
    "J’estime que je suis égal aux autres, ni meilleur ni pire",
    "Je consacre beaucoup de temps à atteindre la perfection",
    "Je trouve les besoins des autres plus importants que les miens",
    "Je suis capable d’exprimer mes besoins",
    "J’ai vite tendance à vouloir avoir raison",
    "Je ressens souvent de l’anxiété, des doutes et de l’inquiétude",
    "J’ai confiance en mes propres capacités",
    "J’estime que je ne peux pas échouer",
    "Je me concentre souvent sur mes faiblesses",
    "J’ai une vision positive de la vie",
    "J’estime que je suis plus intelligent ou meilleur que les autres",
    "J’ai du mal à dire non aux autres",
    "Je peux facilement dire non si je le veux",
    "On dit parfois que je sais exprimer de grandes idées",
    "Je souffre d’une peur intense de l’échec",
    "J’ai une idée précise de mes forces et de mes faiblesses et je les accepte",
    "On dit parfois que je surestime mes compétences",
  ];

  const options_nl = [
    {
      name: "Ja",
      value: 1,
    },
    {
      name: "Nee",
      value: 0,
    },
  ];

  const options_en = [
    {
      name: "Yes",
      value: 1,
    },
    {
      name: "No",
      value: 0,
    },
  ];

  const options_fr = [
    {
      name: "Oui",
      value: 1,
    },
    {
      name: "Non",
      value: 0,
    },
  ];

  function calculateScore() {
    try {
      let a = 0;

      // @ts-expect-error no idea why, check OG ML repo?
      a += parseInt(document.querySelector('input[name="v1"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      a += parseInt(document.querySelector('input[name="v4"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      a += parseInt(document.querySelector('input[name="v7"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      a += parseInt(document.querySelector('input[name="v10"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      a += parseInt(document.querySelector('input[name="v13"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      a += parseInt(document.querySelector('input[name="v16"]:checked').value);

      let b = 0;

      // @ts-expect-error no idea why, check OG ML repo?
      b += parseInt(document.querySelector('input[name="v2"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      b += parseInt(document.querySelector('input[name="v5"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      b += parseInt(document.querySelector('input[name="v8"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      b += parseInt(document.querySelector('input[name="v11"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      b += parseInt(document.querySelector('input[name="v14"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      b += parseInt(document.querySelector('input[name="v17"]:checked').value);

      let c = 0;

      // @ts-expect-error no idea why, check OG ML repo?
      c += parseInt(document.querySelector('input[name="v3"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      c += parseInt(document.querySelector('input[name="v6"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      c += parseInt(document.querySelector('input[name="v9"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      c += parseInt(document.querySelector('input[name="v12"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      c += parseInt(document.querySelector('input[name="v15"]:checked').value);
      // @ts-expect-error no idea why, check OG ML repo?
      c += parseInt(document.querySelector('input[name="v18"]:checked').value);

      document.getElementById("resultaat").style.display = "block";
      document.getElementById("vragen").style.display = "none";

      if (a > b && a > c) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Je hebt eerder een laag zelfbeeld. Zorg ervoor dat je jezelf niet onderwaardeert en ga verder aan de slag met deze module."
            : i18n.language === "en"
              ? "You tend to have a low self-esteem. Make sure you don't undervalue yourself. Continue this program and give your self-esteem a boost."
              : "Vous avez tendance à avoir une faible estime de soi. Assurez-vous de ne pas vous sous-estimer et continuez avec ce programme.";
      } else if (b > a && b > c) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Je hebt een gezond zelfbeeld, goed zo!"
            : i18n.language === "en"
              ? "You seem to have a healthy self-esteem. Good job!"
              : "Vous avez une bonne estime de soi, bon travail !";
      } else if (c > a && c > b) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Jouw zelfbeeld is eerder hoog. Zorg ervoor dat je jezelf niet overschat, want dit zou jou ook problemen kunnen opleveren."
            : i18n.language === "en"
              ? "Your self-image is rather high. Make sure you don't overestimate yourself, because this could get you in trouble too."
              : "Votre estime de soi est plutôt élevée. Veillez à ne pas vous surestimer, car cela pourrait également vous attirer des ennuis.";
      } else if (a === b && b === c) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Er is geen duidelijk resultaat uit de test te detecteren, je scoort evenredig op alle vragen"
            : i18n.language === "en"
              ? "The test indicates no clear result. You score equally on all questions"
              : "Le test ne donne pas de résultat clair. Vous obtenez un score égal à toutes les questions";
      } else if (a > c && a === b) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Je hebt eerder een laag zelfbeeld. Zorg ervoor dat je jezelf niet onderwaardeert en ga verder aan de slag met deze module."
            : i18n.language === "en"
              ? "You tend to have a low self-esteem. Make sure you don't undervalue yourself. Continue this program and give your self-esteem a boost."
              : "Vous avez tendance à avoir une faible estime de soi. Assurez-vous de ne pas vous sous-estimer et continuez avec ce programme.";
      } else if (c > a && c === b) {
        document.getElementById("result").innerHTML =
          i18n.language === "nl"
            ? "Jouw zelfbeeld is eerder hoog. Zorg ervoor dat je jezelf niet overschat, want dit zou jou ook problemen kunnen opleveren."
            : i18n.language === "en"
              ? "Your self-image is rather high. Make sure you don't overestimate yourself, because this could get you in trouble too."
              : "Votre estime de soi est plutôt élevée. Veillez à ne pas vous surestimer, car cela pourrait également vous attirer des ennuis.";
      }
    } catch {
      document.getElementById("error").style.display = "block";
    }
  }

  return (
    <div
      style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <div id="vragen" style={{ overflow: "auto", width: "100%" }}>
        <table className="table">
          <thead>
            <tr>
              <td></td>
              {options_nl.map((_, i) => {
                return (
                  <td>
                    {i18n.language === "nl" && options_nl[i].name}
                    {i18n.language === "en" && options_en[i].name}
                    {i18n.language === "fr" && options_fr[i].name}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {nl.map((_, i) => {
              return (
                <tr>
                  <td>
                    {i18n.language === "nl" && nl[i]}
                    {i18n.language === "en" && en[i]}
                    {i18n.language === "fr" && fr[i]}
                  </td>
                  {options_nl.map((item) => {
                    return (
                      <td>
                        <input
                          name={`v${i + 1}`}
                          type="radio"
                          value={item.value}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <span id="error" style={{ color: "red", display: "none" }}>
          Gelieve alle vragen te beantwoorden
        </span>
        <div
          className="button w-fit mt-4"
          onClick={() => {
            calculateScore();
          }}
        >
          {t("calculate_score")}
        </div>
      </div>
      <div id="resultaat" style={{ width: "80%" }}>
        <div id="result"></div>
      </div>
    </div>
  );
}
