import { useTranslation } from "react-i18next";
import { Footer } from "./Footer";

export function GeneralTerms() {
  const { i18n } = useTranslation();

  let content;

  if (i18n.language === "fr") {
    content = (
      <div className="container">
        <h1>Conditions Générales</h1>
        <p>
          <strong>Données d’entreprise</strong>
        </p>
        <p>
          <br />
          Mindlab by BloomUp
        </p>
        <p>Sare De Caster</p>
        <p>
          info@mindlab.be
          <br />
          0472/58.56.25
        </p>
        <p>
          <br />
          <strong>Article 1 : Dispositions générales</strong>
        </p>
        <p>
          <br />
          Le site d’e-commerce de Mindlab by BloomUp offre à ses clients la
          possibilité d’acheter les produits de sa boutique en ligne
        </p>
        <p>
          <br />
          Les présentes Conditions Générales (les « Conditions ») s’appliquent à
          toute commande passée par un visiteur de ce site d’e-commerce (le «
          Client »). Lorsqu’il passe une commande par le biais de la boutique en
          ligne de Mindlab by BloomUp, le Client doit accepter expressément ces
          Conditions, acceptant par conséquent l’applicabilité de ces
          Conditions, à l’exclusion de toutes les autres conditions. Les
          conditions supplémentaires du client sont exclues, sauf si celles-ci
          ont été acceptées préalablement, expressément et par écrit, par
          Mindlab by BloomUp.
        </p>
        <p>
          <br />
          <strong>Article 2 : Prix</strong>
        </p>
        <p>
          <br />
          Tous les prix mentionnés sont exprimés en euros, incluant toujours la
          TVA et tous les autres impôts ou taxes que le client doit supporter.
        </p>
        <p>
          <br />
          Le prix indiqué se réfère exclusivement aux articles tels que décrits
          textuellement. Les photos correspondantes sont uniquement destinées à
          des fins décoratives et peuvent contenir des éléments qui ne sont pas
          inclus dans le prix.
        </p>
        <p>
          <strong>Article 3 : Offre</strong>
        </p>
        <p>
          <br />
          Bien que le catalogue en ligne et le site d’e-commerce soient élaborés
          avec le plus grand soin, il se peut malgré tout que les informations
          fournies soient incomplètes, contiennent des erreurs matérielles ou ne
          soient pas à jour. Les fautes ou erreurs manifestes dans l’offre
          n’engagent pas Mindlab by BloomUp. Mindlab by BloomUp n’est tenu qu’à
          une obligation de moyens quant à l’exactitude et l’exhaustivité des
          informations fournies. Mindlab by BloomUp n’est en aucun cas
          responsable des erreurs matérielles manifestes, des fautes
          d’impression ou des fautes de frappe.
        </p>
        <p>
          <br />
          Si le Client a des questions spécifiques concernant, par exemple, la
          disponibilité, le délai de livraison ou le mode de livraison, nous lui
          demandons de contacter au préalable notre service clientèle via les
          options de contact mentionnées sur notre site web.
        </p>
        <p>
          <br />
          L’offre est valable dans la limite des stocks disponibles et peut être
          modifiée ou retirée par Mindlab by BloomUp à tout moment. Mindlab by
          BloomUp ne peut être tenu responsable de l’indisponibilité d’un
          produit.
        </p>
        <p>
          <br />
          <strong>Article 4 : Achats en ligne</strong>
          <br />
          Le Client peut choisir parmi les modes de paiement suivants :
        </p>
        <ul>
          <li>par carte de crédit</li>
          <li>par carte bancaire</li>
          <li>par virement bancaire</li>
          <li>via PayPal</li>
          <li>via iDeal</li>
        </ul>
        <p>
          Mindlab est en droit de refuser une commande en raison d’un manquement
          grave de la part du client au regard des commandes impliquant le
          client.
        </p>
        <strong>Article 5 : Livraison et exécution du contrat</strong>
        <br />
        Les articles commandés via cette boutique en ligne sont mis à
        disposition gratuitement en ligne. Tout dommage visible et/ou défaut
        qualitatif d’un article ou autre manquement lors de la livraison, doit
        être signalé par le Client à Mindlab immédiatement et sans délai.
        <br />
        <br />
        <strong>Article 6 : Réserve de propriété</strong>
        <br />
        Les articles fournis restent la propriété exclusive de Mindlab.
        <br />
        Le Client s’engage, le cas échéant, à informer des tiers de la réserve
        de propriété de Mindlab.
        <br />
        <br />
        <br />
        <strong>Article 7 : Droit de rétractation</strong>
        <br />
        Les dispositions du présent article s’appliquent exclusivement aux
        Clients qui, en leur qualité de consommateurs, achètent des articles en
        ligne auprès de Mindlab.
        <br />
        Si le droit de rétractation s’applique :<br />
        Le Client a le droit de révoquer le contrat dans un délai de 14 jours
        calendaires sans en indiquer ses raisons.
        <br />
        Le délai de rétractation expire 14 jours calendaires suivant la date où
        le Client ou un tiers désigné par le Client, qui n’est pas le
        transporteur, prend matériellement possession du dernier bien.
        <br />
        Afin d’exercer son droit de rétractation, le client doit informer
        Mindlab, info@mindlab.be, 0472/58.56.25, par le biais d’une déclaration
        sans équivoque (par ex. par écrit par courrier, par fax ou par e-mail)
        de sa décision de révoquer le contrat. Le Client peut utiliser le modèle
        de formulaire de rétractation ci-joint à cette fin, mais n’est pas tenu
        de le faire.
        <br />
        Si le Client fait usage de cette possibilité, nous lui adresserons
        immédiatement un accusé de réception de sa rétractation sur un support
        durable (par ex. par e-mail).
        <br />
        En vue de respecter le délai de rétractation, le Client doit envoyer sa
        communication concernant l’exercice de son droit de rétractation avant
        l’expiration du délai de rétractation.
        <br />
        Le Client doit renvoyer ou transmettre les biens à Mindlab,
        info@mindlab.be, 0472/58.56.25, sans délai et en tout cas au plus tard
        14 jours calendaires à compter de la date où il a communiqué à Mindlab
        sa décision de révoquer le contrat.
        <br />
        Le Client est dans les temps s’il renvoie les biens avant l’expiration
        du délai de 14 jours calendaires.
        <br />
        Si le produit retourné a perdu de la valeur de quelque manière que ce
        soit, Mindlab se réserve le droit de tenir le Client responsable et de
        réclamer un dédommagement pour toute dépréciation de la valeur des biens
        résultant de l’utilisation des biens par le Client, au-delà de ce qui
        est nécessaire pour établir la nature, les caractéristiques et le
        fonctionnement des biens.
        <br />
        Seuls les articles dans leur emballage d’origine, avec tous les
        accessoires, le mode d’emploi et la facture ou preuve d’achat peuvent
        être repris.
        <br />
        Si le Client a demandé que la prestation des services débute au cours du
        délai de rétractation, le Client devra payer un montant proportionnel à
        ce qui a déjà été livré au moment où il nous a informés qu’il révoque le
        contrat, par rapport à la pleine exécution du contrat.
        <br />
        Si le Client résilie le contrat, Mindlab remboursera tous les paiements
        reçus de la part du client jusqu’à ce moment-là, y compris les frais de
        livraison standard, dans un délai maximum de 14 jours calendaires après
        que Mindlab a été informé de la décision du client de révoquer le
        contrat. Dans le cas des contrats de vente, Mindlab peut attendre
        d’avoir reçu tous les biens en retour pour effectuer le remboursement,
        ou que le Client ait prouvé avoir renvoyé les marchandises, en fonction
        de la première éventualité.
        <br />
        Les éventuels frais supplémentaires résultant du choix du client d’un
        mode de livraison différent de la livraison standard la moins chère
        proposée par Mindlab ne seront pas remboursés.
        <br />
        Mindlab remboursera le Client en utilisant le même mode de paiement avec
        lequel le Client a effectué la transaction initiale, sauf si le Client
        en a expressément convenu autrement ; le Client ne sera en aucun cas
        facturé pour ce remboursement.
        <br />
        Si le droit de rétractation ne s’applique pas, indiquez pourquoi sur la
        base des catégories suivantes :<br />
        Le Client ne peut pas exercer son droit de rétractation pour :<br />
        • la livraison ou la prestation de biens ou de services dont le prix est
        soumis aux fluctuations du marché financier sur lesquelles Mindlab n’a
        aucune influence et qui peuvent survenir au cours du délai de
        rétractation.
        <br />
        • la livraison de biens fabriqués selon les spécifications du Client, ou
        qui sont clairement destinés à une personne spécifique ;<br />
        • la livraison de biens scellés qui ne peuvent être retournés pour des
        raisons de protection de la santé ou d’hygiène et dont le sceau a été
        brisé après la livraison ;<br />
        • la livraison de biens qui sont irrévocablement mélangés avec d’autres
        produits après la livraison de par leur nature ;<br />
        • la livraison de boissons alcoolisées dont le prix a été convenu lors
        de la conclusion du contrat, mais dont la livraison ne peut avoir lieu
        qu’après 30 jours et dont la valeur réelle dépend des fluctuations du
        marché sur lesquelles l’entreprise n’a aucune influence ;<br />
        • les accords dans le cadre desquels le client a spécifiquement demandé
        à Mindlab de lui rendre visite afin d’effectuer des réparations ou des
        entretiens urgents ;<br />
        • la fourniture d’enregistrements audio et vidéo scellés et de logiciels
        informatiques scellés dont le sceau a été brisé après la livraison ;
        <br />
        • la livraison de journaux, de périodiques ou de magazines, à
        l’exception des contrats d’abonnement à ces publications ;<br />
        • les contrats conclus lors d’une vente aux enchères publiques ;<br />
        • la remise à disposition d’un hébergement autre qu’à des fins
        résidentielles, le transport de marchandises, les services de location
        de voitures, la restauration et les services liés aux activités de
        loisirs si les contrats prévoient une date ou une période d’exécution
        précise ;<br />
        • la fourniture d’un contenu numérique qui n’est pas livré sur un
        support matériel si l’exécution a débuté avec le consentement exprès
        préalable du Client et à condition que le Client ait reconnu qu’il perd
        ainsi son droit de rétractation (par ex. téléchargement de musique, de
        logiciels) ;<br />
        • les contrats de services pour les paris et les loteries.
        <br />
        <br />
        <strong>Article 8 : Service clientèle</strong>
        <br />
        Le service clientèle de Mindlab est accessible par téléphone au
        0472/58.56.25 ou par e-mail à l’adresse info@mindlab.be. Toute
        réclamation peut lui être adressée.
        <br />
        <br />
        <strong>Article 9 : Sanctions en cas de non-paiement</strong>
        <br />
        Sans préjudice de l’exercice des autres droits dont dispose Mindlab, en
        cas de non-paiement ou de paiement tardif, le Client est redevable de
        plein droit et sans mise en demeure d’un intérêt de 10 % par an sur le
        montant impayé à compter de la date du défaut. Le Client est en outre
        redevable de plein droit et sans mise en demeure d’une indemnité
        forfaitaire de 10 % sur le montant concerné, avec un minimum de 25 euros
        par facture.
        <br />
        Sans préjudice de ce qui précède, Mindlab se réserve le droit de
        récupérer les articles non (entièrement) payés.
        <br />
        <br />
        <strong>Article 10 : Respect de la vie privée</strong>
        <br />
        Le responsable du traitement, Mindlab, respecte la loi européenne sur la
        vie privée (Règlement Général sur la Protection des Données) en ce qui
        concerne la protection de la vie privée dans le cadre du traitement des
        données à caractère personnel.
        <br />
        Les données à caractère personnel que vous communiquez ne sont utilisées
        qu’aux fins suivantes : l’exécution du contrat conclu, le traitement de
        la commande, l’envoi de newsletters, la publicité et/ou le marketing.
        <br />
        Vous disposez d’un droit légal de consultation et de correction
        éventuelle de vos données à caractère personnel. Moyennant une preuve
        d’identité (copie de la carte d’identité), vous pouvez obtenir
        gratuitement une communication écrite reprenant vos données personnelles
        en envoyant une demande écrite datée et signée à Mindlab, 0472/58.56.25,
        info@mindlab.be. Le cas échéant, vous pouvez également demander la
        rectification de données erronées, incomplètes ou non pertinentes.
        <br />
        Nous traitons vos données en tant qu’informations confidentielles et
        nous ne les transmettrons pas, ne les louerons pas et ne les vendrons
        pas à des tiers.
        <br />
        Le Client est responsable de la confidentialité de ses données
        d’identification et de l’utilisation de son mot de passe. Votre mot de
        passe est stocké de manière cryptée, Mindlab n’y a donc pas accès.
        <br />
        Mindlab effectue des statistiques en ligne (anonymes) concernant les
        visiteurs afin de déterminer quelles pages du site Internet sont
        consultées et dans quelle mesure.
        <br />
        Pour toute question au sujet de cette déclaration de confidentialité,
        vous pouvez nous contacter à l’adresse info@mindlab.be.
        <br />
        <br />
        <strong>Article 11 : Utilisation des cookies</strong>
        <br />
        Aucun cookie n’est actuellement utilisé.
        <br />
        <br />
        <strong>Article 12 : Atteinte à la validité – Non-renonciation</strong>
        <br />
        Si une disposition des présentes Conditions est déclarée invalide,
        illégale ou nulle, la validité, la légalité et l’application des autres
        dispositions n’en seront en aucune façon affectées.
        <br />
        Tout manquement de Mindlab, à quelque moment que ce soit, concernant la
        défense de l’un des droits stipulés dans les présentes Conditions ou
        l’exercice d’un droit quelconque à cet égard ne sera jamais considéré
        comme une renonciation à ladite disposition et ne portera en aucun cas
        atteinte à la validité de ces droits.
        <br />
        <br />
        <strong>Article 13 : Modification des conditions</strong>
        <br />
        Les présentes Conditions sont complétées par d’autres conditions
        auxquelles il est explicitement fait référence, ainsi que par les
        conditions générales de vente de Mindlab. En cas de contradiction, les
        présentes Conditions prévalent.
        <br />
        <br />
        <strong>Article 14 : Preuve</strong>
        <br />
        Le Client consent à ce que des communications électroniques et des
        sauvegardes puissent faire office de preuves.
        <br />
        <br />
        <strong>Article 15 : Droit applicable – Tribunal compétent</strong>
        <br />
        Le droit belge est applicable, à l’exception des dispositions du droit
        international privé relatives au droit applicable et à l’exception de la
        Convention de Vienne sur les contrats de vente internationale de biens
        mobiliers. Sauf si le Client est un consommateur, seuls les tribunaux de
        l’arrondissement de TRIBUNAL DE COMMERCE GAND DIVISION TERMONDE sont
        compétents en cas de litige.
        <br />
        Garantie légale donnée par le vendeur final : le bien de consommation
        est couvert par une garantie d’une durée de 2 ans à compter de la date
        de livraison et ne peut être limitée par le vendeur
        <br />
        http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pllanguage=nl&la=N&cn=2013022819&table_name=wet&&caller=list&N&fromtab=wet&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(”))#Art.IX.1)
        <br />
        Garantie commerciale (ou garantie d’usine) = garantie non légale mais
        convenue par les parties, accordée par le vendeur final ou par le
        fabricant. Celle-ci ne peut pas limiter la garantie légale du
        consommateur (art. 1649 septies du Code Civil).
        <br />
      </div>
    );
  } else if (i18n.language === "en") {
    content = (
      <div className="container">
        <h1>General Terms and Conditions</h1>
        <p>
          <strong>Company Information</strong>
        </p>
        <p>
          <br />
          Mindlab
        </p>
        <p>Sare De Caster</p>
        <p>
          info@mindlab.be
          <br />
          0472/58.56.25
        </p>
        <p>
          <br />
          <strong>Article 1: General provisions</strong>
        </p>
        <p>
          <br />
          The e-commerce website of Mindlab offers its customers the possibility
          to purchase the products from its web store online.
        </p>
        <p>
          <br />
          These General Terms and Conditions ("Conditions") apply to every order
          placed by a visitor of this e-commerce website ("Customer"). By
          placing an order through Mindlab's web store, the Customer explicitly
          accepts these Conditions, thereby agreeing to the applicability of
          these Conditions, to the exclusion of all other conditions. Additional
          conditions of the Customer are excluded, unless they have been
          accepted in advance, in writing, and explicitly by Mindlab.
        </p>
        <p>
          <br />
          <strong>Article 2: Price</strong>
        </p>
        <p>
          <br />
          All prices mentioned are expressed in EURO, always including VAT and
          all other taxes or duties to be borne by the Customer.
        </p>
        <p>
          <br />
          The price indication exclusively refers to the articles as they are
          verbally described. The accompanying photos are intended for
          decoration and may contain elements that are not included in the
          price.
        </p>
        <p>
          <strong>Article 3: Offer</strong>
        </p>
        <p>
          <br />
          Despite the fact that the online catalog and e-commerce website are
          compiled with the utmost care, it is possible that the provided
          information is incomplete, contains material errors, or is not up to
          date. Clear mistakes or errors in the offer do not bind Mindlab.
          Mindlab's obligation regarding the accuracy and completeness of the
          provided information is limited to a best-effort obligation. Mindlab
          is in no way liable in the event of manifest material errors,
          typographical or printing errors.
        </p>
        <p>
          <br />
          If the Customer has specific questions regarding availability,
          delivery time, or delivery method, we kindly request the Customer to
          contact our customer service in advance via the contact options on our
          website.
        </p>
        <p>
          <br />
          The offer is always valid while stocks last and can be adjusted or
          withdrawn by Mindlab at any time. Mindlab cannot be held liable for
          the unavailability of a product.
        </p>
        <p>
          <br />
          <strong>Article 4: Online purchases</strong>
          <br />
          The customer has the choice between the following payment methods:
        </p>
        <ul>
          <li>by credit card</li>
          <li>by debit card</li>
          <li>by bank transfer</li>
          <li>via PayPal</li>
          <li>via iDeal</li>
        </ul>
        <p>
          Mindlab is entitled to refuse an order due to a serious breach by the
          Customer regarding orders in which the Customer is involved.
        </p>
        <p>
          <strong>Article 5: Delivery and performance of the agreement</strong>
        </p>
        <p>
          Items ordered through this web store are made available online free of
          charge.
        </p>
        <p>
          Any visible damage and/or qualitative defect of an item or any other
          deficiency in the delivery must be reported immediately by the
          Customer to Mindlab.
        </p>
        <p>
          <strong>Article 6: Retention of title</strong>
        </p>
        <p>The delivered items remain the exclusive property of Mindlab.</p>
        <p>
          The Customer undertakes to inform third parties of Mindlab's retention
          of title, if necessary.
        </p>
        <p>
          <strong>Article 7: Right of withdrawal</strong>
        </p>
        <p>
          The provisions of this article only apply to Customers who, in their
          capacity as consumers, purchase items online from Mindlab.
        </p>
        <p>If the right of withdrawal applies:</p>
        <p>
          The Customer has the right to withdraw from the agreement within a
          period of 14 calendar days without giving any reason.
        </p>
        <p>
          The withdrawal period expires 14 calendar days after the day on which
          the Customer or a third party designated by the Customer, who is not
          the carrier, physically acquires the last good.
        </p>
        <p>
          To exercise the right of withdrawal, the customer must notify Mindlab,
          info@mindlab.be, 0472/58.56.25, via an unambiguous statement (e.g., in
          writing by post, fax, or email) of their decision to withdraw from the
          agreement. The Customer can use the attached model withdrawal form for
          this purpose, but it is not obligatory.
        </p>
        <p>
          If the customer makes use of this possibility, we will send the
          Customer an acknowledgment of receipt of such withdrawal without delay
          on a durable medium (e.g., by email).
        </p>
        <p>
          To comply with the withdrawal period, the Customer must send their
          communication regarding their exercise of the right of withdrawal
          before the withdrawal period has expired.
        </p>
        <p>
          The Customer must return or hand over the goods to Mindlab,
          info@mindlab.be, 0472/58.56.25, without delay and in any case no later
          than 14 calendar days after the day on which they have notified
          Mindlab of their decision to withdraw from the agreement.
        </p>
        <p>
          The Customer is on time if they return the goods before the period of
          14 calendar days has expired.
        </p>
        <p>
          If the returned product has been diminished in value in any way,
          Mindlab reserves the right to hold the Customer liable and demand
          compensation for any depreciation of the goods that results from the
          use of the goods by the Customer beyond what is necessary to determine
          the nature, characteristics, and functioning of the goods.
        </p>
        <p>
          Only items that are in their original packaging, together with all
          accessories, user manuals, and invoice or proof of purchase, can be
          returned.
        </p>
        <p>
          If the Customer has requested the provision of services to commence
          during the withdrawal period, the Customer shall pay an amount
          proportionate to the services already provided at the time of
          notifying us of the withdrawal, compared to the full performance of
          the agreement.
        </p>
        <p>
          If the Customer withdraws from the agreement, Mindlab will reimburse
          all payments received from the Customer up to that point, including
          standard delivery costs, within a maximum of 14 calendar days after
          Mindlab has been informed of the Customer's decision to withdraw. In
          sales agreements, Mindlab may wait to refund until it has received all
          the goods back or until the Customer has provided evidence of
          returning the goods, whichever occurs first.
        </p>
        <p>
          Any additional costs resulting from the Customer's choice of a
          different delivery method than the cheapest standard delivery offered
          by Mindlab will not be refunded.
        </p>
        <p>
          Mindlab will refund the Customer using the same payment method used by
          the Customer for the original transaction, unless the Customer
          expressly agrees otherwise. In any case, the Customer will not be
          charged any fees for such a refund.
        </p>
        <p>
          If the right of withdrawal is not applicable, the reasons should be
          mentioned based on the following categories:
        </p>
        <p>The Customer cannot exercise the right of withdrawal for:</p>
        <ul>
          <li>
            the supply or provision of goods or services whose price is
            dependent on fluctuations in the financial market that are beyond
            Mindlab's control and that may occur within the withdrawal period;
          </li>
          <li>
            the supply of goods made to the Customer's specifications or clearly
            personalized;
          </li>
          <li>
            the supply of sealed goods that are not suitable for return due to
            health protection or hygiene reasons and whose seal has been broken
            after delivery;
          </li>
          <li>
            the supply of goods that are, by their nature, inseparably mixed
            with other items after delivery;
          </li>
          <li>
            the supply of alcoholic beverages, the price of which was agreed
            upon at the time of the conclusion of the sales agreement but which
            can only be delivered after 30 days, and whose actual value depends
            on fluctuations in the market over which the business has no
            control;
          </li>
          <li>
            agreements where the Customer has specifically requested Mindlab to
            visit for urgent repairs or maintenance;
          </li>
          <li>
            the supply of sealed audio or sealed video recordings or sealed
            computer software that has been unsealed after delivery;
          </li>
          <li>
            the supply of newspapers, magazines, or periodicals, with the
            exception of subscription agreements for such publications;
          </li>
          <li>agreements concluded during a public auction;</li>
          <li>
            the provision of accommodation for purposes other than residential,
            goods transport, car rental services, catering services, and
            services related to leisure activities if a specific date or period
            of performance is provided for in the agreements;
          </li>
          <li>
            the supply of digital content that is not supplied on a tangible
            medium if the performance has begun with the express prior consent
            of the customer and provided that the customer has acknowledged that
            they lose their right of withdrawal (e.g., downloading music,
            software);
          </li>
          <li>agreements for betting and lottery services.</li>
        </ul>
        <p>
          <strong>Article 8: Customer Service</strong>
          <br />
          Mindlab's customer service can be reached at the telephone number
          0472/58.56.25 or via email at{" "}
          <a href="mailto:info@mindlab.be">info@mindlab.be</a>. Any complaints
          can be addressed to this contact information.
        </p>
        <p>
          <strong>Article 9: Sanctions for Non-Payment</strong>
        </p>
        <p>
          <br />
          Without prejudice to the exercise of other rights held by Mindlab, in
          case of non-payment or late payment, the Customer shall, as of the
          date of default, automatically and without notice, owe an interest of
          10% per annum on the unpaid amount. In addition, the Customer shall
          automatically and without notice owe a lump-sum compensation of 10% on
          the amount concerned, with a minimum of 25 euros per invoice.
        </p>
        <p>
          <br />
          Notwithstanding the foregoing, Mindlab reserves the right to repossess
          the unpaid (partially or in full) items.
        </p>
        <p>
          <strong>Article 10: Privacy</strong>
        </p>
        <p>
          {" "}
          The data controller, Mindlab, respects the European privacy law
          (General Data Protection Regulation) regarding the protection of
          privacy in the processing of personal data.
        </p>
        <p>
          The personal data provided by you will only be used for the following
          purposes: the execution of the concluded agreement, the processing of
          the order, sending newsletters, advertising, and/or marketing
          purposes.
        </p>
        <p>
          You have a legal right to access and possibly correct your personal
          data. Upon proof of identity (copy of identity card), you can obtain,
          free of charge, the written communication of your personal data by a
          written, dated, and signed request to Mindlab, 0472/58.56.25,
          info@mindlab.be. If necessary, you can also request the correction of
          data that would be incorrect, incomplete, or not relevant.
        </p>
        <p>
          {" "}
          We treat your data as confidential information and will not disclose,
          rent, or sell it to third parties.
        </p>
        <p>
          <br />
          The customer is responsible for keeping his login information
          confidential and for the use of his password. Your password is stored
          encrypted, so Mindlab does not have access to your password.
        </p>
        <p>
          <br />
          Mindlab keeps online (anonymous) visitor statistics to assess the
          extent to which pages of the website are visited.
        </p>
        <p>
          If you have any questions about this privacy statement, you can
          contact us at&nbsp;
          <a href="mailto:info@myaddon.be">info@mindlab.be</a>.
        </p>
        <p>
          <strong>Article 11: Use of cookies</strong>
        </p>
        <p>No cookies are currently being used.</p>
        <p>
          <strong>Article 12: Validity - Non-waiver</strong>
        </p>
        <p>
          <br />
          If a provision of these Terms and Conditions is declared invalid,
          illegal, or void, this will in no way affect the validity, legality,
          and enforceability of the other provisions.
        </p>
        <p>
          Failure by Mindlab at any time to enforce any of the rights listed in
          these Terms and Conditions, or to exercise any such right, will never
          be construed as a waiver of such provision and will never affect the
          validity of these rights.
        </p>
        <p>
          <strong>Article 13: Amendment of terms</strong>
        </p>
        <p>
          <br />
          These Terms and Conditions are supplemented by other terms explicitly
          referred to and the general terms and conditions of sale of Mindlab.
          In case of conflict, these Terms and Conditions take precedence.
        </p>
        <p>
          <br />
          <strong>Article 14: Proof</strong>
        </p>
        <p>
          <br />
          The Customer accepts that electronic communications and backups can
          serve as evidence.
        </p>
        <p>
          <strong>Article 15: Applicable law - Competent court</strong>
        </p>
        <p>
          Belgian law applies, with the exception of the provisions of private
          international law regarding applicable law and the Vienna Convention
          on Contracts for the International Sale of Goods. Unless the customer
          is a consumer, any disputes shall be exclusively submitted to the
          courts of the district RECHTBANK VAN KOOPHANDEL GENT AFDELING
          DENDERMONDE.
        </p>
        <p>
          <strong>Legal warranty</strong> provided by the final seller: the
          consumer goods are covered by a warranty for a period of 2 years from
          the delivery and cannot be limited by the seller. <br />{" "}
          <a href="http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pllanguage=nl&amp;la=N&amp;cn=2013022819&amp;table_name=wet&amp;&amp;caller=list&amp;N&amp;fromtab=wet&amp;tri=dd+AS+RANK&amp;rech=1&amp;numero=1&amp;sql=(text+contains+(”))#Art.IX.1)">
            http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pllanguage=nl&amp;la=N&amp;cn=2013022819&amp;table_name=wet&amp;&amp;caller=list&amp;N&amp;fromtab=wet&amp;tri=dd+AS+RANK&amp;rech=1&amp;numero=1&amp;sql=(text+contains+(”))#Art.IX.1){" "}
          </a>
        </p>
        <p>
          <strong>Commercial warranty</strong> (or manufacturer's warranty) =
          not a legal warranty but a warranty agreed upon by the parties and
          provided by the final seller or the manufacturer. This warranty cannot
          limit the consumer's legal warranty (art. 1649septies Civil Code).
        </p>
      </div>
    );
  }

  content = (
    <div className="container">
      <h1>Algemene voorwaarden</h1>
      <p>
        <strong>Ondernemingsgegevens</strong>
      </p>
      <p>
        <br />
        Mindlab
      </p>
      <p>Sare De Caster</p>
      <p>
        info@mindlab.be
        <br />
        0472/58.56.25
      </p>
      <p>
        <br />
        <strong>Artikel 1: Algemene bepalingen</strong>
      </p>
      <p>
        <br />
        De e-commerce website van Mindlab biedt haar klanten de mogelijkheid om
        de producten uit haar webwinkel online aan te kopen.
      </p>
      <p>
        <br />
        Onderhavige Algemene Voorwaarden (“Voorwaarden”) zijn van toepassing op
        elke bestelling die geplaatst wordt door een bezoeker van deze
        e-commerce website (“Klant”). Bij het plaatsen van een bestelling via de
        webwinkel van Mindlab moet de Klant deze Voorwaarden uitdrukkelijk
        aanvaarden, waarmee hij instemt met de toepasselijkheid van deze
        Voorwaarden, met uitsluiting van alle andere voorwaarden. Bijkomende
        voorwaarden van de Klant worden uitgesloten, behoudens wanneer deze
        voorafgaandelijk, schriftelijk en uitdrukkelijk door Mindlab aanvaard
        zijn.
      </p>
      <p>
        <br />
        <strong>Artikel 2: Prijs</strong>
      </p>
      <p>
        <br />
        Alle vermelde prijzen zijn uitgedrukt in EURO, steeds inclusief BTW en
        alle andere verplicht door de Klant te dragen taksen of belastingen.
      </p>
      <p>
        <br />
        De opgave van prijs slaat uitsluitend op de artikelen zoals het
        woordelijk wordt omschreven. De bijhorende foto’s zijn decoratief
        bedoeld en kunnen elementen bevatten die niet inbegrepen zijn in de
        prijs.
      </p>
      <p>
        <strong>Artikel 3: Aanbod</strong>
      </p>
      <p>
        <br />
        Ondanks het feit dat de online catalogus en de e-commerce website met de
        grootst mogelijke zorgvuldigheid worden samengesteld, is het toch
        mogelijk dat de aangeboden informatie onvolledig is, materiële fouten
        bevat, of niet up-to-date is. Kennelijke vergissingen of fouten in het
        aanbod binden Mindlab niet. Mindlab is wat de juistheid en volledigheid
        van de aangeboden informatie slechts gehouden tot een
        middelenverbintenis. Mindlab is in geen geval aansprakelijk ingeval van
        manifeste materiële fouten, zet- of drukfouten.
      </p>
      <p>
        <br />
        Wanneer de Klant specifieke vragen heeft over bv. beschikbaarheid,
        leveringstermijn of leveringswijze, verzoeken wij de Klant om vooraf
        contact op te nemen met onze klantendienst via de contactopties op onze
        website.
      </p>
      <p>
        <br />
        Het aanbod geldt steeds zolang de voorraad strekt en kan te allen tijde
        worden aangepast of ingetrokken door Mindlab. Mindlab kan niet
        aansprakelijk gesteld worden voor het niet beschikbaar zijn van een
        product.
      </p>
      <p>
        <br />
        <strong>Artikel 4: Online aankopen</strong>
        <br />
        De klant heeft de keuze tussen de volgende betaalwijzen:
      </p>
      <ul>
        <li>via kredietkaart</li>
        <li>via bankkaart</li>
        <li>via overschrijving</li>
        <li>via PayPal</li>
        <li>via iDeal</li>
      </ul>
      <p>
        Mindlab is gerechtigd een bestelling te weigeren ingevolge een ernstige
        tekortkoming van de Klant met betrekking tot bestellingen waarbij de
        Klant betrokken is.
      </p>
      <p>
        <strong>Artikel 5: Levering en uitvoering van de overeenkomst</strong>
      </p>
      <p>
        Artikelen besteld via deze webwinkel worden gratis online beschikbaar
        gesteld.
      </p>
      <p>
        Elke zichtbare beschadiging en/of kwalitatieve tekortkoming van een
        artikel of andere tekortkoming bij de levering, moeten door de Klant
        onverwijld en onmiddellijk worden gemeld aan Mindlab.
      </p>
      <p>
        <strong>Artikel 6: Eigendomsvoorbehoud</strong>
      </p>
      <p>De geleverde artikelen blijven de exclusieve eigendom van Mindlab.</p>
      <p>
        De klant verbindt er zich toe zo nodig derden op het eigendomsvoorbehoud
        van Mindlab te wijzen.
      </p>
      <p>
        <strong>Artikel 7: Herroepingsrecht</strong>
      </p>
      <p>
        De bepalingen van dit artikel gelden enkel voor Klanten die in hun
        hoedanigheid van consument artikelen online aankopen bij Mindlab.
      </p>
      <p>Indien het herroepingsrecht van toepassing is:</p>
      <p>
        De Klant heeft het recht om binnen een termijn van 14 kalenderdagen
        zonder opgave van redenen de overeenkomst te herroepen.
      </p>
      <p>
        De herroepingstermijn verstrijkt 14 kalenderdagen na de dag waarop de
        Klant of een door de Klant aangewezen derde, die niet de vervoerder is,
        het laatste goed fysiek in bezit krijgt.;
      </p>
      <p>
        Om het herroepingsrecht uit te oefenen, moet de klant&nbsp;
        <strong>Mindlab, info@</strong>
        <strong>mindlab</strong>
        <strong>.be,</strong>
        <strong>0472/58.56.25</strong>via een ondubbelzinnige verklaring (bv.
        schriftelijk per post, fax of e-mail) op de hoogte stellen van zijn
        beslissing de overeenkomst te herroepen. De Klant kan hiervoor
        gebruikmaken van het bijgevoegde modelformulier voor herroeping, maar is
        hiertoe niet verplicht.
      </p>
      <p>
        Indien de klant van deze mogelijkheid gebruik maakt zullen wij de Klant
        onverwijld op een duurzame gegevensdrager (bv. per e-mail) een
        ontvangstbevestiging van zijn herroeping sturen”.
      </p>
      <p>
        Om de herroepingstermijn na te leven moet de Klant zijn mededeling
        betreffende zijn uitoefening van het herroepingsrecht verzenden voordat
        de herroepingstermijn is verstreken.
      </p>
      <p>
        De klant moet de goederen onverwijld, doch in ieder geval niet later dan
        14 kalenderdagen na de dag waarop hij zijn beslissing om de overeenkomst
        te herroepen aan Mindlab heeft meegedeeld, terugzenden of overhandigen
        aan Mindlab, info@mindlab.be, 0472/58.56.25 ;
      </p>
      <p>
        De Klant is op tijd als hij de goederen terugstuurt voordat de termijn
        van 14 kalenderdagen is verstreken.
      </p>
      <p>
        Indien het teruggestuurde product op een of andere manier in waarde
        verminderd is, behoudt Mindlab zich het recht voor om de Klant
        aansprakelijk te stellen en schadevergoeding te eisen voor elke
        waardevermindering van de goederen die het gevolg is van het gebruik van
        de goederen door de Klant dat verder gaat dan nodig is om de aard, de
        kenmerken en de werking van de goederen vast te stellen.
      </p>
      <p>
        Enkel artikelen die zich in de originele verpakking bevinden, samen met
        alle toebehoren, gebruiksaanwijzingen en factuur of aankoopbewijs kunnen
        worden teruggenomen.
      </p>
      <p>
        Als de Klant heeft verzocht om de verrichting van diensten te laten
        beginnen tijdens de herroepingstermijn, betaalt de Klant een bedrag dat
        evenredig is aan hetgeen op het moment dat hij ons ervan in kennis heeft
        gesteld dat hij de overeenkomst herroept reeds geleverd is, vergeleken
        met de volledige uitvoering van de overeenkomst.
      </p>
      <p>
        Indien de Klant de overeenkomst herroept, zal Mindlab alle tot op dat
        moment van de Klant ontvangen betalingen, inclusief de standaard
        leveringskosten, aan de Klant terugbetalen binnen maximum 14
        kalenderdagen nadat Mindlab op de hoogte is gesteld van de beslissing
        van de Klant om de overeenkomst te herroepen. Bij verkoopovereenkomsten
        kan Mindlab wachten met de terugbetaling totdat hij alle goederen heeft
        teruggekregen, of totdat de Klant heeft aangetoond dat hij de goederen
        heeft teruggezonden, naar gelang welk tijdstip eerst valt.
      </p>
      <p>
        Eventuele extra kosten ten gevolge van de keuze van de Klant voor een
        andere wijze van levering dan de door Mindlab geboden goedkoopste
        standaard levering worden niet terugbetaald.
      </p>
      <p>
        Mindlab betaalt de klant terug met hetzelfde betaalmiddel als waarmee de
        Klant de oorspronkelijke transactie heeft verricht, tenzij de Klant
        uitdrukkelijk anderszins heeft ingestemd; in ieder geval zullen de Klant
        voor zulke terugbetaling geen kosten in rekening worden gebracht.
      </p>
      <p>
        Indien het herroepingsrecht niet van toepassing is, vermelden waarom op
        basis van de volgende categorieën:
      </p>
      <p>De Klant kan het herroepingsrecht niet uitoefenen voor:</p>
      <ul>
        <li>
          de levering of verstrekking van goederen of diensten waarvan de prijs
          gebonden is aan schommelingen op de financiële markt waarop Mindlab
          geen invloed heeft en die zich binnen de herroepingstermijn kunnen
          voordoen;
        </li>
        <li>
          de levering van volgens specificaties van de Klant vervaardigde
          goederen, of die duidelijk voor een specifieke persoon bestemd zijn;
        </li>
        <li>
          de levering van verzegelde goederen die niet geschikt zijn om te
          worden teruggezonden om redenen van gezondheidsbescherming of hygiëne
          en waarvan de verzegeling na de levering is verbroken;
        </li>
        <li>
          de levering van goederen die na levering door hun aard onherroepelijk
          vermengd zijn met andere producten;
        </li>
        <li>
          de levering van alcoholische dranken waarvan de prijs is
          overeengekomen bij de sluiting van de verkoopovereenkomst, maar
          waarvan de levering slechts kan plaatsvinden na 30 dagen, en waarvan
          de werkelijke waarde afhankelijk is van schommelingen van de markt
          waarop de onderneming geen invloed heeft;
        </li>
        <li>
          overeenkomsten waarbij de klant Mindlab specifiek verzocht heeft hem
          te bezoeken om daar dringende herstellingen of onderhoud te
          verrichten;
        </li>
        <li>
          de levering van verzegelde audio- en verzegelde video-opnamen en
          verzegelde computerprogrammatuur waarvan de verzegeling na levering is
          verbroken;
        </li>
        <li>
          de levering van kranten, tijdschriften of magazines, met uitzondering
          van overeenkomsten voor een abonnement op dergelijke publicaties;
        </li>
        <li>overeenkomsten die zijn gesloten tijdens een openbare veiling;</li>
        <li>
          de terbeschikkingstelling van accommodatie anders dan voor
          woondoeleinden, goederenvervoer, autoverhuurdiensten, catering en
          diensten met betrekking tot vrijetijdsbesteding, indien in de
          overeenkomsten een bepaalde datum of periode van uitvoering is
          voorzien;
        </li>
        <li>
          de levering van digitale inhoud die niet op een materiële drager is
          geleverd, als de uitvoering is begonnen met uitdrukkelijke
          voorafgaande toestemming van de klant en mits de klant heeft erkend
          dat hij zijn herroepingsrecht daarmee verliest (bv. downloaden van
          muziek, software);
        </li>
        <li>
          de overeenkomsten voor diensten voor weddenschappen en loterijen.
        </li>
      </ul>
      <p>
        <strong>Artikel</strong>
        <strong>8</strong>
        <strong>: Klantendienst</strong>
        <br />
        De klantendienst van Mindlab is bereikbaar op het telefoonnummer
        0472/58.56.25, via e-mail op <a href="mailto:info@mindlab.be">info@</a>
        <a href="mailto:info@mindlab.be">mindlab.be</a>. Eventuele klachten
        kunnen hieraan gericht worden.
      </p>
      <p>
        <strong>Artikel 9: Sancties voor niet-betaling</strong>
      </p>
      <p>
        <br />
        Onverminderd de uitoefening van andere rechten waarover Mindlab
        beschikt, is de Klant ingeval van niet- of laattijdige betaling vanaf de
        datum van de wanprestatie van rechtswege en zonder aanmaning een intrest
        van 10% per jaar verschuldigd op het niet-betaalde bedrag. Bovendien is
        de Klant van rechtswege en zonder aanmaning een forfaitaire
        schadeloosstelling verschuldigd van 10% op het betrokken bedrag, met een
        minimum van 25 euro per factuur.
      </p>
      <p>
        <br />
        Onverminderd het voorgaande behoudt Mindlab zich het recht voor de niet
        (volledig) betaalde artikelen terug te nemen.
      </p>
      <p>
        <strong>Artikel 10: Privacy</strong>
      </p>
      <p>
        {" "}
        De verantwoordelijke voor de verwerking, Mindlab respecteert de Europese
        privacywet (Algemene Verordening voor Gegevensbescherming) met
        betrekking tot de bescherming van het privéleven in de verwerking van de
        persoonlijke gegevens.
      </p>
      <p>
        De door u meegedeelde persoonsgegevens zullen enkel gebruikt worden voor
        volgende doeleinden: de uitvoering van de afgesloten overeenkomst, het
        verwerken van de bestelling, versturen van nieuwsbrieven, reclame- en/of
        marketingdoeleinden.
      </p>
      <p>
        U beschikt over een wettelijk recht op inzage en eventuele correctie van
        uw persoonsgegevens. Mits bewijs van identiteit (kopie identiteitskaart)
        kunt u via een schriftelijke, gedateerde en ondertekende aanvraag aan
        Mindlab, 0472/58.56.25, info@mindlab.be, gratis de schriftelijke
        mededeling bekomen van uw persoonsgegevens. Indien nodig kunt u ook
        vragen de gegevens te corrigeren die onjuist, niet volledig of niet
        pertinent zouden zijn.
      </p>
      <p>
        {" "}
        Wij behandelen uw gegevens als vertrouwelijke informatie en zullen die
        niet doorgeven, verhuren of verkopen aan derden.
      </p>
      <p>
        <br />
        De klant is zelf verantwoordelijk voor het vertrouwelijk houden van zijn
        logingegevens en het gebruik van zijn paswoord. Uw paswoord wordt
        gecodeerd opgeslagen, Mindlab heeft dus geen toegang tot uw paswoord.
      </p>
      <p>
        <br />
        Mindlab houdt online (anonieme) bezoekersstatistieken bij om te kunnen
        bekijken welke pagina’s van de internetsite in welke mate bezocht
        worden.
      </p>
      <p>
        Indien u vragen heeft over deze privacy statement, kunt u ons
        contacteren op&nbsp;<a href="mailto:info@myaddon.be">info@mindlab.be</a>
        .
      </p>
      <p>
        <strong>Artikel 11: Gebruik van cookies</strong>
      </p>
      <p>Er wordt momenteel geen gebruik gemaakt van cookies.</p>
      <p>
        <strong>Artikel 12: Aantasting geldigheid – niet-verzaking</strong>
      </p>
      <p>
        <br />
        Indien een bepaling van deze Voorwaarden ongeldig, onwettig of nietig
        wordt verklaard, zal dit op geen enkele wijze de geldigheid, de
        wettigheid en de toepasbaarheid van de andere bepalingen aantasten.
      </p>
      <p>
        Het nalaten op gelijk welk moment door Mindlab om één van de in deze
        Voorwaarden opgesomde rechten af te dwingen, of gelijk welk recht
        hiervan uit te oefenen, zal nooit gezien worden als een verzaking aan
        zulke bepaling en zal nooit de geldigheid van deze rechten aantasten.
      </p>
      <p>
        <strong>Artikel 13: Wijziging voorwaarden</strong>
      </p>
      <p>
        <br />
        Deze Voorwaarden worden aangevuld door andere voorwaarden waar expliciet
        naar verwezen wordt, en de algemene verkoopsvoorwaarden van Mindlab.
        Ingeval van tegenstrijdigheid, primeren onderhavige Voorwaarden.
      </p>
      <p>
        <br />
        <strong>Artikel 1</strong>
        <strong>4</strong>
        <strong>: Bewijs</strong>
      </p>
      <p>
        <br />
        De Klant aanvaardt dat elektronische communicaties en back-ups als
        bewijsvoering kunnen dienen.
      </p>
      <p>
        <strong>Artikel 15: Toepasselijk recht – Bevoegde rechter</strong>
      </p>
      <p>
        Het Belgisch recht is van toepassing, met uitzondering van de bepalingen
        van internationaal privaatrecht inzake toepasselijk recht en met
        uitzondering van het Verdrag van Wenen inzake internationale
        koopovereenkomsten betreffende roerende zaken. Tenzij de klant een
        consument is, zijn bij eventuele geschillen uitsluitend de rechtbanken
        van het arrondissement RECHTBANK VAN KOOPHANDEL GENT AFDELING
        DENDERMONDE bevoegd.
      </p>
      <p>
        <strong>Wettelijke garantie</strong>&nbsp;gegeven door de eindverkoper:
        het consumptiegoed valt onder garantie gedurende een periode van 2 jaar
        vanaf de levering ervan en kan niet beperkt worden door de verkoper
        <br />{" "}
        <a href="http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pllanguage=nl&amp;la=N&amp;cn=2013022819&amp;table_name=wet&amp;&amp;caller=list&amp;N&amp;fromtab=wet&amp;tri=dd+AS+RANK&amp;rech=1&amp;numero=1&amp;sql=(text+contains+(”))#Art.IX.1)">
          http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pllanguage=nl&amp;la=N&amp;cn=2013022819&amp;table_name=wet&amp;&amp;caller=list&amp;N&amp;fromtab=wet&amp;tri=dd+AS+RANK&amp;rech=1&amp;numero=1&amp;sql=(text+contains+(”))#Art.IX.1){" "}
        </a>
      </p>
      <p>
        <strong>Commerciële garantie&nbsp;</strong>(of fabrieksgarantie) = niet
        wettelijke maar door de partijen overeengekomen garantie gegeven door de
        eindverkoper of door de producent. Deze mag de wettelijke garantie van
        de consument niet beperken (art. 1649septies BW).
      </p>
    </div>
  );

  return (
    <>
      {content} <Footer authenticated={false}></Footer>
    </>
  );
}
