import { useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Navigate } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { deletePartners, getPartners, Partner } from "../api/PartnersApi";

export function Partners() {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [addingPartner, setAddingPartner] = useState(false);

  useEffectOnce(() => {
    getPartners().then((r) => {
      setPartners(r);
    });
  });

  if (addingPartner) {
    return <Navigate to="/dashboard/partners/new" />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Partners</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingPartner(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        {partners.length === 0 ? (
          "Momenteel nog geen partners beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Logo</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {partners.map((business) => {
                return (
                  <tr key={business.id}>
                    <td>
                      <img
                        alt="logo"
                        src={business.url}
                        style={{
                          height: "50px",
                          objectFit: "contain",
                          width: "50px",
                        }}
                      />
                    </td>
                    <td>
                      <div className="flex">
                        <div
                          className="button small w-fit ml-5 pink"
                          onClick={async () => {
                            await deletePartners(business.id);
                            setPartners(
                              partners.filter((l) => l.id !== business.id),
                            );
                          }}
                        >
                          <FaTimes />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
