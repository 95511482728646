import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useEffectOnce } from "react-use";
import { BusinessSidebar } from "../BusinessSidebar";
import { Loading } from "../Loading";
import {
  BusinessStatistics,
  ConnectedBusiness,
  getConnectedBusinesses,
  getStatsForBusiness,
} from "../api/BusinessApi";
import { getModules, Module } from "../api/ModulesApi";
import { getSessions, Sessions } from "../api/UserApi";
import { ActivityGraph } from "./ActivityGraph";
import { ModulesGraph } from "./ModulesGraph";
import { SessionGraph } from "./SessionGraph";
import { SkillsGraph } from "./SkillsGraph";
import { StatsBoxRow } from "./StatsBoxRow";
import { TestsGraph } from "./TestsGraph";
import { UsersGraph } from "./UsersGraph";
import "./BusinessDashboard.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export function BusinessDashboard() {
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);

  const [stats, setStats] = useState<BusinessStatistics>();
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [modules, setModules] = useState<Module[]>([]);
  const [hasPremium, setHasPremium] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState<Sessions>();
  const translationResponse = useTranslation("hr-dashboard");

  const foundBusiness = connectedBusinesses.find(
    (b) => b.business.id === selectedBusiness,
  );

  useEffectOnce(() => {
    setLoading(true);
    getConnectedBusinesses().then((result) => {
      setConnectedBusinesses(result);

      if (result.length > 0) {
        setSelectedBusiness(result[0].business.id);
      }

      setHasPremium(result.filter((b) => b.business.type >= 1).length >= 1);
    });

    getModules().then((result) => {
      setModules(result);
    });
  });

  useEffect(() => {
    if (!selectedBusiness) {
      return;
    }

    setLoading(true);
    getStatsForBusiness(selectedBusiness).then((r) => {
      setStats(r);
      setLoading(false);
    });

    getSessions(selectedBusiness).then((r) => setSessions(r));
  }, [selectedBusiness]);

  return (
    <BusinessSidebar>
      {loading || !stats ? (
        <Loading />
      ) : (
        <>
          <div className="flex w-100">
            <div
              className={`ml-auto business-select ${
                isMobile ? "w-60 mt-4" : "w-15"
              }`}
            >
              <Select
                onChange={(e: any) => {
                  setSelectedBusiness(e.value);
                }}
                options={connectedBusinesses.map((b) => {
                  return {
                    label: b.business.name,
                    value: b.business.id,
                  };
                })}
                value={
                  foundBusiness
                    ? {
                        label: foundBusiness.business.name,
                        value: foundBusiness.business.id,
                      }
                    : { label: "", value: "" }
                }
              />
            </div>
          </div>
          {stats && sessions ? (
            <div className="business-dashboard flex flex-column">
              <h2>Dashboard</h2>
              <StatsBoxRow stats={stats} />
              <div className={"flex gap wrap"}>
                <ModulesGraph
                  modules={modules.filter((m) => !m.skills)}
                  premium={false}
                  stats={stats}
                  translationResponse={translationResponse}
                />
                <SkillsGraph
                  locked={!hasPremium}
                  premium={true}
                  skills={modules.filter((m) => m.skills)}
                  stats={stats}
                />
                <ActivityGraph modulesActivity={stats.modulesActivity} />
                <UsersGraph stats={stats} />
                <SessionGraph sessions={sessions} />
                <TestsGraph testStats={stats.tests || []} />
              </div>
              <div className={"flex justify-between gap"}></div>
            </div>
          ) : null}
        </>
      )}
    </BusinessSidebar>
  );
}
