import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { useEffectOnce, useSearchParam } from "react-use";
import { graphql } from "./__generated__";
import { resetPassword } from "./api/AuthApi";

const choosePasswordMutation = graphql(`
  mutation choosePassword($input: ChoosePasswordV2Input!) {
    choosePasswordV2(input: $input)
  }
`);

function PasswordReset() {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [message, setMessage] = useState("");
  const [done, setDone] = useState(false);
  const id = useSearchParam("id");
  const email = useSearchParam("email");

  const [bloomupChoosePassword] = useMutation(choosePasswordMutation);

  const handleChoosePassword = useCallback(
    (e: React.FormEvent) => {
      if (!id) {
        return;
      }

      e.preventDefault();

      if (!process.env.NX_PUBLIC_USE_BLOOMUP_LOGIN) {
        resetPassword(password, id).then((r) => {
          setMessage(r);
        });
        setDone(true);
      } else {
        if (email)
          bloomupChoosePassword({
            onCompleted: () => {
              setDone(true);
            },
            onError: () => {
              setMessage("networkError");
            },
            variables: {
              input: {
                email: email,
                newPassword: password,
                resetToken: id,
              },
            },
          });
      }
    },
    [bloomupChoosePassword, email, id, password],
  );

  const { t } = useTranslation("login");

  useEffectOnce(() => {
    localStorage.setItem("reset", "true");
  });

  if (done) {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <div className="auth login">
      <h2>{t("reset")}</h2>
      <form className="auth-form" onSubmit={handleChoosePassword}>
        <label>{t("password")}</label>
        <input
          className="input"
          onChange={(input) => {
            setPassword(input.target.value);
          }}
          placeholder={t("password")}
          required={true}
          type="password"
          value={password}
        />
        <label>{t("repeat_password")}</label>
        <input
          className="input"
          onChange={(input) => {
            setPassword2(input.target.value);
          }}
          placeholder={t("repeat_password")}
          required={true}
          type="password"
          value={password2}
        />
        {message !== "" ? <div className="mt-4">{t(`${message}`)}</div> : null}
        <button
          className="button mt-4"
          disabled={password !== password2}
          type="submit"
        >
          {t("send")}
        </button>
      </form>
    </div>
  );
}

export default PasswordReset;
