import { useState } from "react";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import { Navigate } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { Blog, deleteBlog, getBlogs } from "../api/BlogApi";

export function Blogs() {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [addingBlog, setAddingBlog] = useState(false);
  const [editingBlog, setEditingBlog] = useState<Blog | null>(null);
  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    getBlogs().then((r) => {
      setBlogs(r);
    });
  });

  if (addingBlog) {
    return <Navigate to="/dashboard/blogs/new" />;
  }

  if (editingBlog) {
    return <Navigate to={`/dashboard/blogs/${editingBlog.id}`} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Blogs</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingBlog(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        <input
          className="input"
          onChange={(input) => {
            setFilter(input.target.value);
          }}
          placeholder={"Naam"}
          type="text"
          value={filter}
        />

        {blogs.length === 0 ? (
          "Momenteel nog geen blogs beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Korte tekst</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {blogs
                .filter((b) =>
                  b.nameNL.toLowerCase().includes(filter.toLowerCase()),
                )
                .map((business) => {
                  return (
                    <tr key={business.id}>
                      <td>{business.nameNL}</td>
                      <td>{business.excerptNL}</td>
                      <td>
                        <div className="flex">
                          <div
                            className="button small w-fit ml-5 pink ml-auto"
                            onClick={async () => {
                              await deleteBlog(business.id);
                              setBlogs(
                                blogs.filter((b) => b.id !== business.id),
                              );
                            }}
                          >
                            <FaTimes />
                          </div>
                          <div
                            className="button small w-fit ml-5 yellow"
                            onClick={() => {
                              setEditingBlog(business);
                            }}
                          >
                            <FaPen />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
