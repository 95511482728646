import { useState } from "react";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { getPodcasts, Podcast } from "../api/PodcastApi";
import { PodcastForm } from "./PodcastForm";

export function EditPodcast() {
  const { podcastId } = useParams<{ podcastId: string }>();
  const [podcast, setPodcast] = useState<Podcast | null>(null);

  useEffectOnce(() => {
    getPodcasts().then((r) => {
      setPodcast(r.find((c) => c.id === podcastId) || null);
    });
  });

  if (!podcast) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <PodcastForm podcast={podcast} />
    </Sidebar>
  );
}
