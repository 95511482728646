import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useNavigate } from "react-router";
import Select from "react-select";
import { useEffectOnce } from "react-use";
import { Dialog } from "./Dialog";
import { Footer } from "./Footer";
import { HomeModule } from "./HomeModule";
import { Enable2FA } from "./Profile";
import { getCorrectUrl, isB2C } from "./Utils";
import { graphql } from "./__generated__";
import { getDecodedToken } from "./api/AuthApi";
import { ConnectedBusiness, getConnectedBusinesses } from "./api/BusinessApi";
import { getBusinessSettings, SettingType } from "./api/BusinessSettingApi";
import { getModules, Module } from "./api/ModulesApi";
import {
  getCurrentUser,
  getRecentVisited,
  getTotalTimes,
  User,
} from "./api/UserApi";
import MeasureWellbeing from "./img/MeasureWellbeing.png";
import UniSexIcon from "./img/UniSexIcon.png";
import WavesBottom from "./img/WavesBottom.svg";
import FaceScanIcon from "./img/facescan.png";
import SearchProgram from "./img/headers/Modules.svg";
import ListenPodcast from "./img/headers/Podcasts.svg";
import Logo from "./img/logo.png";
import phases from "./img/phases.svg";
import Sare from "./img/sare.png";
import "./Home.css";

const joinOrgByEmailOrCodeMutation = graphql(`
  mutation joinOrgByEmailOrCodeHome($emailOrCode: String!) {
    joinOrganizationByEmailOrCodeV2(emailOrCode: $emailOrCode)
  }
`);

export function Home({
  authenticated,
  setShowSuggestFaceScanModal,
}: {
  authenticated: boolean;
  setShowSuggestFaceScanModal: (b: boolean) => void;
}) {
  const [modules, setModules] = useState<Module[]>([]);
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);

  const [hasSkills, setHasSkills] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [faDone, setFaDone] = useState(false);
  const [profile, setProfile] = useState<User | null>(null);
  const [totalTimes, setTotalTimes] = useState<number>(0);
  const [recentVisited, setRecentVisited] = useState<string[]>([]);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [businessSettings, setBusinessSettings] = useState<SettingType>({
    disableTests: false,
    enforce2FA: false,
    inactiveSiteLink: process.env.NX_PUBLIC_FRONTEND_URL || "",
  });

  const [joinOrganizationByEmailOrCode] = useMutation(
    joinOrgByEmailOrCodeMutation,
  );

  const navigate = useNavigate();

  const foundBusiness = connectedBusinesses.find(
    (b) => b.business.id === selectedBusiness,
  );

  const { i18n, t } = useTranslation("general");

  useEffectOnce(() => {
    getModules().then((result) => {
      setModules(result);
    });
    getCurrentUser().then((r) => {
      setProfile(r);
    });
    getRecentVisited().then((r) => {
      setRecentVisited(r);
    });
    getConnectedBusinesses().then(async (r) => {
      if (!r) {
        return;
      }

      localStorage.setItem("reset", "true");

      setConnectedBusinesses(r);

      if (r.length === 0 && !isB2C()) {
        setShowPopup(true);
      }

      setHasSkills(r.filter((b) => b.business.type >= 1).length >= 1);

      const settings = await getBusinessSettings(r[0].business.id);

      setBusinessSettings(settings.settings);
    });
    getTotalTimes().then((r) => {
      setTotalTimes(r.modules + r.tests + r.podcasts);
    });
  });

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(false);
    }, 2000);
  }, [showAnimation]);

  if (!authenticated) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className="home">
      {getDecodedToken().admin ? (
        <Select
          onChange={(e: any) => {
            setSelectedBusiness(e.value);
          }}
          options={connectedBusinesses.map((b) => {
            return {
              label: b.business.name,
              value: b.business.id,
            };
          })}
          value={
            foundBusiness
              ? {
                  label: foundBusiness.business.name,
                  value: foundBusiness.business.id,
                }
              : { label: "", value: "" }
          }
        />
      ) : null}
      <div className="hero-section content">
        <div className="section-left">
          <h1>{t(isB2C() ? "home-header-b2c" : "home-header")}</h1>
          {isB2C() ? null : (
            <div className={"offered-by"}>
              <div className={"mt-12"}>{t("offered-by")}</div>
              {connectedBusinesses &&
                connectedBusinesses
                  .filter(
                    (c) =>
                      !foundBusiness ||
                      c.business.id === foundBusiness.business.id,
                  )
                  .slice(0, 1)
                  .map((item) => {
                    return (
                      <img
                        alt={item.business.name}
                        src={getCorrectUrl(item.business.logo)}
                        style={{
                          height: item.business.height || "250px",
                          objectFit: "contain",
                          width: item.business.width || "auto",
                        }}
                      />
                    );
                  })}
            </div>
          )}
        </div>
        <div className="section-right">
          <img
            alt="Plants in different phases"
            src={phases}
            style={{ maxHeight: "500px", maxWidth: "500px", width: "100%" }}
          />
        </div>
      </div>
      <div className="progress">
        <div className="content progress-content">
          <div className="progress-block">
            <div className="title">{t("your-progress")}</div>
            <div className="progress-block-content">
              {recentVisited.map((item) => {
                const foundModule = modules.find((m) => m.id === item);

                if (!foundModule) {
                  return null;
                }

                return <HomeModule module={foundModule} />;
              })}
            </div>
            <Link className="flex more" to="/modules">
              {t("more")}
              <div className="arrow-blue" />
            </Link>
          </div>
          <div className="progress-block">
            <div className="title">{t("explore")}</div>
            <div>
              <div
                className="inspiration-block flex"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/modules");
                }}
              >
                <div className="icon">
                  <img alt="Icon of the call to action" src={SearchProgram} />
                </div>
                <div className="inspiration-title">{t("search-program")}</div>
                <div className="arrow-blue" />
              </div>
              <div
                className="inspiration-block flex"
                onClick={(e) => {
                  e.preventDefault();

                  if (isB2C()) {
                    navigate("/skills");
                  } else {
                    setShowSuggestFaceScanModal(true);
                  }
                }}
              >
                <div className="icon">
                  <img
                    alt="Icon of the call to action"
                    src={isB2C() ? SearchProgram : FaceScanIcon}
                  />
                </div>
                <div className="inspiration-title">
                  {t(isB2C() ? "search-skills" : "do-facescan")}
                </div>
                <div className="arrow-blue" />
              </div>
              {hasSkills ? (
                <div
                  className="inspiration-block flex"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/podcasts");
                  }}
                >
                  <div className="icon">
                    <img alt="Icon of the call to action" src={ListenPodcast} />
                  </div>
                  <div className="inspiration-title">{t("listen-podcast")}</div>
                  <div className="arrow-blue" />
                </div>
              ) : (
                <div
                  className="inspiration-block flex"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/tests");
                  }}
                >
                  <div className="icon">
                    <img
                      alt="Icon of the call to action"
                      src={MeasureWellbeing}
                    />
                  </div>
                  <div className="inspiration-title">
                    {t("measure-wellbeing")}
                  </div>
                  <div className="arrow-blue" />
                </div>
              )}
            </div>
          </div>
          <div className="progress-block">
            <div className="title">{t("my-times")}</div>
            <div className="time-circle">
              <div className="hours">{Math.floor(totalTimes / 60)} hr</div>
              <div className="minutes">{Math.floor(totalTimes % 60)} min</div>
            </div>
            <Link className="flex more" to="/user-dashboard/times">
              {t("more-dashboard")}
              <div className="arrow-blue" />
            </Link>
          </div>
        </div>
      </div>
      <div className="waves-block">
        <img alt={"waves"} src={WavesBottom} />
      </div>
      {connectedBusinesses.length > 0 ? (
        <div className="quote">
          <div className="content quote-content">
            <div className="quote-left flex-column">
              {isB2C() ? (
                <>
                  <div className={"quote-text"}>{t("b2c-quote")}</div>
                  <div className="flex author">
                    <img alt="Person image" src={Sare} />
                    <div className="quote-author-details">
                      <div className="quote-author">{t("b2c-author")}</div>
                      <div className="quote-author-role">
                        {t("b2c-author-role")}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                connectedBusinesses &&
                connectedBusinesses
                  .filter(
                    (c) =>
                      !foundBusiness ||
                      c.business.id === foundBusiness.business.id,
                  )
                  .slice(0, 1)
                  .map((item) => {
                    if (
                      (i18n.language === "nl" &&
                        item.business.info_nl === undefined) ||
                      (i18n.language === "en" &&
                        item.business.info_en === undefined) ||
                      (i18n.language === "fr" &&
                        item.business.info_fr === undefined)
                    ) {
                      return null;
                    }

                    return (
                      <>
                        <div
                          className="quote-text"
                          dangerouslySetInnerHTML={{
                            __html:
                              i18n.language === "nl"
                                ? item.business.info_nl
                                : i18n.language === "en"
                                  ? item.business.info_en
                                  : item.business.info_fr,
                          }}
                        />
                        <div className="flex author">
                          <img alt="Person image" src={UniSexIcon} />
                          <div className="quote-author-details">
                            <div className="quote-author">
                              {item.business.infoAuthorName}
                            </div>
                            <div className="quote-author-role">
                              {i18n.language === "nl"
                                ? item.business.infoAuthorTitleNl
                                : i18n.language === "en"
                                  ? item.business.infoAuthorTitleEn
                                  : item.business.infoAuthorTitleFr}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
              )}
            </div>
            <div className="logo-right">
              {isB2C() ? (
                <img
                  alt={"Logo Mindlab"}
                  src={Logo}
                  style={{
                    height: "auto",
                    width: "350px",
                  }}
                />
              ) : (
                connectedBusinesses &&
                connectedBusinesses
                  .filter(
                    (c) =>
                      !foundBusiness ||
                      c.business.id === foundBusiness.business.id,
                  )
                  .slice(0, 1)
                  .map((item) => {
                    return (
                      <img
                        alt={item.business.name}
                        src={getCorrectUrl(item.business.logo)}
                        style={{
                          height: item.business.height || "250px",
                          objectFit: "contain",
                          width: item.business.width || "auto",
                        }}
                      />
                    );
                  })
              )}
            </div>
          </div>
        </div>
      ) : null}
      <Footer authenticated={authenticated} />
      <Dialog
        isOpen={
          businessSettings.enforce2FA &&
          !faDone &&
          !profile?.twoFactorAuthEnabled
        }
        onRequestClose={() => {
          // Nothing
        }}
        padding={true}
        showCloseButton={false}
        size="small"
        title="2 staps verificatie instellen"
      >
        <Enable2FA
          close={() => {
            setFaDone(true);
          }}
        />
      </Dialog>
      <Dialog
        isOpen={showPopup}
        onRequestClose={() => {
          setShowPopup(false);
        }}
        padding={true}
        size={"large"}
        title={""}
      >
        <div className="flex">
          <div className="mr-5">
            Jouw profiel is nog niet gekoppeld aan een bedrijfscode. Voer hier
            jouw code in om toegang te krijgen tot de modules:{" "}
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                try {
                  await joinOrganizationByEmailOrCode({
                    variables: {
                      emailOrCode: code.toLowerCase().trim(),
                    },
                  });

                  setShowPopup(false);
                } catch {
                  setError(
                    "Er is iets foutgelopen bij het opslaan van de bedrijfscode",
                  );
                }
              }}
            >
              <input
                className="input"
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                type="text"
                value={code}
              />
              <div className="red"> {error ? error : null}</div>
              <button className="button small" type="submit">
                Doorgaan
              </button>
            </form>
          </div>
          <div className="ml-5">
            Heb je geen bedrijfscode, maar wil je toch graag een module
            aankopen? Klik dan hier om verder te gaan op{" "}
            <a href="www.yourmindlab.be">www.yourmindlab.be</a>.
          </div>
        </div>
      </Dialog>
    </div>
  );
}
