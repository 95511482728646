import { useState } from "react";
import { FaPlus, FaTimes, FaEye, FaPen, FaCopy } from "react-icons/fa";
import { Navigate } from "react-router";
import Select from "react-select";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { getSettings, updateSettings } from "../api/SettingApi";
import { listTests, Test, deleteTest } from "../api/TestApi";
import "./Tests.css";

export function Tests() {
  const [tests, setTests] = useState<Test[]>([]);
  const [addingTest, setAddingTest] = useState(false);
  const [copyTest, setCopyTest] = useState<Test | null>(null);
  const [viewingTest, setViewingTest] = useState<Test | null>(null);
  const [editingTest, setEditingTest] = useState<Test | null>(null);
  const [orientationTest, setOrientationTest] = useState<Test | null>(null);

  useEffectOnce(() => {
    listTests().then((r) => {
      setTests(r);

      getSettings().then((s) => {
        if (s && s.orientationTestId) {
          const test = r.find((t) => t.id === s.orientationTestId);

          setOrientationTest(test || null);
        }
      });
    });
  });

  if (addingTest) {
    return <Navigate to="/dashboard/tests/new" />;
  }

  if (copyTest) {
    return <Navigate to={`/dashboard/tests/new?copy=${copyTest.id}`} />;
  }

  if (viewingTest) {
    return <Navigate to={`/tests-view/${viewingTest.id}`} />;
  }

  if (editingTest) {
    return <Navigate to={`/dashboard/tests/${editingTest.id}`} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Testen</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingTest(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        {tests.length === 0 ? (
          "Momenteel nog geen testen beschikbaar"
        ) : (
          <>
            <div className="w-25">
              <label>Orientatietest</label>
              <Select
                className="input mt-4"
                onChange={(e: any) => {
                  const test = tests.find((t) => t.id === e.value);

                  setOrientationTest(test || null);
                }}
                options={tests.map((t) => {
                  return {
                    label: t.title_nl,
                    value: t.id,
                  };
                })}
                placeholder={"Orientatietest"}
                value={
                  orientationTest
                    ? {
                        label: orientationTest.title_nl,
                        value: orientationTest.id,
                      }
                    : null
                }
              />
              <div
                className="button w-fit mb-4 mt-4"
                onClick={async () => {
                  if (orientationTest) {
                    await updateSettings({
                      orientationTestId: orientationTest.id,
                    });
                  }
                }}
              >
                Opslaan
              </div>
            </div>
            <table className="table ">
              <thead>
                <tr>
                  <th>Naam</th>
                  <th>Beschrijving</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {tests.map((test) => {
                  return (
                    <tr key={test.id}>
                      <td>{test.title_nl}</td>
                      <td>{test.description_nl}</td>
                      <td>
                        <div className="flex">
                          <div
                            className="button small w-fit ml-auto"
                            onClick={() => {
                              setViewingTest(test);
                            }}
                          >
                            <FaEye />
                          </div>
                          <div
                            className="button small w-fit ml-5 yellow"
                            onClick={() => {
                              setEditingTest(test);
                            }}
                          >
                            <FaPen />
                          </div>
                          <div
                            className="button small w-fit ml-5"
                            onClick={() => {
                              setCopyTest(test);
                            }}
                          >
                            <FaCopy />
                          </div>
                          <div
                            className="button small w-fit ml-5 pink"
                            onClick={async () => {
                              // TODO: add confirm
                              await deleteTest(test);
                              setTests(tests.filter((t) => t.id !== test.id));
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </Sidebar>
  );
}
