import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { loggedIn } from "./api/AuthApi";
import { getCheckoutUrl } from "./api/UserApi";

export function Checkout() {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [discount, setDiscount] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffectOnce(() => {
    if (moduleId === "undefined") {
      return;
    }
  });

  function checkout() {
    getCheckoutUrl(
      moduleId,
      discount.toLowerCase().trim() === ""
        ? undefined
        : discount.toLowerCase().trim(),
    ).then((result) => {
      window.location.href = result.url;
    });
  }

  useEffect(() => {
    if (!loggedIn()) {
      navigate(`/login?redirect=/checkout/${moduleId}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!loggedIn()) {
    return <Navigate to={`/login?redirect=/checkout/${moduleId}`} />;
  }

  return (
    <div className="auth">
      <h2>Checkout</h2>
      <div>Kortingscode?</div>
      <input
        className="input w-10"
        onChange={(e) => {
          setDiscount(e.target.value);
        }}
        type="text"
        value={discount}
      />
      <button className="button mt-4" onClick={checkout}>
        Doorgaan
      </button>
      <button className="button mt-4 pink" onClick={checkout}>
        Overslaan
      </button>
    </div>
  );
}
