import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { Checkbox } from "../Checkbox";
import {
  addModuleExerciseResult,
  getModuleExerciseResult,
} from "../api/UserApi";

const items_nl = [
  {
    item1: "Ik zal een last zijn voor mijn familie als ik ziek word",
    item2: "Bevraag anderen over wat ze zouden denken als jij ziek wordt",
  },
  {
    item1: "Mijn handen constant wassen, voorkomt dat ik ziek word",
    item2:
      "Probeer eens je handen een dag niet te wassen en bekijk of je ziek wordt",
  },
  {
    item1: "Ik heb geen controle over mijn gedachten",
    item2:
      "Probeer je gedachten eens te lossen voor enkele minuten. Wat gebeurt er?",
  },
  {
    item1: "Veel testen en onderzoeken zal ziekte uitsluiten",
    item2:
      "Vraag bij je of hij kan testen op de ziekte. Gebruik de doktersafspraak als experiment.",
  },
  {
    item1: "Ik heb meer kans op ziek worden dan iemand anders",
    item2:
      "Schrijf neer wat je denkt dat er zal gebeuren. Bevraag mensen rondom jou hoe vaak zij ziek zijn. Vergelijk de frequentie van anderen met jezelf.",
  },
  {
    item1: "Ik moet onderzoeken of ik risico loop op X",
    item2:
      "Stop met onderzoek voeren naar de ziekte waar je je zorgen over maakt. Houd bij hoe angstig je je voelt in deze periode. Mindert je angst naarmate de tijd verstrijkt?",
  },
  {
    item1:
      "Als ik niet direct met een dokter spreek over mijn symptomen, zal ik doodgaan",
    item2:
      "Stel het spreken met een dokter voor een week uit. Onderzoek voor jezelf of het symptoom mindert. (doe dit niet voor een medische noodzaak!)",
  },
  {
    item1:
      "Ik zal iets belangrijk missen als ik mijn lichaam niet nauw in de gaten houd",
    item2:
      "Probeer een specifieke periode (vb 3 dagen) je lichaam niet te controleren. Evalueer nadien de uitkomst. Werd je ziek als je je lichaam niet in de gaten hield?",
  },
];

const items_en = [
  {
    item1: "I'll be a burden on my family if I get sick.",
    item2: "Ask others about what they'd think if you got sick",
  },
  {
    item1: "Constantly wash my hands, prevents me from getting sick",
    item2: "Try not washing your hands for a day and see if you get sick",
  },
  {
    item1: "I can't control my thoughts",
    item2: "Try to resolve your thoughts for a few minutes. What is going on?",
  },
  {
    item1: "Many tests and studies will rule out disease",
    item2:
      "Ask you if he can test for the disease. Use the doctor's appointment as an experiment.",
  },
  {
    item1: "I'm more likely to get sick than anyone else",
    item2:
      "Write down what you think will happen. Ask people around you how often they're sick. Compare the frequency of others with yourself.",
  },
  {
    item1: "I need to see if I'm at risk of X",
    item2:
      "Stop researching the disease you're worried about. Keep track of how anxious you feel during this period. Does your fear diminish as time passes?\n",
  },
  {
    item1:
      "If I don't speak directly to a doctor about my symptoms, I'm going to die.",
    item2:
      "Put off speaking to a doctor for a week. Research for yourself if the symptom is deducted. (don't do this for a medical necessity!)",
  },
  {
    item1:
      "I'll miss something important if I don't keep a close eye on my body	",
    item2:
      "Try not to control your body for a specific period (e.g. 3 days). Evaluate the outcome afterwards. Did you get sick if you didn't keep an eye on your body?",
  },
];

const items_fr = [
  {
    item1: "Je serai un fardeau pour ma famille si je tombe malade.",
    item2: "Demandez aux autres ce qu’ils penseraient si vous tombez malade",
  },
  {
    item1: "Se laver constamment les mains, m’empêche de tomber malade",
    item2:
      "Essayez de ne pas vous laver les mains pendant une journée et voir si vous tombez malade",
  },
  {
    item1: "Je ne peux pas contrôler mes pensées",
    item2:
      "Essayez de résoudre vos pensées pendant quelques minutes. Que se passe-t-il?",
  },
  {
    item1: "De nombreux tests et études excluront la maladie",
    item2:
      "Demandez-vous s’il peut tester la maladie. Utilisez le rendez-vous du médecin comme une expérience.",
  },
  {
    item1:
      "Je suis plus susceptible de tomber malade que n’importe qui d’autre",
    item2:
      "Notez ce que vous pensez qu’il va se passer. Demandez aux gens autour de vous combien de fois ils sont malades. Comparez la fréquence des autres avec vous-même.",
  },
  {
    item1: "J’ai besoin de voir si je suis à risque de X",
    item2:
      "Arrêtez de faire des recherches sur la maladie qui vous inquiète. Gardez une trace de l’anxiété que vous ressentez pendant cette période. Votre peur diminue-t-elle avec le temps ?",
  },
  {
    item1:
      "Si je ne parle pas directement à un médecin de mes symptômes, je vais mourir.",
    item2:
      "Je ne parle pas à un médecin pendant une semaine. Recherchez par vous-même si le symptôme est déduit. (ne faites pas cela pour une nécessité médicale!)",
  },
  {
    item1:
      "Je vais manquer quelque chose d’important si je ne garde pas un œil sur mon corps",
    item2:
      "Essayez de ne pas contrôler votre corps pendant une période spécifique (p. ex. 3 jours). Évaluer le résultat par la suite. Tu es tombé malade si tu n’avais pas gardé un oeil sur ton corps ?",
  },
];

export function ChallengeToughts({ id }: { id: string }) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [saved, setSaved] = useState(false);

  const { i18n, t } = useTranslation("general");

  const values =
    i18n.language === "nl"
      ? items_nl
      : i18n.language === "en"
        ? items_en
        : items_fr;

  const [checked, setChecked] = useState<number[]>([]);

  useEffectOnce(() => {
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult = result.find(
        (r) => r.key === `ChallengeToughts-${id}`,
      );

      if (currentResult) {
        setChecked(JSON.parse(currentResult.result));
      }
    });
  });

  return (
    <div>
      <table className="w-100">
        <thead>
          <th />
          <th>
            {i18n.language === "nl" && "Als je denkt ..."}
            {i18n.language === "en" && "When you think..."}
            {i18n.language === "fr" && "Quand vous pensez..."}
          </th>
          <th>
            {i18n.language === "nl" && "test het experiment"}
            {i18n.language === "en" && "Test the experiment"}
            {i18n.language === "fr" && "Tester l'expérience"}
          </th>
        </thead>
        <tbody>
          {values.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <Checkbox
                    onClick={(result) => {
                      if (result) {
                        setChecked(checked.concat(index));
                      } else {
                        setChecked(checked.filter((i) => i !== index));
                      }
                    }}
                    title={""}
                    value={checked.includes(index)}
                  />
                </td>
                <td>{item.item1}</td>
                <td>{item.item1}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {saved ? <div className="green mt-4 mb-4">{t("saved")}!</div> : null}
      <div
        className="button w-fit mb-4"
        onClick={async (e) => {
          e.preventDefault();

          await addModuleExerciseResult(
            lessonId,
            `ChallengeToughts-${id}`,
            JSON.stringify(checked),
          );

          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
        }}
      >
        {t("bewaar")}
      </div>
    </div>
  );
}
