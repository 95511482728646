import { useState } from "react";
import { FaPen, FaTimes } from "react-icons/fa";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { addImage } from "../api/BusinessApi";
import {
  createTestType,
  deleteTestType,
  listTestTypes,
  TestType,
  updateTestType,
} from "../api/TestApi";

export function TestTypes() {
  const [testTypes, setTestTypes] = useState<TestType[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [titleNl, setTitleNl] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [editType, setType] = useState<TestType | null>(null);

  useEffectOnce(() => {
    listTestTypes().then((r) => {
      setTestTypes(r);
    });
  });

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Test Types</h2>
        </div>
        <div className="mb-4">
          <label>Titel in het Nederlands</label>
          <input
            className="input"
            onChange={(e) => {
              setTitleNl(e.target.value);
            }}
            type="text"
            value={titleNl}
          />
          <label>Titel in het Engels</label>
          <input
            className="input"
            onChange={(e) => {
              setTitleEn(e.target.value);
            }}
            type="text"
            value={titleEn}
          />
          <label>Titel in het Frans</label>
          <input
            className="input"
            onChange={(e) => {
              setTitleFr(e.target.value);
            }}
            type="text"
            value={titleFr}
          />
          <label>Upload image</label>
          <input
            accept={"image/*"}
            className="input"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setSelectedFile(e.target.files[0]);
              }
            }}
            placeholder="Upload image"
            type="file"
          />
          <div
            className="button small w-fit"
            onClick={async (e) => {
              e.preventDefault();

              let pathResult;

              if (selectedFile) {
                pathResult = await addImage(selectedFile);
              }

              let result: TestType;

              if (editType) {
                result = await updateTestType(
                  {
                    image:
                      pathResult &&
                      typeof pathResult !== "string" &&
                      pathResult.path
                        ? pathResult.path
                        : editType.image,
                    titleEN: titleEn,
                    titleFR: titleFr,
                    titleNL: titleNl,
                  },
                  editType.id,
                );

                setTestTypes(
                  testTypes.map((i) => {
                    if (i.id === editType.id) {
                      return result;
                    }

                    return i;
                  }),
                );
                setType(null);
              } else {
                result = await createTestType({
                  image:
                    pathResult &&
                    typeof pathResult !== "string" &&
                    pathResult.path
                      ? pathResult.path
                      : "",
                  titleEN: titleEn,
                  titleFR: titleFr,
                  titleNL: titleNl,
                });

                setTestTypes(testTypes.concat(result));
              }

              setSelectedFile(null);
              setTitleFr("");
              setTitleNl("");
              setTitleEn("");
            }}
          >
            Opslaan
          </div>
        </div>

        {testTypes.length === 0 ? (
          "Momenteel nog geen types beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {testTypes.map((type) => {
                return (
                  <tr key={type.id}>
                    <td>{type.titleNL}</td>
                    <td>
                      <div className="flex">
                        <div
                          className="button small w-fit ml-5 yellow"
                          onClick={() => {
                            setTitleNl(type.titleNL);
                            setTitleFr(type.titleFR);
                            setTitleEn(type.titleEN);
                            setType(type);
                          }}
                        >
                          <FaPen />
                        </div>
                        <div
                          className="button small w-fit ml-5 pink"
                          onClick={async () => {
                            await deleteTestType(type.id);
                            setTestTypes(
                              testTypes.filter((t) => t.id !== type.id),
                            );
                          }}
                        >
                          <FaTimes />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
