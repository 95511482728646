import { Sidebar } from "../Sidebar";
import { DiscountForm } from "./DiscountForm";

export function CreateDiscount() {
  return (
    <Sidebar>
      <DiscountForm discount={null} />
    </Sidebar>
  );
}
