import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { pdf } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useEffectOnce } from "react-use";
import { QuarterAndYear } from "./QuarterlyReport/QuarterAndYear";
import { createTimeBoxImage } from "./QuarterlyReport/getReportImage";
import { RowData, Table } from "./Table";
import { TimeReport } from "./TimeReport";
import { UserDashboardSidebar } from "./UserDashboardSidebar";
import { getDecodedToken } from "./api/AuthApi";
import { getTotalTimesPerQuarter, QuarterTime } from "./api/UserApi";
import "./UserDashboardTimes.css";

export function UserDashboardTimes() {
  const [quarterTimes, setQuarterTimes] = useState<QuarterTime[]>([]);
  const [rowData, setRowData] =
    useState<RowData<QuarterAndYear, number, QuarterAndYear, undefined>>();

  const { t } = useTranslation("user-dashboard");
  const translationResponse = useTranslation("user-dashboard");

  useEffectOnce(() => {
    getTotalTimesPerQuarter().then(async (quarterTimes) => {
      setQuarterTimes(quarterTimes);

      const rowData: RowData<
        QuarterAndYear,
        number,
        QuarterAndYear,
        undefined
      > = [];

      for (const quarterTime of quarterTimes) {
        const quarterAndYear: QuarterAndYear = {
          quarter: quarterTime.quarter,
          year: quarterTime.year,
        };

        rowData.push({
          buttonColValue: quarterAndYear,
          col1Value: quarterAndYear,
          col2Value:
            quarterTime.tests + quarterTime.modules + quarterTime.podcasts,
          type: undefined,
        });
      }

      setRowData(rowData);
    });
  });

  function downloadReport(quarterTime: QuarterTime) {
    const toastId = toast.loading("Creating report...");

    setTimeout(async () => {
      const image = await createTimeBoxImage(
        quarterTime.tests + quarterTime.modules + quarterTime.podcasts,
      );

      const blob = await pdf(
        <TimeReport
          date={`Q${quarterTime.quarter}/${quarterTime.year}`}
          name={`${getDecodedToken().firstName} ${getDecodedToken().lastName}`}
          result={image}
          translationResponse={translationResponse}
        />,
      ).toBlob();

      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = `${t("times.title")} ${DateTime.utc().toFormat(
        "dd/LL/y",
      )}.pdf`;
      link.click();

      toast.dismiss(toastId);
    }, 1);
  }

  return (
    <UserDashboardSidebar>
      <div className="dashboard-times">
        <h2 className="title-underlined">{t("times.title")}</h2>
        {rowData ? (
          <Table<QuarterAndYear, number, QuarterAndYear, undefined>
            buttonIcon={faDownload}
            col1Data={{
              cellRenderer: ({ value }) => `Q${value.quarter}/${value.year}`,
              headerName: t("times.quarter"),
            }}
            col2Data={{
              cellRenderer: ({ value }) => {
                const hours = Math.floor(value / 60);
                const minutes = value % 60;

                return `${hours}hr ${minutes}min`;
              },
              headerName: t("times.spentTime"),
            }}
            onButtonClick={(quarterAndYear) => {
              const foundQuarterAndYear = quarterTimes.find(
                (q) =>
                  q.year === quarterAndYear.year &&
                  q.quarter === quarterAndYear.quarter,
              );

              if (!foundQuarterAndYear) {
                return;
              }

              downloadReport(foundQuarterAndYear);
            }}
            rowData={rowData}
          />
        ) : null}
      </div>
      <ToastContainer />
    </UserDashboardSidebar>
  );
}
