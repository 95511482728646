import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useEffectOnce } from "react-use";
import { Sidebar } from "./Sidebar";
import { getCmsItem, upsertCmsItem } from "./api/CMSApi";

export function FaqCms() {
  const [data, setData] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(0);

  useEffectOnce(() => {
    getCmsItem("faq").then((result) => {
      const parsed = JSON.parse(result.data);

      setData(parsed || []);
    });
  });

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>FAQ</h2>
        </div>

        {data.map((item: any, index: number) => {
          if (index !== show) {
            return (
              <>
                <div
                  className="pointer"
                  onClick={() => {
                    setShow(index);
                  }}
                >
                  {item.questionNL || "Lege vraag"}
                </div>
                <hr />
              </>
            );
          }

          return (
            <>
              <div>
                <label>Question NL</label>
                <FaTrash
                  className={"ml-5 pointer"}
                  onClick={() => {
                    setData(data.filter((_: any, id: number) => id !== index));
                  }}
                />
              </div>
              <input
                className="input"
                onChange={(input) => {
                  setData(
                    data.map((it: any, id: number) => {
                      if (id === index) {
                        return {
                          ...it,
                          questionNL: input.target.value,
                        };
                      }

                      return it;
                    }),
                  );
                }}
                placeholder={"Question NL"}
                type="text"
                value={item.questionNL}
              />
              <label>Answer NL</label>
              <textarea
                className="input"
                onChange={(input) => {
                  setData(
                    data.map((it: any, id: number) => {
                      if (id === index) {
                        return {
                          ...it,
                          answerNL: input.target.value,
                        };
                      }

                      return it;
                    }),
                  );
                }}
                placeholder={"Answer NL"}
                rows={6}
                value={item.answerNL}
              />
              <label>Question EN</label>
              <input
                className="input"
                onChange={(input) => {
                  setData(
                    data.map((it: any, id: number) => {
                      if (id === index) {
                        return {
                          ...it,
                          questionEN: input.target.value,
                        };
                      }

                      return it;
                    }),
                  );
                }}
                placeholder={"Question EN"}
                type="text"
                value={item.questionEN}
              />
              <label>Answer EN</label>
              <textarea
                className="input"
                onChange={(input) => {
                  setData(
                    data.map((it: any, id: number) => {
                      if (id === index) {
                        return {
                          ...it,
                          answerEN: input.target.value,
                        };
                      }

                      return it;
                    }),
                  );
                }}
                placeholder={"Answer EN"}
                rows={6}
                value={item.answerEN}
              />
              <label>Question FR</label>
              <input
                className="input"
                onChange={(input) => {
                  setData(
                    data.map((it: any, id: number) => {
                      if (id === index) {
                        return {
                          ...it,
                          questionFR: input.target.value,
                        };
                      }

                      return it;
                    }),
                  );
                }}
                placeholder={"Question FR"}
                type="text"
                value={item.questionFR}
              />
              <label>Answer FR</label>
              <textarea
                className="input"
                onChange={(input) => {
                  setData(
                    data.map((it: any, id: number) => {
                      if (id === index) {
                        return {
                          ...it,
                          answerFR: input.target.value,
                        };
                      }

                      return it;
                    }),
                  );
                }}
                placeholder={"Answer FR"}
                rows={6}
                value={item.answerFR}
              />
              <hr className="mt-4 mb-4" />
            </>
          );
        })}
        <div
          className={"mt-4 pointer"}
          onClick={() => {
            setData(
              data.concat({
                answerEN: "",
                answerFR: "",
                answerNL: "",
                questionEN: "",
                questionFR: "",
                questionNL: "",
              }),
            );
            setShow(data.length);
          }}
        >
          + add FAQ item
        </div>
      </div>
      <div className="mt-4 mb-4">{message}</div>
      <div
        className="button w-10 ml-auto mr-auto"
        onClick={() => {
          upsertCmsItem("faq", JSON.stringify(data));
          setMessage("Opgeslagen!");
        }}
      >
        Opslaan
      </div>
    </Sidebar>
  );
}
