import { useState } from "react";
import { useEffectOnce } from "react-use";
import { getModuleExerciseResult } from "../api/UserApi";

export function TableFormResults({ match }: { match: string }) {
  const regex =
    /columns=&quot;(.*)&quot; id=&quot;(.*)&quot; lesson_id=&quot;(.*)&quot;/gm;

  const result = regex.exec(match);
  const columns = (result && result[1].split(",")) || [];
  const id = (result && result[2]) || "";
  const lessonId = (result && result[3]) || "";
  const [savedInput, setSavedInput] = useState<Record<string, string>>({});

  useEffectOnce(() => {
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult = result.find((r) => r.key === `TableForm-${id}`);

      if (currentResult) {
        setSavedInput(JSON.parse(currentResult.result));
      }
    });
  });

  return (
    <div>
      <table className="w-100">
        <thead>
          {columns.map((item) => {
            return <th>{item}</th>;
          })}
        </thead>
        <tbody>
          <tr>
            {columns.map((item) => {
              return (
                <td>
                  <textarea
                    disabled={true}
                    rows={6}
                    style={{ width: "100%" }}
                    value={savedInput[item]}
                  />
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
