import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import montserratBold from "./fonts/Montserrat-Bold.otf";
import montserratRegular from "./fonts/Montserrat-Regular.otf";
import { TranslationResponseType } from "./i18n";
import Logo from "./img/logo.jpg";

export function FaceScanReport({
  date,
  results,
  title,
  translationResponse,
}: {
  date: string;
  results: { advice: string; image: string; result: string }[];
  title: string;
  translationResponse: TranslationResponseType;
}) {
  const { t } = translationResponse;

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: montserratRegular,
      },
      {
        src: montserratBold,
      },
    ],
  });

  const styles = StyleSheet.create({
    bottomLine: {
      borderBottom: "3px",
      borderColor: "#FFBA49",
      marginTop: "auto",
    },
    column: {
      width: "170px",
    },
    date: {
      fontSize: "13px",
      fontWeight: "bold",
      marginLeft: "auto",
    },
    doc: {
      fontFamily: "Montserrat",
    },
    generalView: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "40px",
    },
    headerText: {
      fontSize: "15px",
      fontWeight: "bold",
    },
    image: {
      height: "200px",
      left: "10px",
      objectFit: "contain",
      position: "absolute",
      top: "-80px",
      width: "100px",
    },
    imageStyle: {
      objectFit: "contain",
      objectPosition: "left",
      width: "190px",
    },
    line: {
      borderBottom: "1px",
      borderColor: "black",
      marginBottom: "5px",
      marginTop: "5px",
    },
    middle: {
      marginLeft: "14px",
      marginRight: "14px",
    },
    normalText: {
      fontSize: "8px",
    },
    page: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      borderBottom: "2px",
      borderColor: "#F1F6F8",
      display: "flex",
      flexDirection: "row",
      marginBottom: "10px",
      paddingBottom: "10px",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleWrapper: {
      alignItems: "baseline",
      borderBottom: "3px",
      borderColor: "#FFBA49",
      display: "flex",
      flexDirection: "row",
      marginBottom: "20px",
      marginTop: "20px",
      paddingBottom: "10px",
    },
  });

  function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }

  const chunkArray = [...chunks(results, 4)];

  return (
    <Document style={styles.doc}>
      {chunkArray.map((items) => {
        return (
          <Page orientation={"portrait"} style={styles.page}>
            <Image src={Logo} style={styles.image} />
            <View style={styles.generalView}>
              <View style={styles.titleWrapper}>
                <Text style={styles.title}>
                  {t("result")} {title}
                </Text>
                <Text style={styles.date}>{date}</Text>
              </View>
              <View>
                <View style={styles.row}>
                  <View style={styles.column}>
                    <Text style={styles.headerText}>{t("parameter")}</Text>
                  </View>
                  <View style={[styles.column, styles.middle]}>
                    <Text style={styles.headerText}>{t("result")}</Text>
                  </View>
                  <View style={styles.column}>
                    <Text style={styles.headerText}>{t("advice")}</Text>
                  </View>
                </View>
                {items.map((result) => {
                  return (
                    <View style={styles.row}>
                      <View style={styles.column}>
                        <Image src={result.image} style={styles.imageStyle} />
                      </View>
                      <View style={[styles.column, styles.middle]}>
                        <Text style={styles.normalText}>{result.result}</Text>
                      </View>
                      <View style={styles.column}>
                        <Text style={styles.normalText}>{result.advice}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.bottomLine} />
            </View>
          </Page>
        );
      })}
    </Document>
  );
}
