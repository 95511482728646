import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { getCorrectUrl, isB2C } from "../../Utils";
import { Lesson } from "../../api/LessonsApi";
import { Module } from "../../api/ModulesApi";
import "./Modules.css";

export function ModuleView({
  available,
  lessons,
  module,
  progressCount,
}: {
  available: boolean;
  lessons: Lesson[];
  module: Module;
  progressCount: number;
}) {
  const { i18n, t } = useTranslation("general");

  const subjectCount = new Set(lessons.map((a) => a.subjectId)).size;

  const percentage =
    lessons.length !== 0
      ? Math.min(Math.floor((progressCount / lessons.length) * 100), 100)
      : 100;

  return (
    <Link className="module" to={`/modules/${module.id}`}>
      <img
        alt={module.titleNL}
        className="module-image"
        src={getCorrectUrl(module.image)}
      />
      <div
        className={`ribbon ${
          percentage >= 100
            ? "finished"
            : percentage <= 0
              ? "to-start"
              : "started"
        }`}
      >
        {percentage >= 100
          ? t("finished2")
          : percentage <= 0
            ? t("to_start")
            : t("started")}
      </div>
      <div className="content">
        <div className="lessons">
          {subjectCount} {t("lessons")}
        </div>
        <div className="title">
          {i18n.language === "nl" && module.titleNL}
          {i18n.language === "en" && module.titleEN}
          {i18n.language === "fr" && module.titleFR}
        </div>
        <div className="bar-wrapper">
          <div
            className={`bar`}
            style={{
              width: `${percentage > 100 ? 100 : percentage}%`,
            }}
          />
        </div>

        <div style={{ fontSize: "13px", marginBottom: "10px" }}>
          {`${percentage > 100 ? 100 : percentage}%`} {t("finished")}
        </div>
        <div className="description">
          {i18n.language === "nl" && module.descriptionNL.slice(0, 87)}
          {i18n.language === "en" && module.descriptionEN.slice(0, 87)}
          {i18n.language === "fr" && module.descriptionFR.slice(0, 87)}...
        </div>
        {!available && isB2C() ? (
          <div className="flex items-center">
            <Link
              className="button yellow xsmall"
              to={`/checkout/${module.id}`}
            >
              {t("buy")}
            </Link>
            <div className="ml-auto pricing">&euro; {module.price}</div>
          </div>
        ) : null}
      </div>
    </Link>
  );
}
