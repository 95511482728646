import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { TranslationResponseType } from "./i18n";
import Logo from "./img/logo.jpg";

export function TestReport({
  date,
  results,
  title,
  translationResponse,
}: {
  date: string;
  results: string[];
  title: string;
  translationResponse: TranslationResponseType;
}) {
  const { t } = translationResponse;

  const styles = StyleSheet.create({
    bottomLine: {
      borderBottom: "3px",
      borderColor: "#FFBA49",
      marginTop: "auto",
    },
    date: {
      fontSize: "13px",
      fontWeight: "bold",
      marginLeft: "auto",
    },
    generalView: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "70px",
    },
    image: {
      height: "200px",
      left: "10px",
      objectFit: "contain",
      position: "absolute",
      top: "-80px",
      width: "100px",
    },
    imageText: {
      width: "100%",
    },
    line: {
      borderBottom: "1px",
      borderColor: "black",
      marginBottom: "5px",
      marginTop: "5px",
    },
    page: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleWrapper: {
      alignItems: "baseline",
      borderBottom: "3px",
      borderColor: "#FFBA49",
      display: "flex",
      flexDirection: "row",
      marginBottom: "20px",
      marginTop: "20px",
      paddingBottom: "10px",
    },
  });

  return (
    <Document>
      <Page orientation={"portrait"} style={styles.page}>
        <Image src={Logo} style={styles.image} />
        <View style={styles.generalView}>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>
              {t("result")} {title}
            </Text>
            <Text style={styles.date}>{date}</Text>
          </View>
          <View>
            {results.map((result, index) => {
              return (
                <View>
                  <Image src={result} style={styles.imageText} />
                  {index === results.length - 1 ? null : (
                    <View style={styles.line} />
                  )}
                </View>
              );
            })}
          </View>
          <View style={styles.bottomLine} />
        </View>
      </Page>
    </Document>
  );
}
