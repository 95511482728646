import { Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import ReactMarkdown from "react-markdown";
import { useEffectOnce } from "react-use";
import { Footer } from "./Footer";
import { Loading } from "./Loading";
import { getCorrectUrl } from "./Utils";
import {
  addPodcastFeedback,
  getPodcasts,
  Podcast as PodcastType,
} from "./api/PodcastApi";
import { addPodcastTime } from "./api/UserApi";
import PodcastImg from "./img/headers/Podcasts.svg";

let interval: any;

export function Podcast({ authenticated }: { authenticated: boolean }) {
  const { i18n, t } = useTranslation("general");

  const [loading, setLoading] = useState(true);
  const [podcasts, setPodcasts] = useState<PodcastType[]>([]);
  const [playing, setPlaying] = useState<string | null>(null);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffectOnce(() => {
    getPodcasts().then((r) => {
      setPodcasts(r);
      setLoading(false);
    });
  });

  useEffect(() => {
    interval = setInterval(
      async () => {
        const podcastId = localStorage.getItem("podcastId");

        if (!podcastId) {
          return;
        }

        await addPodcastTime(podcastId, 1);
      },
      1000 * 60 * 1,
    );

    return () => {
      localStorage.removeItem("podcastId");
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setSuccess(null);
    setError(null);
    setFeedback("");
    setScore(0);
  }, [playing]);

  return (
    <Fragment>
      <div className="allbanner">
        <div
          className="banner mySlides fade flex justify-evenly wrap"
          id="banner2"
          style={{
            backgroundColor: `#FFBB25`,
            minHeight: "400px",
          }}
        >
          <div className="banner-title w30" style={{ textAlign: "center" }}>
            <h1 style={{ color: "white" }}>{t("mindlabPodcast")}</h1>
          </div>
          <img
            src={PodcastImg}
            style={{
              maxHeight: "350px",
              maxWidth: "350px",
              width: "auto",
            }}
          />
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div
            className="mb-4 flex flex-column"
            style={{ marginLeft: "5%", marginRight: "5%" }}
          >
            {podcasts
              .filter((podcast) => {
                return (
                  (i18n.language === "nl" && podcast.name) ||
                  (i18n.language === "en" && podcast.nameEN) ||
                  (i18n.language === "fr" && podcast.nameFR)
                );
              })
              .map((podcast) => {
                return (
                  <div className="flex w-100 mt-4 mb-4" key={podcast.id}>
                    {isMobile ? null : (
                      <div
                        style={{
                          height: "250px",
                          marginRight: "20px",
                          maxWidth: "250px",
                          width: "100%",
                        }}
                      >
                        <img
                          src={getCorrectUrl(podcast.image)}
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        border: "1px solid black",
                        display: "flex",
                        flexDirection: "column",
                        padding: "20px",
                        width: "100%",
                      }}
                    >
                      <div className="flex">
                        {isMobile ? (
                          <div
                            style={{
                              height: "60px",
                              marginBottom: "20px",
                              marginRight: "20px",
                              maxWidth: "60px",
                              width: "100%",
                            }}
                          >
                            <img
                              src={getCorrectUrl(podcast.image)}
                              style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        ) : null}
                        <div>
                          <div
                            style={{
                              color: "#FFBB25",
                              fontSize: "20px",
                              fontWeight: "bold",
                              marginBottom: "12px",
                            }}
                          >
                            {i18n.language === "nl" && podcast.name}
                            {i18n.language === "en" && podcast.nameEN}
                            {i18n.language === "fr" && podcast.nameFR}
                          </div>
                          <div>
                            <ReactMarkdown>
                              {i18n.language === "nl"
                                ? podcast.description
                                : i18n.language === "en"
                                  ? podcast.descriptionEN
                                  : podcast.descriptionFR}
                            </ReactMarkdown>
                          </div>
                        </div>
                      </div>
                      <div className="mt-auto">
                        <audio
                          className={"w-100"}
                          controls={true}
                          key={podcast.id + i18n.language}
                          onPause={() => {
                            localStorage.removeItem("podcastId");
                          }}
                          onPlay={() => {
                            localStorage.setItem("podcastId", podcast.id);
                            setPlaying(podcast.id);
                          }}
                        >
                          <source
                            src={getCorrectUrl(
                              i18n.language === "nl"
                                ? podcast.audio
                                : i18n.language === "en"
                                  ? podcast.audioEN
                                  : podcast.audioFR,
                            )}
                            type="audio/mp3"
                          />
                        </audio>
                        {playing === podcast.id ? (
                          <div className="w-80 ml-5">
                            <div className="flex items-center">
                              <textarea
                                className={"w-50"}
                                cols={2}
                                onChange={(e) => {
                                  setFeedback(e.target.value);
                                }}
                                rows={6}
                                value={feedback}
                              />
                              <div
                                className="ml-5 flex"
                                style={{ fontSize: "30px" }}
                              >
                                {Array.from(Array(5).keys()).map((_, index) => {
                                  return score >= index + 1 ? (
                                    <AiFillStar
                                      className="pointer"
                                      onClick={() => {
                                        setScore(index + 1);
                                      }}
                                      style={{ color: "#FEBB49" }}
                                    />
                                  ) : (
                                    <AiOutlineStar
                                      className="pointer"
                                      onClick={() => {
                                        setScore(index + 1);
                                      }}
                                      style={{ color: "#FEBB49" }}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                            {error ? (
                              <div className="error">{error}</div>
                            ) : null}
                            {success ? (
                              <div className="success">{success}</div>
                            ) : null}
                            <div
                              className="button w-fit"
                              onClick={async () => {
                                if (score === 0 && feedback.trim() === "") {
                                  setError(
                                    "Gelieve een score te geven en feedback in te vullen.",
                                  );
                                }

                                await addPodcastFeedback(
                                  podcast.id,
                                  score,
                                  feedback.trim(),
                                );
                                setError(null);
                                setSuccess(
                                  i18n.language === "nl"
                                    ? "Jouw feedback is ingestuurd!"
                                    : i18n.language === "en"
                                      ? "Your feedback is sent!"
                                      : "Envoyé",
                                );
                              }}
                            >
                              {i18n.language === "nl"
                                ? "Feedback insturen"
                                : i18n.language === "en"
                                  ? "Send feedback"
                                  : "Envoyé"}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <Footer authenticated={authenticated} />
    </Fragment>
  );
}
