import { useState } from "react";
import ReactQuill from "react-quill";
import { Navigate } from "react-router";
import { QuillFormats, QuillModules } from "../Modules/admins/LessonForm";
import { Blog, createBlog, updateBlog } from "../api/BlogApi";
import { addMedia } from "../api/MediaApi";

export function BlogForm({ blog }: { blog: Blog | null }) {
  const [nameNL, setNameNL] = useState(blog ? blog.nameNL : "");
  const [nameEN, setNameEN] = useState(blog ? blog.nameEN : "");
  const [nameFR, setNameFR] = useState(blog ? blog.nameFR : "");
  const [excerptNL, setExcerptNL] = useState(blog ? blog.excerptNL : "");
  const [excerptEN, setExcerptEN] = useState(blog ? blog.excerptEN : "");
  const [excerptFR, setExcerptFR] = useState(blog ? blog.excerptFR : "");
  const [contentNL, setContentNL] = useState(blog ? blog.contentNL : "");
  const [contentEN, setContentEN] = useState(blog ? blog.contentEN : "");
  const [contentFR, setContentFR] = useState(blog ? blog.contentFR : "");
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>();
  const [saved, setSaved] = useState(false);

  const image = blog ? blog.image : "";

  if (saved) {
    return <Navigate to={"/dashboard/blogs"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        let updatedBusiness = {
          banner: "",
          contentEN: contentEN,
          contentFR: contentFR,
          contentNL: contentNL,
          excerptEN: excerptEN,
          excerptFR: excerptFR,
          excerptNL: excerptNL,
          image: image,
          nameEN: nameEN || "",
          nameFR: nameFR || "",
          nameNL: nameNL,
        };

        if (selectedImageFile) {
          const pathResult = await addMedia(selectedImageFile);

          if (pathResult && typeof pathResult !== "string" && pathResult.path) {
            updatedBusiness = {
              ...updatedBusiness,
              image: pathResult.path,
            };
          }
        }

        if (blog) {
          updateBlog({
            ...updatedBusiness,
            id: blog.id,
          }).then(async () => {
            setSaved(true);
          });
        } else {
          createBlog({
            id: "",
            ...updatedBusiness,
          }).then(async () => {
            setSaved(true);
          });
        }
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-100">
          <div>
            <h4>Algemene info</h4>
            <label>Naam NL</label>
            <input
              className="input"
              onChange={(input) => {
                setNameNL(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={nameNL}
            />
            <label>Naam EN</label>
            <input
              className="input"
              onChange={(input) => {
                setNameEN(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={nameEN}
            />
            <label>Naam FR</label>
            <input
              className="input"
              onChange={(input) => {
                setNameFR(input.target.value);
              }}
              placeholder="Naam"
              type="text"
              value={nameFR}
            />
            <label>Korte tekst NL</label>
            <input
              className="input"
              onChange={(input) => {
                setExcerptNL(input.target.value);
              }}
              placeholder="Tekst"
              required={true}
              type="text"
              value={excerptNL}
            />
            <label>Korte tekst EN</label>
            <input
              className="input"
              onChange={(input) => {
                setExcerptEN(input.target.value);
              }}
              placeholder="Tekst"
              required={true}
              type="text"
              value={excerptEN}
            />
            <label>Korte tekst FR</label>
            <input
              className="input"
              onChange={(input) => {
                setExcerptFR(input.target.value);
              }}
              placeholder="Tekst"
              type="text"
              value={excerptFR}
            />
            <label>Afbeelding</label>
            <input
              className="input"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedImageFile(e.target.files[0]);
                }
              }}
              placeholder="Upload afbeelding"
              type="file"
            />
            <div className="container">
              <div className="flex">
                <h4>Content in het Nederlands</h4>
              </div>
              <div style={{ backgroundColor: "white" }}>
                <ReactQuill
                  formats={QuillFormats}
                  modules={QuillModules}
                  onChange={(value) => {
                    setContentNL(value);
                  }}
                  theme="snow"
                  value={contentNL}
                />
              </div>
            </div>
            <div className="container">
              <div className="flex">
                <h4>Content in het Engels</h4>
              </div>
              <div style={{ backgroundColor: "white" }}>
                <ReactQuill
                  formats={QuillFormats}
                  modules={QuillModules}
                  onChange={(value) => {
                    setContentEN(value);
                  }}
                  theme="snow"
                  value={contentEN}
                />
              </div>
            </div>
            <div className="container">
              <div className="flex">
                <h4>Content in het Frans</h4>
              </div>
              <div style={{ backgroundColor: "white" }}>
                <ReactQuill
                  formats={QuillFormats}
                  modules={QuillModules}
                  onChange={(value) => {
                    setContentFR(value);
                  }}
                  theme="snow"
                  value={contentFR}
                />
              </div>
            </div>

            <button className="button" type="submit">
              {blog ? "Blog bijwerken" : "Blog toevoegen"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
