import { getCorrectUrl } from "../Utils";
import { uniqueId } from "../uniqueId";

export function Audio({ url }: { url: string }) {
  return (
    <audio controls={true} key={uniqueId()}>
      <source src={getCorrectUrl(url)} type="audio/mp3" />
    </audio>
  );
}
