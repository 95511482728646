import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParam } from "react-use";
import { createUserTestScore, Test, TestStatement } from "./api/TestApi";

export function DefaultTest({ test }: { test: Test }) {
  const [showScore, setShowScore] = useState<Record<string, number> | null>(
    null,
  );

  const [currentQuestion, setCurrenQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const paramStarted = useSearchParam("started");
  const [started, setStarted] = useState(paramStarted === "true");
  const [sortedStatements, setSortedStatments] = useState<TestStatement[]>([]);
  const { i18n, t } = useTranslation("general");

  useEffect(() => {
    setStarted(paramStarted === "true" || false);
    setSelectedOptions([]);
    setShowScore(null);
    setCurrenQuestion(0);
    setSortedStatments(test.statements.sort((a, b) => a.position - b.position));
  }, [test, paramStarted]);

  const currentStatement = sortedStatements[currentQuestion];

  if (!started) {
    return (
      <div className="flex flex-column" key={test.id}>
        <div className="test-info">
          <div className="test-title">
            {i18n.language === "nl" && test.title_nl}
            {i18n.language === "en" && test.title_en}
            {i18n.language === "fr" && test.title_fr}
          </div>
          <div
            className="test-description"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "nl"
                  ? test.description_nl
                  : i18n.language === "en"
                    ? test.description_en || ""
                    : test.description_fr || "",
            }}
          />
        </div>
        <div
          className="test-button"
          onClick={() => {
            setStarted(true);
          }}
        >
          {t("start_test")}
        </div>
      </div>
    );
  }

  if (!currentStatement) {
    return null;
  }

  if (test.statements.length < 2) {
    return (
      <div>
        Er is iets foutgelopen bij het inladen van de test. Gelieve ons te
        contacteren of later opnieuw te proberen.
      </div>
    );
  }

  return (
    <div className="tests">
      {!showScore ? (
        <>
          <div className="testview">
            <div className="w-100">
              {" "}
              <div>
                <div className="flex flex-column" key={test.id}>
                  <div className="test-info mb-4">
                    <div className="test-title">
                      {i18n.language === "nl" && test.title_nl}
                      {i18n.language === "en" && test.title_en}
                      {i18n.language === "fr" && test.title_fr}
                    </div>
                    <div className="test-description">
                      {i18n.language === "nl" && currentStatement.title_nl}
                      {i18n.language === "en" && currentStatement.title_en}
                      {i18n.language === "fr" && currentStatement.title_fr}
                    </div>
                  </div>
                  <div className="flex justify-center mt-4 wrap">
                    {test.options
                      .filter(
                        (o) => o.groupId === currentStatement.optionGroupId,
                      )
                      .sort((a, b) => a.position - b.position)
                      .map((option, indexOption) => {
                        return (
                          <div
                            className={`test-option pointer ${
                              selectedOptions[currentQuestion] === option.id
                                ? "selected"
                                : ""
                            }`}
                            key={indexOption}
                            onClick={() => {
                              const tmp = [...selectedOptions];

                              tmp[currentQuestion] = option.id;
                              setSelectedOptions(tmp);

                              if (
                                currentQuestion === 0 ||
                                currentQuestion !== test.statements.length - 1
                              ) {
                                setCurrenQuestion(currentQuestion + 1);
                              }
                            }}
                          >
                            {i18n.language === "nl" && option.title_nl}
                            {i18n.language === "en" && option.title_en}
                            {i18n.language === "fr" && option.title_fr}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {currentQuestion !== test.statements.length - 1 ||
          selectedOptions[currentQuestion] === undefined ? (
            <div className="flex">
              {currentQuestion !== 0 ? (
                <div
                  className="button mt-4 w-fit"
                  onClick={() => {
                    setCurrenQuestion(currentQuestion - 1);
                  }}
                >
                  {t("previous")}
                </div>
              ) : null}
              {/*<div
                className="button mt-4 w-fit ml-5"
                onClick={() => {
                  setCurrenQuestion(currentQuestion + 1);
                }}
              >
                Volgende
              </div>*/}
            </div>
          ) : selectedOptions[currentQuestion] !== undefined ? (
            <div className="flex">
              <div
                className="button mt-4 w-fit"
                onClick={() => {
                  setCurrenQuestion(currentQuestion - 1);
                }}
              >
                {t("previous")}
              </div>
              <div
                className="button mt-4 w-fit ml-5"
                onClick={async () => {
                  const groupScores: Record<string, number> = {};

                  for (let index = 0; index < test.statements.length; index++) {
                    const statement = sortedStatements[index];
                    const current = groupScores[statement.groupId];

                    if (!current) {
                      groupScores[statement.groupId] = 0;
                    }

                    const foundOption = test.options.find(
                      (o) => o.id === selectedOptions[index],
                    );

                    if (!foundOption) {
                      continue;
                    }

                    groupScores[statement.groupId] += foundOption.score;
                  }

                  const currentDate = DateTime.utc();

                  for (const groupId of Object.keys(groupScores)) {
                    await createUserTestScore({
                      date: currentDate.toUTC().toISO() as string,
                      groupId: groupId,
                      score: groupScores[groupId],
                      testId: test.id,
                    });
                  }

                  setShowScore(groupScores);
                }}
              >
                {t("calculate_score")}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div>
          <h3 className="test-result">{t("score")}</h3>

          {Object.entries(showScore).map(([id, score]) => {
            const group = test.groups.find((g) => g.id === id);

            if (!group) {
              return null;
            }

            const groupScore = test.testScores
              .filter((s) => s.groupId === group.id && s.ltScore > score)
              .sort((a, b) => a.ltScore - b.ltScore)[0];

            if (!groupScore) {
              return null;
            }

            return (
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "nl"
                        ? groupScore.explanation_nl.replace(
                            "https://b2b-staging.mindlab.be",
                            "",
                          )
                        : i18n.language === "en"
                          ? (groupScore.explanation_en || "").replace(
                              "https://b2b-staging.mindlab.be",
                              "",
                            )
                          : (groupScore.explanation_fr || "").replace(
                              "https://b2b-staging.mindlab.be",
                              "",
                            ),
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
