import { useMutation } from "@apollo/client";
import { t } from "i18next";
import { useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router";
import { graphql } from "./__generated__";

const loginWithSSOMutation = graphql(`
  mutation LoginWithSSO($input: LoginWithSSOInput!) {
    loginWithSSO(input: $input) {
      ... on TokenResponse {
        accessToken
        mindlabToken
        refreshToken
      }
    }
  }
`);

export function SSORedirectV2({
  setAuthenticated,
}: {
  setAuthenticated: (state: boolean) => void;
}) {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const code = queryParams.get("code");
  const params = useParams<{ businessId: string }>();

  const [loginWithSSO, { error, loading }] = useMutation(loginWithSSOMutation, {
    async onCompleted(data) {
      if (
        data &&
        data.loginWithSSO.__typename === "TokenResponse" &&
        data.loginWithSSO.mindlabToken &&
        data.loginWithSSO.accessToken &&
        data.loginWithSSO.refreshToken
      ) {
        localStorage.setItem(
          "bloomupAccessToken",
          data.loginWithSSO.accessToken,
        );
        localStorage.setItem(
          "bloomupRefreshToken",
          data.loginWithSSO.refreshToken,
        );

        localStorage.setItem("token", data.loginWithSSO.mindlabToken);
        setAuthenticated(true);
        navigate("/home", { replace: true });
      }
    },
  });

  //The useEffect is a workaround to have the function run async at render
  useEffect(() => {
    if (code) {
      const loginFunc = async () => {
        await loginWithSSO({
          variables: {
            input: {
              authorizationCode: code,
              mindlabBusinessId: params.businessId,
            },
          },
        });
      };

      loginFunc();
    }
  }, [code, loginWithSSO, params.businessId]);

  return (
    <div className="container">
      {loading && (
        <RotatingLines
          animationDuration="0.75"
          strokeColor="#224453"
          strokeWidth="5"
          visible={loading}
          width={"115"}
        />
      )}
      {error && <div>{t("login:genericError")}</div>}
    </div>
  );
}
