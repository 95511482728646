import { useState } from "react";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import { Navigate, useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../../Sidebar";
import { getModule, Module } from "../../api/ModulesApi";
import { deleteSubject, getSubjects, Subject } from "../../api/SubjectsApi";

export function ViewSubjects() {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [module, setModule] = useState<Module>();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [addingSubject, setAddingSubject] = useState(false);
  const [editingSubject, setEditingSubject] = useState<false | Subject>(false);

  useEffectOnce(() => {
    getModule(moduleId).then((result) => {
      setModule(result);
    });

    getSubjects(moduleId).then((result) => {
      setSubjects(result);
    });
  });

  if (addingSubject) {
    return <Navigate to={`/dashboard/modules/${moduleId}/subjects/new`} />;
  }

  if (editingSubject) {
    return (
      <Navigate
        to={`/dashboard/modules/${moduleId}/subjects/${editingSubject.id}`}
      />
    );
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>{module?.titleNL} - onderwerpen</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingSubject(true);
            }}
          >
            <FaPlus />
          </div>
        </div>
        <table className="table ">
          <thead>
            <tr>
              <th>Naam</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {subjects.map((subject) => {
              return (
                <tr>
                  <td>{subject.nameNL}</td>
                  <td>
                    <div className="flex">
                      <div
                        className="button small w-fit ml-auto yellow"
                        onClick={() => {
                          setEditingSubject(subject);
                        }}
                      >
                        <FaPen />
                      </div>
                      <div
                        className="button small w-fit ml-5 pink"
                        onClick={async () => {
                          await deleteSubject(moduleId, subject.id);
                          setSubjects(
                            subjects.filter((s) => s.id !== subject.id),
                          );
                        }}
                      >
                        <FaTimes />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Sidebar>
  );
}
