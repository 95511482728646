import { useState } from "react";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { Test, getTest } from "../api/TestApi";
import { TestForm } from "./TestForm";

export function EditTest() {
  const { testId } = useParams<{ testId: string }>();
  const [test, setTest] = useState<Test | null>(null);

  useEffectOnce(() => {
    getTest(testId).then((r) => {
      setTest(r);
    });
  });

  if (!test) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <TestForm test={test} update={true} />
    </Sidebar>
  );
}
