import ReactPDF from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Quarter } from "./QuarterlyReport/Quarter";
import { QuarterlyReport } from "./QuarterlyReport/QuarterlyReport";
import {
  createStatsBoxRowImage,
  createModulesGraphImage,
  createSessionGraphImage,
  createUsersGraphImage,
  createActivityGraphImage,
  createTestsTableImage,
} from "./QuarterlyReport/getReportImage";
import { Business, getStatsForBusiness } from "./api/BusinessApi";
import { getModules } from "./api/ModulesApi";
import { listTests } from "./api/TestApi";
import { getSessions } from "./api/UserApi";

export function ReportBox({
  business,
  className,
  icon,
  locked,
  quarter,
  year,
}: {
  business: Business;
  className: string;
  icon: string;
  locked: boolean;
  quarter: Quarter;
  year: number;
}) {
  const { t } = useTranslation("report");
  const translationResponse = useTranslation("hr-dashboard");

  return (
    <div>
      <div
        className={`${className} box`}
        onClick={async () => {
          const toastId = toast.loading("Creating report...");

          try {
            // create pdf
            const modules = await getModules();
            const allStats = await getStatsForBusiness(business.id);
            const stats = await getStatsForBusiness(business.id, {
              quarter,
              year,
            });

            const statsBoxRowImage = await createStatsBoxRowImage(
              allStats,
              !locked,
            );

            const modulesWithoutSkills = modules.filter((m) => !m.skills);
            const modulesGraphImage = await createModulesGraphImage(
              modulesWithoutSkills,
              stats,
              translationResponse,
            );

            const skills = modules.filter((m) => m.skills);
            const skillsGraphImage = await createModulesGraphImage(
              skills,
              stats,
              translationResponse,
            );

            const sessions = await getSessions(business.id, {
              quarter,
              year,
            });

            const sessionGraphImage = await createSessionGraphImage(sessions);

            const usersGraphImage = await createUsersGraphImage(stats);
            const activityGraphImage = await createActivityGraphImage(
              stats.modulesActivity,
              translationResponse,
            );

            const tests = await listTests();

            const testsTableImage = await createTestsTableImage(
              tests,
              stats.tests,
            );

            const blob = await ReactPDF.pdf(
              <QuarterlyReport
                activityGraphImage={activityGraphImage}
                business={business}
                modulesGraphImage={modulesGraphImage}
                quarter={quarter}
                sessionGraphImage={sessionGraphImage}
                skillsGraphImage={skillsGraphImage}
                statsBoxRowImage={statsBoxRowImage}
                testsTableImage={testsTableImage}
                usersGraphImage={usersGraphImage}
                year={year}
              />,
            ).toBlob();

            // download pdf
            const link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = `report_${business.name}_Q${quarter}_${year}.pdf`;
            link.click();

            toast.dismiss(toastId);
          } catch {
            toast.update(toastId, {
              isLoading: false,
              render: "Report creation failed",
              type: "error",
            });
          }
        }}
      >
        <div className="box-inside-wrapper">
          <img alt="icon" className="icon" src={icon} />
          <div className="name">{t("quarterReport", { quarter })}</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
