import { Quarter } from "../QuarterlyReport/Quarter";
import { QuarterAndYear } from "../QuarterlyReport/QuarterAndYear";
import { quarterAndYearToFilterQueryString } from "../Utils";
import { API_ENDPOINT, getHeaders } from "./AuthApi";
import { Lesson } from "./LessonsApi";
import { Module } from "./ModulesApi";

export type User = {
  admin: boolean;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  isHr: boolean;
  language: string;
  lastName: string;
  newsletter: boolean;
  twoFactorAuthEnabled: boolean;
};

export type ModuleProgress = {
  finished: string;
  id: string;
  lessonId: string;
  moduleId: string;
  subjectId: string;
  userId: string;
};

type ModuleExerciseResult = {
  id: string;
  key: string;
  lessonId: string;
  result: string;
};

export type Sessions = {
  sessionsPerDay: {
    day: string;
    sessions: number;
  }[];
};

export type QuarterTime = {
  modules: number;
  podcasts: number;
  quarter: Quarter;
  tests: number;
  year: number;
};

export async function getCurrentUser(): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/user`, {
    headers: getHeaders(),
    method: "GET",
  });

  if (result.status === 401) {
    throw new Error("UNAUTHENTICATED");
  }

  const data = await result.json();

  if (data.token) {
    localStorage.setItem("token", data.token);
  }

  return data.user;
}

export async function deleteConnectedBusiness(
  businessId: string,
  userId: string,
): Promise<void> {
  await fetch(`${API_ENDPOINT}/businesses/connect`, {
    body: JSON.stringify({
      businessId: businessId,
      userId: userId,
    }),
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}

export async function getModuleExerciseResult(
  lessonId: string,
): Promise<ModuleExerciseResult[]> {
  const result = await fetch(`${API_ENDPOINT}/users/exercises/${lessonId}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.results) {
    return [];
  }

  return result.results;
}

export async function addModuleExerciseResult(
  lessonId: string,
  key: string,
  result: string,
): Promise<ModuleExerciseResult> {
  const response = await fetch(`${API_ENDPOINT}/users/exercises`, {
    body: JSON.stringify({
      key: key,
      lessonId: lessonId,
      result: result,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return response;
}

export async function getModuleProgress(
  moduleId: string,
): Promise<ModuleProgress[]> {
  const result = await fetch(`${API_ENDPOINT}/users/progress/${moduleId}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.progress) {
    return [];
  }

  return result.progress;
}

export async function getModuleCount(): Promise<Record<string, number>> {
  const result = await fetch(`${API_ENDPOINT}/users/progress/count`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.count) {
    return {};
  }

  return result.count;
}

export async function getTotalTimes(): Promise<{
  modules: number;
  podcasts: number;
  tests: number;
}> {
  return await fetch(`${API_ENDPOINT}/users/times`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());
}

export async function getTotalTimesPerQuarter(): Promise<QuarterTime[]> {
  const response = await fetch(`${API_ENDPOINT}/users/times-per-quarter`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return response.timesPerQuarter;
}

export async function getRecentVisited(): Promise<string[]> {
  const result = await fetch(`${API_ENDPOINT}/users/latest-visit`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.visited) {
    return [];
  }

  return result.visited;
}

export async function addModuleTime(
  moduleId: string,
  spend: number,
): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/time`, {
    body: JSON.stringify({
      moduleId: moduleId,
      spend: spend,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.ok;
}

export async function getUserPodcastTimes(): Promise<
  { id: string; time: number }[]
> {
  const result = await fetch(`${API_ENDPOINT}/users/podcast-time`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.times) {
    return [];
  }

  return result.times;
}

export async function addPodcastTime(
  podcastId: string,
  spend: number,
): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/podcast-time`, {
    body: JSON.stringify({
      podcastId: podcastId,
      spend: spend,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.ok;
}

export async function addTestTime(
  testId: string,
  spend: number,
): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/test-time`, {
    body: JSON.stringify({
      spend: spend,
      testId: testId,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.ok;
}

export async function addModuleProgress(
  moduleId: string,
  subjectId: string,
  lessonId: string,
): Promise<ModuleProgress> {
  const result = await fetch(`${API_ENDPOINT}/users/progress`, {
    body: JSON.stringify({
      lessonId: lessonId,
      moduleId: moduleId,
      subjectId: subjectId,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function getCheckoutUrl(
  moduleId: string,
  discount: string | undefined,
): Promise<{ url: string }> {
  const result = await fetch(`${API_ENDPOINT}/checkout`, {
    body: JSON.stringify({ discount: discount, moduleId: moduleId }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function getSessions(
  businessId: string,
  quarterAndYear?: QuarterAndYear,
): Promise<Sessions> {
  const filterQueryString = quarterAndYear
    ? quarterAndYearToFilterQueryString(
        quarterAndYear.quarter,
        quarterAndYear.year,
      )
    : "";

  const result = await fetch(
    `${API_ENDPOINT}/businesses/${businessId}/users/sessions?${filterQueryString}`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result;
}

export async function storeSession(userId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/users/session`, {
    body: JSON.stringify({
      userId: userId,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}

export async function listLessonsAndModulesWithExercises(): Promise<{
  lessons: Lesson[];
  modules: Module[];
}> {
  const result = await fetch(
    `${API_ENDPOINT}/users/exercises/lessons-and-modules`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  if (!result.lessons || !result.modules) {
    return { lessons: [], modules: [] };
  }

  return result;
}

export async function getHasSkills(): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/has_skills`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.hasSkills) {
    return false;
  }

  return true;
}
