import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useEffectOnce, useSearchParam } from "react-use";
import { Loading } from "./Loading";
import { getDecodedToken } from "./api/AuthApi";
import {
  addChallenge,
  addScore,
  Challenge,
  getChallenges,
  getGame,
  getPreviousChallenge,
  getScores,
  Score,
  startGame,
  stopGame,
} from "./api/GameApi";
import GameImg from "./img/game.png";

export function Game() {
  const { i18n, t } = useTranslation("general");

  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [current, setCurrent] = useState<string | null>(null);
  const [max, setMax] = useState(10);
  const [scores, setScores] = useState<Score[]>([]);
  const [date, setDate] = useState<DateTime>(DateTime.utc());
  const [previous, setPrevious] = useState<string | null>(null);
  const code = useSearchParam("code");
  const [loading, setLoading] = useState(true);

  useEffectOnce(() => {
    getChallenges().then((r) => {
      setChallenges(r);
    });

    if (!code) {
      getGame(null, null).then((r) => {
        if (r) {
          setCurrent(r.challengeId);
        }

        getPreviousChallenge().then((r) => {
          if (r) {
            setPrevious(r);
          }

          setLoading(false);
        });

        setDate(DateTime.fromISO(r.updated).toUTC());
        setMax(r.max);

        getScores().then((r) => {
          setScores(r);
        });
      });
    } else {
      setLoading(false);
    }
  });

  const foundChallenge = current
    ? challenges.find((c) => c.id === current)
    : null;

  const foundPreviousChallenge = previous
    ? challenges.find((c) => c.id === previous)
    : null;

  const unique = new Set(scores.map((s) => s.userId));
  const calculatedScores = Array.from(unique).map((u) => {
    const allScores = scores.filter((s) => s.userId === u);

    return {
      name: allScores[0].name,
      score: allScores.reduce((a, b) => a + b.score, 0),
    };
  });

  return (
    <div className="allbanner">
      <div
        className="banner mySlides fade flex justify-evenly wrap"
        id="banner2"
        style={{
          backgroundColor: `#1C4052`,
          minHeight: "400px",
        }}
      >
        <div className="banner-title w30" style={{ textAlign: "center" }}>
          <h1 style={{ color: "white" }}>{t("game")}</h1>
        </div>
        <img
          src={GameImg}
          style={{
            maxHeight: "350px",
            maxWidth: "303px",
            width: "auto",
          }}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="mb-4">
          {code || foundChallenge ? (
            <div className="flex">
              <div
                style={{
                  backgroundColor: "white",
                  maxWidth: "400px",
                  padding: "20px",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Vorige challenge
                </h3>
                {foundPreviousChallenge ? (
                  <div className={"flex flex-column items-center"}>
                    <h3>
                      Challenge{" "}
                      {scores.filter(
                        (s) => s.userId === getDecodedToken().userId,
                      ).length + 1}
                      :{" "}
                      {i18n.language === "nl" && foundPreviousChallenge.nameNL}
                      {i18n.language === "en" && foundPreviousChallenge.nameEN}
                      {i18n.language === "fr" && foundPreviousChallenge.nameFR}
                    </h3>
                    <div
                      style={{
                        background: "white",
                        fontWeight: "bold",
                        padding: "25px",
                      }}
                    >
                      {i18n.language === "nl" && foundPreviousChallenge.textNL}
                      {i18n.language === "en" && foundPreviousChallenge.textEN}
                      {i18n.language === "fr" && foundPreviousChallenge.textFR}
                    </div>
                    <div
                      style={{
                        fontSize: "19px",
                        marginBottom: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <b>Heb je de challenge voltooid?</b>
                    </div>
                    <div className="flex">
                      <div
                        onClick={async () => {
                          if (previous) {
                            await addScore(
                              previous,
                              1,
                              getDecodedToken().email,
                            );
                            setPrevious(null);
                            getScores().then((r) => {
                              setScores(r);
                            });
                          }
                        }}
                        style={{
                          alignItems: "center",
                          background: "#74A0AD",
                          borderRadius: "100%",
                          color: "white",
                          cursor: "pointer",
                          display: "flex",
                          fontSize: "30px",
                          height: "20px",
                          justifyContent: "center",
                          marginRight: "15px",
                          padding: "20px",
                          width: "20px",
                        }}
                      >
                        <FaCheck />
                      </div>
                      <div
                        onClick={async () => {
                          if (previous) {
                            await addScore(
                              previous,
                              0,
                              getDecodedToken().email,
                            );
                            setPrevious(null);
                            getScores().then((r) => {
                              setScores(r);
                            });
                          }
                        }}
                        style={{
                          alignItems: "center",
                          background: "#FFA176",
                          borderRadius: "100%",
                          color: "white",
                          cursor: "pointer",
                          display: "flex",
                          fontSize: "30px",
                          height: "20px",
                          justifyContent: "center",
                          padding: "20px",
                          width: "20px",
                        }}
                      >
                        <FaTimes />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="flex flex-column justify-center items-center">
                  <div>
                    <b>Wil je het spel stoppen?</b>
                  </div>
                  <div
                    className="button w-fit pink"
                    onClick={async () => {
                      await stopGame();
                      window.location.href = "/game";
                    }}
                  >
                    Stoppen
                  </div>
                </div>
              </div>

              <div
                className="ml-auto mr-auto"
                style={{ maxWidth: "600px", width: "100%" }}
              >
                {code ? (
                  <div>
                    <h2>Accepteer de invite!</h2>
                    <div
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Nickname
                    </div>
                    <div
                      className="button w-fit mt-4"
                      onClick={async () => {
                        await getGame(code, getDecodedToken().email);
                        window.location.href = "/game/single";
                      }}
                    >
                      Starten
                    </div>
                  </div>
                ) : scores.filter((s) => s.userId === getDecodedToken().userId)
                    .length === max ? (
                  <div>
                    <h2>{t("game-ended-title")}</h2>
                    <div
                      style={{
                        background: "white",
                        fontWeight: "bold",
                        padding: "25px",
                      }}
                    >
                      {t("game-ended-text")}
                    </div>
                    <div
                      className="button w-fit mt-4"
                      onClick={async () => {
                        setCurrent(null);
                        setScores([]);
                      }}
                    >
                      {t("game-ended-button")}
                    </div>
                  </div>
                ) : (
                  foundChallenge && (
                    <div>
                      {
                        // eslint-disable-next-line
                        date.toUTC().set({
                          hour: 0,
                          millisecond: 0,
                          minute: 0,
                          second: 0,
                        }) <=
                          DateTime.utc().set({
                            hour: 0,
                            millisecond: 0,
                            minute: 0,
                            second: 0,
                          }) || true ? (
                          <>
                            <h2 style={{ borderBottom: "1px solid #ffbd4a" }}>
                              Challenge{" "}
                              {
                                scores.filter(
                                  (s) => s.userId === getDecodedToken().userId,
                                ).length
                              }
                              :{" "}
                              {i18n.language === "nl" && foundChallenge.nameNL}
                              {i18n.language === "en" && foundChallenge.nameEN}
                              {i18n.language === "fr" && foundChallenge.nameFR}
                            </h2>
                            <div
                              style={{
                                background: "white",
                                fontWeight: "bold",
                                padding: "25px",
                              }}
                            >
                              {i18n.language === "nl" && foundChallenge.textNL}
                              {i18n.language === "en" && foundChallenge.textEN}
                              {i18n.language === "fr" && foundChallenge.textFR}
                            </div>
                            <div
                              style={{
                                fontSize: "19px",
                                marginBottom: "20px",
                                marginTop: "20px",
                              }}
                            >
                              <b>{t("game-accept")}</b>
                            </div>
                            <div className="flex">
                              <div
                                onClick={async () => {
                                  if (current) {
                                    const result = await addChallenge(
                                      current,
                                      1,
                                    );

                                    setDate(DateTime.utc().plus({ day: 1 }));
                                    setCurrent(result);
                                    getScores().then((r) => {
                                      setScores(r);
                                    });
                                  }
                                }}
                                style={{
                                  alignItems: "center",
                                  background: "#74A0AD",
                                  borderRadius: "100%",
                                  color: "white",
                                  cursor: "pointer",
                                  display: "flex",
                                  fontSize: "40px",
                                  height: "30px",
                                  justifyContent: "center",
                                  marginRight: "15px",
                                  padding: "30px",
                                  width: "30px",
                                }}
                              >
                                <FaCheck />
                              </div>
                              <div
                                onClick={async () => {
                                  if (current) {
                                    const result = await addChallenge(
                                      current,
                                      0,
                                    );

                                    setDate(DateTime.utc().plus({ day: 1 }));
                                    setCurrent(result);
                                    getScores().then((r) => {
                                      setScores(r);
                                    });
                                  }
                                }}
                                style={{
                                  alignItems: "center",
                                  background: "#FFA176",
                                  borderRadius: "100%",
                                  color: "white",
                                  cursor: "pointer",
                                  display: "flex",
                                  fontSize: "40px",
                                  height: "30px",
                                  justifyContent: "center",
                                  padding: "30px",
                                  width: "30px",
                                }}
                              >
                                <FaTimes />
                              </div>
                            </div>
                          </>
                        ) : (
                          <h2>{t("game-comeback")}</h2>
                        )
                      }
                    </div>
                  )
                )}
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  maxWidth: "400px",
                  paddingTop: "20px",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Scores
                </h3>
                {calculatedScores.map((score) => {
                  return (
                    <>
                      <h2
                        className="center"
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        {score.name}
                      </h2>
                      <div
                        className="bar-wrapper"
                        style={{
                          background: "#DDDDDD",
                          borderRadius: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <div
                          className={`bar`}
                          style={{
                            width: `${(score.score / max) * 100}%`,
                          }}
                        />
                      </div>
                    </>
                  );
                })}

                <div style={{ fontWeight: "bold", padding: "20px" }}>
                  {t("game_description")}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex">
              <div className="ml-auto mr-auto" style={{ maxWidth: "600px" }}>
                <h2>{t("game")}</h2>
                <div
                  style={{
                    background: "white",
                    fontWeight: "bold",
                    padding: "25px",
                  }}
                >
                  {t("game_description")}
                </div>
                <div
                  style={{
                    fontSize: "19px",
                    marginBottom: "10px",
                    marginTop: "20px",
                  }}
                >
                  <b>{t("difficulty")}</b>
                </div>
                <div className="flex">
                  <div className="flex mr-5">
                    <input
                      checked={max === 10}
                      onChange={() => {
                        setMax(10);
                      }}
                      required
                      type="radio"
                      value={10}
                    />
                    <div>{t("light")}</div>
                  </div>
                  <div className="flex mr-5">
                    <input
                      checked={max === 20}
                      onChange={() => {
                        setMax(20);
                      }}
                      required
                      type="radio"
                      value={20}
                    />
                    <div>{t("medium")}</div>
                  </div>
                  <div className="flex">
                    <input
                      checked={max === 30}
                      onChange={() => {
                        setMax(30);
                      }}
                      required
                      type="radio"
                      value={30}
                    />
                    <div>{t("extra")}</div>
                  </div>
                </div>
                <div
                  className="button w-fit mt-4"
                  onClick={async () => {
                    const result = await startGame(
                      max,
                      "",
                      "",
                      "",
                      getDecodedToken().email,
                    );

                    setCurrent(result.challengeId);
                    setDate(DateTime.fromISO(result.updated).toUTC());
                    const s = await getScores();

                    setScores(s);
                  }}
                >
                  {t("start-game")}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
