import { useState } from "react";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";

export function Dropdown({ text }: { text: string }) {
  const [open, setOpen] = useState(false);
  const headerRegex = /<h[0-9]+>(.*?)<\/h[0-9]+>(.*)/gim;
  const headerMatch = headerRegex.exec(
    text.replace(
      /\[picture url=&quot;([^<]*)&quot;\]/gm,
      `<img style="height: auto; width: 100%; max-height: calc(100vh - 300px)" src="$1" />`,
    ),
  );

  if (!headerMatch) {
    return null;
  }

  return (
    <div>
      <div
        className="flex items-center pointer"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? <AiFillCaretDown /> : <AiFillCaretRight />}
        <b>{headerMatch[1]}</b>
      </div>
      {open ? (
        <div
          dangerouslySetInnerHTML={{
            __html: headerMatch[2],
          }}
        />
      ) : null}
    </div>
  );
}
