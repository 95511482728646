import { Sidebar } from "../Sidebar";
import { PartnerForm } from "./PartnerForm";

export function CreatePartner() {
  return (
    <Sidebar>
      <PartnerForm />
    </Sidebar>
  );
}
