import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren } from "react";
import ReactModal from "react-modal";
import "./Dialog.css";

export function Dialog({
  children,
  isOpen,
  onRequestClose,
  padding,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  showCloseButton = true,
  size,
  title,
}: PropsWithChildren<{
  isOpen: boolean;
  onRequestClose: () => void;
  padding: boolean;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  showCloseButton?: boolean;
  size: "small" | "wide" | "xsmall" | "large" | "xlarge";
  title: string;
}>) {
  const classNames = ["dialog-body"];

  if (size === "small") {
    classNames.push("is-small");
  }

  if (size === "wide") {
    classNames.push("is-wide");
  }

  if (size === "xsmall") {
    classNames.push("is-xsmall");
  }

  if (size === "large") {
    classNames.push("is-large");
  }

  if (size === "xlarge") {
    classNames.push("is-xlarge");
  }

  if (padding) {
    classNames.push("p-8");
  }

  return (
    <ReactModal
      appElement={window.document.getElementById("root") as HTMLElement}
      bodyOpenClassName="dialog-open"
      className={classNames.join(" ")}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="dialog-overlay"
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {title === "" ? null : (
        <div className={"dialog-header " + (padding ? "mb-4" : "p-8")}>
          <div className="flex items-center">
            <p className="dialog-title">{title}</p>
          </div>
          {showCloseButton ? (
            <div
              className="close-button ml-auto"
              onClick={() => {
                onRequestClose();
              }}
              title="Close dialog"
            >
              <FontAwesomeIcon icon={faTimes} size={"2x"} />
            </div>
          ) : null}
        </div>
      )}

      {isOpen ? children : null}
    </ReactModal>
  );
}
