import { Page, View, StyleSheet, Image, Text } from "@react-pdf/renderer";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PremiumIcon from "../img/Premium.svg";
import { QuarterlyReportFooter } from "./QuarterlyReportFooter";

export function QuarterlyReportContent({
  activityGraphImage,
  modulesGraphImage,
  sessionGraphImage,
  skillsGraphImage,
  statsBoxRowImage,
  testsTableImage,
  usersGraphImage,
}: {
  activityGraphImage: string;
  modulesGraphImage: string;
  sessionGraphImage: string;
  skillsGraphImage: string;
  statsBoxRowImage: string;
  testsTableImage: string;
  usersGraphImage: string;
}) {
  const { t } = useTranslation("hr-dashboard");

  const styles = StyleSheet.create({
    graph: {
      marginTop: "10px",
      padding: "10px",
      width: "355px",
    },
    graphWrapper: {
      border: "1px solid grey",
      borderRadius: "10px",
      color: "#1F3B49",
      fontSize: "15px",
      padding: "10px",
    },
    graphs: {
      display: "flex",
      flexDirection: "row",
      gap: "30px",
      justifyContent: "center",
    },
    largeGraph: {
      marginTop: "10px",
      padding: "10px",
      width: "800px",
    },
    marginTop: {
      marginTop: "50px",
    },
    smallMarginTop: {
      marginTop: "20px",
    },
  });

  return (
    <Fragment>
      <Page orientation="landscape">
        <Image src={statsBoxRowImage} style={styles.marginTop} />
        <View style={styles.graphs}>
          <View style={styles.graphWrapper}>
            <Text>{t("modulesGraph.title")}</Text>
            <Image src={modulesGraphImage} style={styles.graph} />
          </View>
          <View style={styles.graphWrapper}>
            <Text>{t("skillsGraph.title")}</Text>
            <Image src={skillsGraphImage} style={styles.graph} />
            <img alt="" className="graph-premium" src={PremiumIcon} />
          </View>
        </View>
        <QuarterlyReportFooter />
      </Page>
      <Page orientation="landscape">
        <View style={{ ...styles.graphs, ...styles.marginTop }}>
          <View style={styles.graphWrapper}>
            <Text>{t("usersGraph.title")}</Text>
            <Image src={usersGraphImage} style={styles.graph} />
          </View>
          <View style={styles.graphWrapper}>
            <Text>{t("activityGraph.title")}</Text>
            <Image src={activityGraphImage} style={styles.graph} />
          </View>
        </View>
        <QuarterlyReportFooter />
      </Page>
      <Page orientation="landscape">
        <View style={{ ...styles.graphs, ...styles.marginTop }}>
          <View style={styles.graphWrapper}>
            <Text>{t("report:sessionGraphTitle")}</Text>
            <Image src={sessionGraphImage} style={styles.largeGraph} />
          </View>
        </View>
        <QuarterlyReportFooter />
      </Page>
      <Page orientation="portrait">
        <View style={{ ...styles.graphs, ...styles.smallMarginTop }}>
          <View style={styles.graphWrapper}>
            <Text>{t("testTable.title")}</Text>
            <Image src={testsTableImage} style={styles.graph} />
          </View>
        </View>
        <QuarterlyReportFooter />
      </Page>
    </Fragment>
  );
}
