import { ChartData } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { BusinessStatistics } from "../api/BusinessApi";
import { Module } from "../api/ModulesApi";
import { TranslationResponseType } from "../i18n";
import PremiumIcon from "../img/Premium.svg";
import TooltipIcon from "../img/tooltipIcon.svg";

function sortModules(modules: Module[], stats: BusinessStatistics) {
  return modules.sort((a, b) => {
    if (!stats) {
      return 0;
    }

    const foundStatA = stats.modules.find((s) => s.id === a.id)?.total || 0;
    const foundStatB = stats.modules.find((s) => s.id === b.id)?.total || 0;

    return foundStatB - foundStatA;
  });
}

export function getModulesGraphData(
  modules: Module[],
  stats: BusinessStatistics,
  translationResponse: TranslationResponseType,
): ChartData<"bar", number[], string> {
  const sortedModules = sortModules(modules, stats);
  const { i18n } = translationResponse;

  const modulesDatasets = [
    {
      backgroundColor: "rgba(107, 152, 170, 1)",
      data: sortedModules.map((m) => {
        if (!stats) {
          return 0;
        }

        const foundStat = stats.modules.find((s) => s.id === m.id);

        return foundStat?.total || 0;
      }),
      label: "Jouw werknemers",
    },
  ];

  /* if (hasPremium) {
    modulesDataset.push({
      label: "Mindlab",
      data: modules
        .map((m) => {
          if (!stats) {
            return 0;
          }

          const foundStat = stats.modules.find((s) => s.id === m.id);

          return foundStat?.mindlabTotal || 0;
        }),
      backgroundColor: "#fccc79",
    });
  }
   */

  const modulesLabels = sortedModules.map((m) =>
    i18n.language === "nl"
      ? m.titleNL
      : i18n.language === "en"
        ? m.titleEN
        : m.titleFR,
  );

  return {
    datasets: modulesDatasets,
    labels: modulesLabels,
  };
}

export function ModulesGraph({
  modules,
  premium = false,
  stats,
  translationResponse,
}: {
  modules: Module[];
  premium?: boolean;
  stats: BusinessStatistics;
  translationResponse: TranslationResponseType;
}) {
  const modulesGraphData = getModulesGraphData(
    modules,
    stats,
    translationResponse,
  );

  const { t } = useTranslation("hr-dashboard");
  const options = {
    aspectRatio: 1,
    barThickness: 10,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#000000",
        bodyFont: {
          size: 12,
          weight: "700",
        },
        callbacks: {
          label: function (context: { parsed: { x: number } }) {
            return `${Number(context.parsed.x).toFixed(0)}`;
          },
          title: function () {
            return "";
          },
        },
        displayColors: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: t("modulesGraph.xAxisTitle"),
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
    },
  };

  return (
    <div className="graph">
      <div className={"graph-title flex items-center"}>
        {t("modulesGraph.title")}
        <div
          className={"flex items-center"}
          data-tooltip-content={t("modulesGraph.tooltipContent")}
          data-tooltip-id="graph-explanation"
        >
          <img alt={""} className={"tooltip-icon"} src={TooltipIcon} />
        </div>
        <Tooltip className={"tooltip"} id={"graph-explanation"} />
      </div>
      {modules.length === 0 ? (
        <div className={"ml-5 mt-4 mb-4"}>
          {t("modulesGraph.noDataMessage")}
        </div>
      ) : (
        <div className="graph-wrapper">
          <Bar
            data={modulesGraphData}
            options={options}
            style={{ maxHeight: "420px" }}
          />
        </div>
      )}
      {premium ? (
        <img alt={""} className={"graph-premium"} src={PremiumIcon} />
      ) : null}
    </div>
  );
}
